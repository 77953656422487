import React from "react";
import {Box, CircularProgress, Text} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {getAllSavedQueries} from "../../../services/query_manager";
import {SavedQuery} from "../../../types/query_manager";

interface SavedQueriesProps {
    setCurrentQuery: (query: SavedQuery) => void;
    currentQuery: SavedQuery;
}

const SavedQueries: React.FC<SavedQueriesProps> = ({currentQuery, setCurrentQuery}) => {
    const {data, isLoading} = useQuery("saved_queries", getAllSavedQueries);
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            style={{
                overflowY: "auto",
                overflowX: "hidden",
            }}
            minH={"75vh"}
            maxH={"80vh"}
            w={"100%"}
            p={2}
        >
            <Text
                fontSize={"lg"}
                p={2}
            >Saved Queries</Text>
            {isLoading &&
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    w={"100%"}
                    h={"100%"}
                >
                    <CircularProgress isIndeterminate color="green.300"/>
                </Box>
            }
            {data && data.map((query: SavedQuery) => {
                return <SingleQuery
                    query={query}
                    currentQuery={currentQuery}
                    setCurrentQuery={setCurrentQuery}
                />
            })}

        </Box>
    )
}

const SingleQuery: React.FC<{
    query: SavedQuery,
    currentQuery: SavedQuery,
    setCurrentQuery: (query: SavedQuery) => void,
}> = ({
          query,
          currentQuery,
          setCurrentQuery
      }) => {
    return (
        <Box
            border="1px solid"
            borderColor={"#2d3649"}
            borderRadius="lg"
            backgroundColor={query.id == currentQuery?.id ? "#2d3649" : ""}
            p={4}
            mb={2}
            display="flex"
            justifyContent="space-between"
            gridGap={8}
            onClick={() => {
                setCurrentQuery(query)
            }}
            w={"100%"}
            style={{
                cursor: "pointer"
            }}
        >
            <Text fontWeight="bold"
            >{query.title}</Text>
        </Box>
    )
}

export default SavedQueries
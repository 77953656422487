import React from 'react';
import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import ModuleContentComponent from "./module-content-edit";
import ModuleEditPage from "./module-edit-page";
import PagesPresent from "./pages-present";
import {MODULE_PAGE} from "../../types/Strings";

interface ModuleEditComponentProps {
    data: any;
    isNew: boolean;
}

const ModuleEditComponent: React.FC<ModuleEditComponentProps> = ({data, isNew}) => {
    return (
        <Tabs isFitted variant='enclosed'>
            <TabList mb='1em' style={{color:"#838a8a"}}>
                <Tab>Module Edit</Tab>
                <Tab>Content Order</Tab>
                <Tab>Page Manager</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <ModuleEditPage
                        data={data}
                        isNew={isNew}
                    />
                </TabPanel>
                <TabPanel>
                    <ModuleContentComponent
                        data={data?.entity_list}
                        isNew={isNew}
                        source={MODULE_PAGE}
                        module_id={data?.id}
                    />
                </TabPanel>
                <TabPanel>
                    <PagesPresent
                        data={data?.pages}
                        isNew={isNew}
                        module_id={data?.id}
                    />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default ModuleEditComponent;

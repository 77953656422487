import React from "react";
import {SavedQuery, SavedQueryResponse} from "../../../types/query_manager";
import {Box, Button, CircularProgress} from "@chakra-ui/react";
import {useQuery} from "react-query";
import {deleteSavedQuery, getSavedQueryByID, runSavedQuery} from "../../../services/query_manager";
import copy from "copy-to-clipboard";
import toaster from "react-hot-toast";
import EditQueryModal from "./EditQueryModal";

interface SavedQueryRunResultProps {
    currentQuery: SavedQuery;
}

const SavedQueryRunResult: React.FC<SavedQueryRunResultProps> = ({currentQuery}) => {
    const [runQueryResult, setRunQueryResult] = React.useState<SavedQueryResponse>(null);
    const [isRunningQuery, setIsRunningQuery] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const {data, isLoading} = useQuery(`saved-query-${currentQuery.id}`, () => {
        if (currentQuery) {
            return getSavedQueryByID(currentQuery.id)
        }
        return null;
    });

    const deleteHandler = async () => {
        setIsDeleting(true);
        const res = await deleteSavedQuery(currentQuery.id);
        if (res == 204) {
            toaster.success("Query deleted successfully");
        } else {
            toaster.error("Error deleting query");
        }
        setIsDeleting(false);
    }

    const runQuery = async () => {
        setIsRunningQuery(true);
        const res = await runSavedQuery(currentQuery);
        setRunQueryResult(res);
        setIsRunningQuery(false);
    };

    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            w={"100%"}
            h={"100%"}
            p={2}
        >
            {isLoading && <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                w={"100%"}
                h={"100%"}
            >
                <CircularProgress isIndeterminate color="green.300"/>
            </Box>}

            {data && <Box
                w={"100%"}
                h={"100%"}
                display={'flex'}
                flexDirection={"column"}
            >
                <Box
                    w={"100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                >
                    <Box>
                        <EditQueryModal currentQuery={currentQuery} isNew={true}/>
                    </Box>
                    <Box>
                        <EditQueryModal currentQuery={currentQuery} isNew={false}/>
                        <Button mr={2} colorScheme={'red'} isLoading={isDeleting}
                                onClick={deleteHandler}>Delete</Button>
                        <Button mr={2} colorScheme={'green'} isLoading={isRunningQuery} onClick={runQuery}>Run</Button>
                    </Box>
                </Box>
                {runQueryResult && runQueryResult.id == currentQuery.id && <Box
                    w={"100%"}
                    h={"100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Button
                        mr={2}
                        onClick={() => {
                            window.open(runQueryResult.sheet_link, '_blank')
                        }}>Open in Google Sheets</Button>
                    <Button
                        onClick={() => {
                            copy(runQueryResult.sheet_link);
                            toaster.success('Copied to clipboard');
                        }}
                    >
                        Copy Link
                    </Button>
                </Box>}
            </Box>}
        </Box>
    )
}

export default SavedQueryRunResult;

const UserAccessLevel = ({role}) => {
    return (
        <>
            {role === '0' && "Administrator"}
            {role === '1' && "Editor"}
            {role === '2' && "Viewer"}
        </>
    )
}

export default UserAccessLevel;
import React from "react";
import {Wrapper, WrapperVariant} from "./Wrapper";
import NavBar from "../navbar/Navbar";
// @ts-ignore
import Sidebar from "./Sidebar/Drawer";
import {Box, Grid, GridItem} from "@chakra-ui/react";
import {Outlet} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {CurrentPage} from "../../store/mobx/CreateButton";
import {observer} from "mobx-react";

interface LayoutProps {
    variant?: WrapperVariant;
    authenticated?: boolean;
    children?: React.ReactNode;
    setAuthenticated?: any;
}

const Layout: React.FC<LayoutProps> = observer(({variant}) => {
    //@ts-ignore
    const {auth} = useAuth();
    const [isOpen, setIsOpen] = React.useState(true);

    //If screen size is small set the sidebar to be closed
    const isSmallScreen = window.innerWidth < 768
    if (isSmallScreen) {
        setIsOpen(false);
    }

    return (
        <>
            <NavBar isSideBarOpen={isOpen} toggleSideBar={(e) => setIsOpen(e)}/>
            {auth?.user?.id === undefined &&

                <Box
                    mt={20}
                >
                    <Outlet/>
                </Box>
            }
            {auth?.user?.id !== undefined && auth?.user?.id &&
                <Grid templateColumns={isOpen ? "1fr 5.5fr 0.05fr" : ""}>
                    {isOpen && <GridItem
                        h={"100%"}
                        zIndex={2}
                    >
                        <Sidebar
                            currentPage={CurrentPage}
                            toggleSidebar={(e) => setIsOpen(e)}
                            sidebarOpen={isOpen}
                        />
                    </GridItem>}
                    <GridItem
                        style={{
                            marginLeft: !isOpen && !isSmallScreen ? 20 : 12,
                            marginRight: !isOpen && !isSmallScreen ? 20 : 0,
                        }}
                        mt={20}
                    >
                        <Wrapper variant={variant}>
                            <Outlet/>
                        </Wrapper>
                    </GridItem>
                </Grid>
            }
        </>
    );
});


export default Layout;

import GetAccessLevel from "./GetAccessLevel";

const LockAccess = (auth: any, accessLevel:string[]) => {
    if (GetAccessLevel(auth, accessLevel)) {
        return {
            opacity: 1,
            style: {
                pointerEvents: "auto"
            }
        };
    } else {
        return {
            opacity: 0.5,
            style: {
                pointerEvents: "none"
            }
        }
    }

}

export default LockAccess;
import React from 'react';
import {Td, Tr} from "@chakra-ui/table";
import {DragHandleIcon} from "@chakra-ui/icons";
import {useDrag, useDrop} from "react-dnd";
import {DND_ITEM_TYPE} from "../../../types/Strings";
import {IoIosRemoveCircleOutline} from "react-icons/io";
import {CircularProgress, FormControl, FormHelperText, Select, Text} from "@chakra-ui/react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {produce} from "immer";

interface Props {
    id?: string;
    data?: any;
    index?: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
    removeRow: (index: number) => void;
    setData: (data: any) => void;
}

const TagContainer: React.FC<Props> = ({id, index, moveRow, removeRow, setData, data}) => {
    const [l1, setL1] = React.useState<any>(null);
    const [l2, setL2] = React.useState<any>(null);
    const [l3, setL3] = React.useState<any>(null);
    const [l4, setL4] = React.useState<any>(null);

    const [tagList, setTagList] = React.useState<any>(null);

    const [selectedL1, setSelectedL1] = React.useState<any>(null);
    const [selectedL2, setSelectedL2] = React.useState<any>(null);
    const [selectedL3, setSelectedL3] = React.useState<any>(null);
    const [selectedL4, setSelectedL4] = React.useState<any>(null);

    const axiosPrivate = useAxiosPrivate();

    React.useEffect(() => {
        const getTagsList = async () => {
            const response = await axiosPrivate.get('/tags/list');

            setTagList(response.data?.data.map((item: any) => ({
                value: item.id,
                label: item.name
            })));
        };
        getTagsList();
    }, [])

    React.useEffect(() => {
        const fetchApi = async () => {
            let response;
            response = await axiosPrivate.get('/tags');
            setL1([{
                value: null,
                label: "(Blank)",
            }, ...response.data.data.map((item: any) => ({
                value: item.id,
                label: item.name,
            }))])
        }
        fetchApi();
    }, [])

    React.useEffect(() => {
        const fetchApi = async () => {
            let response;
            if (selectedL1 !== null && selectedL1 !== '(Blank)') {
                response = await axiosPrivate.get(`/tags?id=${selectedL1}`);
                setL2([{
                    value: null,
                    label: "(Blank)",
                }, ...response.data.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }))])
            } else if (selectedL1 == '(Blank)') {
                setL2([])
                setL3([])
                setL4([])
            }
        }
        fetchApi();
    }, [selectedL1])

    React.useEffect(() => {
        const fetchApi = async () => {
            let response;
            if (selectedL2 !== null && selectedL2 !== '(Blank)') {
                response = await axiosPrivate.get(`/tags?id=${selectedL2}`);
                setL3([{
                    value: null,
                    label: "(Blank)",
                }, ...response.data.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }))])
            } else if (selectedL2 === '(Blank)') {
                setL3([])
                setL4([])
            }
        }
        fetchApi();
    }, [selectedL2])

    React.useEffect(() => {
        const fetchApi = async () => {
            let response;
            if (selectedL3 !== null && selectedL3 !== '(Blank)') {
                response = await axiosPrivate.get(`/tags?id=${selectedL3}`);
                setL4([{
                    value: null,
                    label: "(Blank)",
                }, ...response.data.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }))])
            } else if (selectedL3 === '(Blank)') {
                setL4([])
            }
        }
        fetchApi();
    }, [selectedL3])

    const handleSelectTag = (id, type) => {
        if (type === 'l1') {
            setData(produce(data => {
                data[index] = {
                    ...data[index],
                    l1: parseInt(id),
                    l2: null,
                    l3: null,
                    l4: null,
                };
            }));

            setSelectedL1(id);
            setSelectedL2(null);
            setSelectedL3(null);
            setSelectedL4(null);

        } else if (type === 'l2') {
            setData(produce(data => {
                data[index] = {
                    ...data[index],
                    l2: parseInt(id),
                    l3: null,
                    l4: null,
                };
            }));
            setSelectedL2(id);
            setSelectedL3(null);
            setSelectedL4(null);
        } else if (type === 'l3') {
            setData(produce(data => {
                data[index] = {
                    ...data[index],
                    l3: parseInt(id),
                    l4: null,
                };
            }));
            setSelectedL3(id);
            setSelectedL4(null);
        } else if (type === 'l4') {
            setData(produce(data => {
                data[index] = {
                    ...data[index],
                    l4: parseInt(id),
                };
            }));
            setSelectedL4(id);
        }
    }

    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)

    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {
            if (!dropRef.current) {
                return
            }
            // @ts-ignore
            let dragIndex = item.index
            let hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = dropRef.current.getBoundingClientRect()
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            moveRow(dragIndex, hoverIndex)
            // @ts-ignore
            item.index = hoverIndex
        },
    })

    const [{isDragging}, drag, preview] = useDrag({
        type: DND_ITEM_TYPE,
        item: {index},
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    preview(drop(dropRef));
    drag(dragRef);

    return (
        <>
            <Tr key={index} ref={dropRef} backgroundColor={data.isNew ? '#3b4966' : " "}>
                <Td
                    ref={dragRef}
                    style={{
                        cursor: 'move',
                        opacity: isDragging ? 0.5 : 1,
                    }}
                >
                    <DragHandleIcon/>
                </Td>
                <Td>{index + 1}</Td>
                <Td>
                    <SelectTag
                        options={!data.isNew ? tagList : l1}
                        value={data.l1}
                        placeholder={'Select L1 Tag'}
                        setSelect={(id) => handleSelectTag(id, 'l1')}
                        disabled={!data.isNew}
                    />
                </Td>
                <Td>
                    <SelectTag
                        options={!data.isNew ? tagList : l2}
                        value={data.l2}
                        placeholder={'Select L2 Tag'}
                        setSelect={(id) => handleSelectTag(id, 'l2')}
                        disabled={!data.isNew}
                    />
                </Td>
                <Td>
                    <SelectTag
                        options={!data.isNew ? tagList : l3}
                        value={data.l3}
                        placeholder={'Select L3 Tag'}
                        setSelect={(id) => handleSelectTag(id, 'l3')}
                        disabled={!data.isNew}
                    />
                </Td>
                <Td>
                    <SelectTag
                        options={!data.isNew ? tagList : l4}
                        value={data.l4}
                        placeholder={'Select L4 Tag'}
                        setSelect={(id) => handleSelectTag(id, 'l4')}
                        disabled={!data.isNew}
                    />
                </Td>
                <Td>
                    <IoIosRemoveCircleOutline
                        style={{
                            cursor: 'pointer',
                            color: 'red',
                            fontSize: '1.5rem'
                        }}
                        onClick={() => removeRow(index)}
                    />
                </Td>
            </Tr>
        </>
    )
};

const SelectTag = ({options, placeholder, value, setSelect, disabled}) => {

    return (
        <>
            <FormControl>
                {!options && <CircularProgress isIndeterminate size="1.5rem" color="blue.500"/>}
                {options && options.length === 0 && <Text>No Tags</Text>}
                {!options && <div>No Tags</div>}
                {options && options.length > 0 && <Select
                    id={placeholder}
                    isDisabled={disabled}
                    // onBlur={(e) => setSelect(e.target.value)}
                    // value={(value !== null || value !== undefined) ? value : options && options.length > 0 ? options[0].value : null}
                    value={value}
                    onChange={(e) => setSelect(e.target.value)}
                >
                    {(disabled && !value) && <option>Null Tag</option>}
                    {options?.map((option, index) => (
                        <option
                            key={index}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    ))}
                </Select>}
                <FormHelperText>{value ? value : "Null"}</FormHelperText>
            </FormControl>
        </>
    )
}

export default TagContainer;
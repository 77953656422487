const SecondsToDuration = (time) => {
    time = parseInt(time);
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    return `${hours>0?`${hours}h `:''}${minutes>0?`${minutes}m `:''}${seconds>0?`${seconds}s `:''}`;
}

export {SecondsToDuration};

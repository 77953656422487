import {
    Box,
    Button, CircularProgress,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Progress,
    VStack
} from "@chakra-ui/react";
import EntitySearchModal from "../entity-search-modal";
import React from "react";
import {toTimestamp} from "../../../utils/Date";
import {ANY, PLAYLIST_PAGE} from "../../../types/Strings";
import EntitiesSearchResult from "./entities-search-result";
import {produce} from "immer";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Loader from "../../common/Loader";

interface Props {
    handleSelect: (entity: any) => void;
    source?: string;
}

const ModuleContentSearch: React.FC<Props> = ({handleSelect, source}) => {
    const [searchResult, setSearchResult] = React.useState(null);
    const [isSearching, setIsSearching] = React.useState(false);
    const [showResult, setShowResult] = React.useState(true);
    const [isSearchingResultModalOpen, setIsSearchingResultModalOpen] = React.useState(false);
    const axiosPrivate = useAxiosPrivate();

    const only_videos = React.useMemo(() => {
        if (searchResult) {
            return searchResult.filter((item) => item.type === "video");
        }
    }, [searchResult])


    const selectedNumber = React.useMemo(() => {
        if (searchResult) {
            return searchResult.filter((item) => item.checked).length;
        }
    }, [searchResult])

    const setSearchParams = async (text, param, type, start, end) => {
        setIsSearching(true);
        setIsSearchingResultModalOpen(true);
        const res = await axiosPrivate.post('/entity/search', {
            text: text,
            type: type,
            param: param,
            start: start ? toTimestamp(start) : ANY,
            end: start ? toTimestamp(end) : ANY
        });

        if (res.data.status === 200) {
            const keys = Object.keys(res.data.data);
            let data: any[] = keys.map(key => {
                if (key !== 'total') {
                    return {
                        type: key,
                        arr: res.data.data[key]
                    }
                }
            })

            const arr = [];

            for (let item of data) {
                if (item) {
                    if (item.arr.length > 0) {
                        for (let i of item.arr) {
                            arr.push({
                                type: item.type,
                                checked: false,
                                ...i
                            })
                        }
                    }
                }
            }
            setSearchResult(arr);
        }
        setIsSearching(false);

    };

    const handleAdd = (id, checked) => {
        setSearchResult(produce(searchResult, draft => {
            draft.forEach(item => {
                if (item.id === id) {
                    item.checked = checked;
                }
            })
        }))
    }

    const handleAll = (checked) => {
        setSearchResult(produce(searchResult, draft => {
            draft.forEach(item => {
                item.checked = checked;
            })
        }))

    }

    const handleAddEntities = () => {
        const items = []
        searchResult.forEach(item => {
            if (item.checked) {
                items.push({
                    entity_type: item.type,
                    id: item.id,
                    entity_id: item.entity_id,
                    image_url: item?.image_url,
                    title: item?.title || item?.name
                })
            }
        });
        handleSelect(items);
        setIsSearchingResultModalOpen(false);
    }

    const handleResults = () => {
        setIsSearchingResultModalOpen(!isSearchingResultModalOpen);
    }

    return (
        <>
            <VStack
                align="left"
            >
                <Box
                    mb={4}
                >
                    <EntitySearchModal
                        handleSearch={setSearchParams}
                        handleResults={handleResults}
                        hasSearchedBefore={!!searchResult}
                    />
                    <Modal isOpen={isSearchingResultModalOpen} onClose={() => {
                        setIsSearchingResultModalOpen(false)
                    }}>
                        <ModalOverlay/>
                        <ModalContent
                            maxW="1000px"
                            maxH="600px"
                            overflowY="auto"
                            sx={{
                                "&::-webkit-scrollbar": {
                                    width: "4px",

                                },
                                "&::-webkit-scrollbar-track": {
                                    width: "6px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "#b4b6be",
                                    borderRadius: "24px",

                                },
                            }}
                        >
                            <ModalHeader>Search Results</ModalHeader>
                            <ModalCloseButton/>
                            <ModalBody>
                                <Box
                                    mb={4}
                                >
                                    {isSearching &&
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <CircularProgress
                                                isIndeterminate
                                                size="100px"
                                                color="green.300"
                                            />
                                        </Box>
                                    }

                                    {!isSearching && searchResult &&
                                        <>

                                            <Button onClick={handleAddEntities} colorScheme={"green"} mb={2} mr={2}>
                                                Add {selectedNumber}/{searchResult.length}
                                            </Button>
                                        </>
                                    }

                                    {!isSearching && searchResult && <EntitiesSearchResult
                                        data={source === PLAYLIST_PAGE ? only_videos : searchResult}
                                        handleAdd={handleAdd}
                                        handleAll={handleAll}
                                    />}
                                </Box>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </Box>
            </VStack>
        </>
    )
}

export default ModuleContentSearch;

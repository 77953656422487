import React from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    useDisclosure
} from "@chakra-ui/react";
import SelectFieldCommon from "../custom/common/SelectCommon";
import {HORIZONTAL} from "../../types/Strings";
import {Table, Tbody, Th, Thead, Tr} from "@chakra-ui/table";
import StatusIndicator from "../common/StatusIndicator";
import {produce} from "immer";
import toast, {Toaster} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

interface ModuleSearchModalProps {
    page_id: string;
    page_type: string;
    refetch: () => void;
}

const ModuleSearchModal: React.FC<ModuleSearchModalProps> = ({page_id, page_type, refetch}) => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [type, setType] = React.useState("text");
    const [input, setInput] = React.useState("");
    const [isSearching, setIsSearching] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [searchResults, setSearchResults] = React.useState(null);
    const [isAdding, setIsAdding] = React.useState(false);

    const navigate = useNavigate();

    const axiosPrivate = useAxiosPrivate();

    const handleSelect = (type) => {
        setType(type);
    }

    const handleCheckbox = (e, id) => {
        setSearchResults(produce(searchResults, draft => {
            draft.forEach(result => {
                if (result.id === id) {
                    result.isSelected = e.target.checked;
                }
            })
        }))
    }

    const handlePosition = (e, id) => {
        setSearchResults(produce(searchResults, draft => {
            draft.forEach(result => {
                if (result.id === id) {
                    result.position = parseInt(e);
                }
            })
        }))
    }

    const handleCheckboxAll = (e) => {
        setSearchResults(produce(searchResults, draft => {
            draft.forEach(result => {
                result.isSelected = e.target.checked;
            })
        }))
    }

    const handleAdd = async () => {
        setIsAdding(true);
        const selected = searchResults.filter(result => result.isSelected);
        const data = {
            id: page_id,
            type: page_type,
            modules: {
                add: selected.map(result => {
                    return {
                        id: result.id,
                        position: result.position,
                        is_active: result.isSelected,
                    }
                })
            }
        }
        console.log(data);
        try {
            const res = await axiosPrivate.patch('/page', data);
            setIsAdding(false);
            if (res.status === 200) {
                if (res.data?.status === 200) {
                    toast.success("Module added successfully");
                } else {
                    toast.error("Something went wrong");
                }
            }
            refetch();
            onClose();
            navigate(`/pages/${page_type}/${page_id}`, {replace: true});
        } catch (e) {
            console.log(e);
            setError("Something went wrong");
        }
        setIsAdding(false);
    }

    const handleSearch = async () => {
        setIsSearching(true);
        if (input.length > 0) {
            setError(null);
        } else {
            setError("Please enter a search term");
        }

        let res;

        if (type === 'text') {
            res = await axiosPrivate.post('/module/search', {
                text: input
            })
        } else {
            res = await axiosPrivate.post('/module/search', {
                ids: input.split(',')
            })
        }
        if (res.status === 200) {
            setSearchResults(res.data?.data);
        }
        setIsSearching(false);
    }

    return (
        <>
            <Button onClick={onOpen} colorScheme={'green'}>Add Module</Button>
            <Toaster/>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Search Modules</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Input
                            placeholder="Search modules..."
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                        />
                        <Box
                            w={'30%'}
                            mt={4}
                        >
                            <SelectFieldCommon
                                setValue={handleSelect}
                                options={[
                                    {value: 'text', label: 'Title'},
                                    {value: 'ids', label: 'IDs'},
                                ]}
                                value={type}
                                layout={HORIZONTAL}
                            />
                        </Box>

                        <Box>
                            {error && error}
                        </Box>

                        <Box>
                            {isSearching && <CircularProgress isIndeterminate size={'1rem'}/>}
                            {searchResults && searchResults.length > 0 &&
                                <Table variant="striped">
                                    <Thead>
                                        <Tr>
                                            <Th>ID</Th>
                                            <Th>Name</Th>
                                            <Th>Is Active</Th>
                                            <Th>Position</Th>
                                            <Th><Checkbox onChange={(e) => handleCheckboxAll(e)}/></Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {searchResults.map((module) => (
                                            <Tr key={module.id}>
                                                <Th>{module.id}</Th>
                                                <Th>{module.title}</Th>
                                                <Th><StatusIndicator is_active={module.is_active}/></Th>
                                                <Th>
                                                    <NumberInput onChange={(e) => handlePosition(e, module.id)}>
                                                        <NumberInputField/>
                                                        <NumberInputStepper>
                                                            <NumberIncrementStepper/>
                                                            <NumberDecrementStepper/>
                                                        </NumberInputStepper>
                                                    </NumberInput>
                                                </Th>
                                                <Th><Checkbox isChecked={module?.isSelected}
                                                              onChange={(e) => handleCheckbox(e, module.id)}/></Th>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            }
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        {searchResults && searchResults.length > 0 &&
                            <Button onClick={handleAdd} mr={2} colorScheme={"green"} isLoading={isAdding}>Add</Button>}
                        <Button isLoading={isSearching} variant='ghost' onClick={handleSearch}>Search</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ModuleSearchModal;
import EntityTagManager from "../tags/entity-taging/EntityTagManager";
import React from "react";
import {PLAYLIST} from "../../types/Strings";

interface Props {
    id: string;
}

const PlaylistTagging: React.FC<Props> = ({id}) => {
    return (
        <>
            <EntityTagManager id={id} type={PLAYLIST}/>
        </>
    )
}

export default PlaylistTagging;
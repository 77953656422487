import {Box, Button, Circle, HStack, Text, Tooltip, VStack} from "@chakra-ui/react";
import {MODULE_PAGE, PAGE, PAGES_PAGE, PLAYLIST_PAGE} from "../../types/Strings";
import React from "react";
import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/table";
import SortComponent from "../common/Sorting";
import StatusIndicator from "../common/StatusIndicator";
import {useNavigate} from "react-router-dom";
import PaginationBar from "./pagination-bar";
import {FiEdit} from "react-icons/fi";
import {AiOutlineArrowRight} from "react-icons/ai";

interface Props {
    isFetching: boolean;
    handlePaginationPrevious: () => void;
    handlePaginationNext: () => void;
    pageNumber: number;
    totalModules?: number;
    perPage: number;
    setPerPage: (perPage: number) => void;
    modules: any[];
    handleSorting: (field: string, direction: string) => void;
    source: string;
    goToPage: (page: number) => void;
    getFilterDetails?: (filter: string) => void;
    setOpenSearchModal: (open: boolean) => void;
}


const TableBody: React.FC<Props> = ({
                                        isFetching,
                                        handlePaginationPrevious,
                                        handlePaginationNext,
                                        pageNumber,
                                        totalModules,
                                        perPage,
                                        setPerPage,
                                        modules,
                                        handleSorting,
                                        source,
                                        goToPage,
                                        getFilterDetails,
                                        setOpenSearchModal
                                    }) => {
    const navigate = useNavigate();

    return (
        <>
            {
                !isFetching && <PaginationBar
                    setPerPage={setPerPage}
                    pageNumber={pageNumber}
                    totalModules={totalModules}
                    perPage={perPage}
                    goToPage={goToPage}
                    previous={handlePaginationPrevious}
                    next={handlePaginationNext}
                    filter={""}
                    setFilter={() => {
                    }}
                    getFilterDetails={getFilterDetails}
                    setOpenSearchModal={setOpenSearchModal}
                />
            }
            {
                !isFetching &&
                <Table>
                    <Thead>
                        <Tr>
                            <Th
                                w={[]}
                            >
                                #
                            </Th>
                            <Th>

                                <SortComponent
                                    setData={handleSorting}
                                    field={'ID'}
                                />

                            </Th>
                            <Th>
                                <SortComponent
                                    setData={handleSorting}
                                    field={'Title'}
                                />
                            </Th>

                            {source === PAGE && <Th>
                                <SortComponent
                                    setData={handleSorting}
                                    field={'Position'}
                                />
                            </Th>}
                            <Th>
                                <SortComponent
                                    setData={handleSorting}
                                    field={`${source === PLAYLIST_PAGE ? 'Playlist' : source === PAGES_PAGE ? 'PAGE' : 'Module'} Status`}
                                />
                            </Th>
                            {source === PAGE && <Th>
                                <SortComponent
                                    setData={handleSorting}
                                    field={'Page Status'}
                                />
                            </Th>}
                            {source === PAGE && <Th>
                                <SortComponent
                                    setData={handleSorting}
                                    field={'No. Of Entities'}
                                />
                            </Th>}
                            {source === PAGES_PAGE && <Th>Number Of Modules</Th>}
                            {source !== PAGES_PAGE && <Th>Edit</Th>}
                            {source === PAGES_PAGE && <Th>Go To Page</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {modules && modules.map((item, index) => {
                            return (
                                <Tr key={index}>
                                    <Td>{index + 1}</Td>
                                    {(source === PAGE || source === MODULE_PAGE) &&
                                        <Td>{item.module_id}</Td>
                                    }
                                    {
                                        source === PLAYLIST_PAGE &&
                                        <Td>{item.id}</Td>
                                    }
                                    {
                                        source === PAGES_PAGE && <Td>{item.id}</Td>
                                    }
                                    <Td>
                                        <VStack align={"left"}>
                                            <Box>
                                                {item.title}
                                            </Box>
                                            <Box>
                                                <HStack align={"center"}>
                                                    {item?.created_by && <Tooltip
                                                        label="Created By"
                                                    >
                                                        <Text color={"#838a8a"}>{item?.created_by} </Text>
                                                    </Tooltip>}
                                                    <Circle size={"4px"} bg={"#838a8a"}/>
                                                    {item?.last_modified && <Tooltip
                                                        label={"Last modification date"}
                                                    >
                                                        <Text
                                                            color={"#838a8a"}>{new Date(item?.last_modified).toDateString()}</Text>
                                                    </Tooltip>}
                                                </HStack>
                                            </Box>
                                        </VStack>
                                    </Td>
                                    {source === PAGE && <Td>{item?.module_position}</Td>}
                                    <Td><StatusIndicator is_active={item?.is_active}/></Td>
                                    {source === PAGE && <Td><StatusIndicator is_active={item?.page_is_active}/></Td>}
                                    {
                                        source === PAGES_PAGE && <Td>{item.number_of_modules}</Td>
                                    }
                                    {source === PAGE && <Td>{item?.entity_list.length || 0}</Td>}
                                    <Td>
                                        <HStack>
                                            {source !== PAGES_PAGE && <FiEdit
                                                size={24}
                                                style={{
                                                    cursor: 'pointer',
                                                    color: '#008ff5',
                                                }}
                                                onClick={() => {
                                                    if (source === MODULE_PAGE || source === PAGE) {
                                                        navigate(`/module-edit/${item.module_id}`)
                                                    } else if (source === PLAYLIST_PAGE) {
                                                        navigate(`/playlist-edit/${item.id}`)
                                                    }
                                                }}
                                            />}
                                            {
                                                source === PAGES_PAGE && <Button
                                                    rightIcon={<AiOutlineArrowRight/>} style={{
                                                    color: "#008ff5",
                                                }}
                                                    variant='ghost'
                                                    onClick={() => navigate(`/pages/${item.type}/${item.id}`)}
                                                >
                                                    Open
                                                </Button>
                                            }
                                        </HStack>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            }
        </>
    )
}

export default TableBody

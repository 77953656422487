import React from 'react'
import {Flex, Link, Menu, MenuButton, Text, useColorModeValue} from '@chakra-ui/react'
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";

const NavItem = observer(({ children, route, active, currentPage, index}) => {
    const navigate = useNavigate();
    return (
        <Flex
            mt={index === 0 ? 0 : 1}
            flexDir="column"
            w="100%"
            alignItems={"flex-start"}
        >
            <Menu placement="right">
                <Link
                    color={currentPage.current_page === route ? "#767a7a" : useColorModeValue('gray.300', 'gray.100')}
                    p={3}
                    _disabled={active}
                    borderRadius={8}
                    onClick={() => {
                        currentPage.changePage(route)
                        navigate(`/${route}`)
                    }}
                    _hover={{textDecor: 'bold', backgroundColor: "#AEC8CA", color: "#1A202C"}}
                    w={"100%"}
                >
                    <MenuButton w="100%"
                    >
                        <Flex

                        >

                            {children}
                        </Flex>
                    </MenuButton>
                </Link>
            </Menu>
        </Flex>
    )
});

export default NavItem;

import React from 'react';
import SelectCommon from "../../custom/common/SelectCommon";
import {Box, Textarea, Text, HStack, Button} from "@chakra-ui/react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {toast} from "react-hot-toast";

const DatabaseConfigManager = () => {
    const [keys, setKeys] = React.useState(null);
    const [selectedKey, setSelectedKey] = React.useState(null);
    const [selectedValue, setSelectedValue] = React.useState(null);
    const [isJSONValid, setIsJSONValid] = React.useState(true);
    const [isFetching, setIsFetching] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);

    const [error, setError] = React.useState("");

    const axiosPrivate = useAxiosPrivate();
    React.useEffect(() => {
        const fetch = async () => {
            const response = await axiosPrivate.get('/config/dbcache/keys');
            if (response.status === 200) {
                setSelectedKey(response.data.data[0])
                setKeys(response.data.data.map(key => {
                    return {
                        label: key,
                        value: key
                    }
                }))
            }
        }
        fetch()
    }, [])

    const handleFetch = async () => {
        setIsFetching(true)
        if (!selectedKey) {
            setError("Please select a key to fetch the data of")
        } else {
            setError("")
        }

        const response = await axiosPrivate.get('/config/dbcache?key=' + selectedKey);

        if (response.status === 200) {
            setIsFetching(false)
            setSelectedValue(JSON.stringify(response.data.data['value']))
        }
    }

    const handleSave = async () => {
        setIsSaving(true);
        if (!selectedKey) {
            setError("Please select a key to update the data")
        } else {
            setError("")
        }

        try {
            const response = await axiosPrivate.patch('/config/dbcache', {
                key: selectedKey,
                value: JSON.parse(selectedValue)
            });

            if (response.status === 200) {
                if (response.data.status == 200) {
                    setIsSaving(false)
                    toast.success(response.data.message);
                } else {
                    setIsSaving(false);
                    toast.error(response.data.message)
                }
            }
        } catch (err) {
            toast.error(err.message);
        }
    }

    const prettyPrint = (json) => {
        //Check if its valid json
        try {
            JSON.parse(json);
            setSelectedValue(JSON.stringify(JSON.parse(json), null, 4))
            setIsJSONValid(true)
        } catch (err) {
            console.log(err)
            setIsJSONValid(false)
        }


    }

    return (
        <>
            {keys && <Box w={"60%"} mt={4}>
                <Text as={"h3"} fontWeight={"bold"} mb={2}>Update Database Cache </Text>
                {keys && <SelectCommon value={selectedKey} placeholder="Select a key" setValue={(e) => {
                    setSelectedKey(e)
                }} options={keys}/>}
                {!!error && <Text as={"h3"} color={'red'}>{error}</Text>}
                <Textarea
                    isInvalid={!isJSONValid}
                    mt={4}
                    value={selectedValue}
                    onChange={(e) => {
                        setSelectedValue(e.target.value)
                    }}
                    minH={300}
                />

                {!isJSONValid && <Text color='red'>Invalid JSON</Text>}

                <HStack mt={4}>
                    <Button colorScheme="blue" isLoading={isFetching} onClick={handleFetch}>Fetch</Button>
                    <Button onClick={() => prettyPrint(selectedValue)}>Pretty</Button>
                    <Button colorScheme="green" isLoading={isSaving} onClick={handleSave}>Save</Button>
                </HStack>
            </Box>}
        </>
    )
}

export default DatabaseConfigManager;
import {Outlet} from "react-router-dom";
import {useState, useEffect} from "react";
import useRefreshToken from '../../hooks/useRefresh';
import useAuth from '../../hooks/useAuth';
import Loader from "../common/Loader";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    //@ts-ignore
    const {auth, persist} = useAuth();

    // @ts-ignore
    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                console.error(err);
            } finally {
                isMounted && setIsLoading(false);
            }
        }

        (!auth?.token) ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, [])

    return (
        <>
            {!persist ? <Outlet/> :
                isLoading
                    ? <Loader/>
                    : <Outlet/>
            }
        </>
    )
}

export default PersistLogin
import React, {useEffect} from "react";
import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/table";
import {Box, Button, Checkbox, CircularProgress, Link, Progress, Tooltip} from "@chakra-ui/react";
import AlertComponent from "../common/Alert";
import {produce} from "immer";
import toast from "react-hot-toast";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {TiTick, TiTimes} from "react-icons/ti";
import Loader from "../common/Loader";

interface Props {
    videos: any;
    isLoading: boolean;
    type: string;
    uploadedVideos: any;
    setUploadedVideos: any;
}

const VideoUploadResult: React.FC<Props> = ({videos, isLoading, type, setUploadedVideos, uploadedVideos}) => {
    const [selected, setSelected] = React.useState([]);
    const [isSubmiting, setIsSubmiting] = React.useState(false);
    const axiosPrivate = useAxiosPrivate()

    useEffect(() => {
        setSelected([])
    }, [uploadedVideos])


    const handleSelected = (id: string) => {
        setSelected(produce(selected, draft => {
            if (draft.includes(id)) {
                draft.splice(draft.indexOf(id), 1);
            } else {
                draft.push(id);
            }
        }));
    };

    const handleSelectAll = () => {
        setSelected(produce(selected, draft => {
            if (draft.length === 0) {
                draft = videos.map(video => video.id);
            } else {
                draft = [];
            }
            return draft;
        }));
    };

    const handleUpload = async () => {
        setIsSubmiting(true);

        const res = await axiosPrivate.post('/video/add', {
            ids: selected,
            type: type
        })

        if (res?.data) {
            if (res.data?.status === 200) {
                setUploadedVideos(res?.data?.data);
                toast.success(res?.data?.message);
            } else {
                toast.error(res?.data?.message);
            }
        }

        setIsSubmiting(false);
    };

    return (
        <>
            {videos && videos.length === 0 &&
                <AlertComponent isSuccess={false} title={"No Response"} description={"No response from server"}/>}

            {!uploadedVideos && videos && videos.length > 0 && !isLoading &&
                <Box
                    display='flex'
                    justifyContent='flex-end'
                    mb={2}
                >
                    <Button
                        isLoading={isSubmiting}
                        colorScheme="green"
                        onClick={async () => {
                            await handleUpload();
                        }}
                    >
                        Upload
                    </Button>
                </Box>
            }


            {!uploadedVideos && videos && videos.length > 0 &&
                <Table colorScheme='teal'>
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Title</Th>
                            <Th>Message</Th>
                            <Th>Success</Th>
                            <Th>
                                <Checkbox
                                    onChange={handleSelectAll}
                                />
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {videos && videos.map((video, index) => <VideoRow
                            title={video?.title}
                            id={video?.id}
                            success={video?.success}
                            message={video?.message}
                            key={index}
                            setSelected={handleSelected}
                            isChecked={selected.includes(video?.id)}
                            isLoading={isLoading}
                        />)}
                    </Tbody>
                </Table>
            }

            {isSubmiting && <Progress
                isIndeterminate={true}
                colorScheme="green"
                size='sm'
                maxH={6}
            />
            }

            {uploadedVideos && <Table colorScheme='teal'>
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Title</Th>
                        <Th>Message</Th>
                        <Th>Success</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {uploadedVideos && uploadedVideos.map((video, index) =>
                        <VideoRow
                            title={video?.title}
                            id={video?.id}
                            success={video?.success}
                            message={video?.message}
                            key={index}
                            setSelected={handleSelected}
                        />)}
                </Tbody>
            </Table>
            }

        </>
    )
}

interface VideoRowProps {
    title: string;
    id: string;
    success: boolean;
    message: string;
    setSelected: (id: string) => void;
    isChecked?: boolean;
    key: number;
    isLoading?: boolean;
}

const VideoRow: React.FC<VideoRowProps> = ({title, id, success, message, key, isLoading, setSelected, isChecked}) => {
    return (
        <>
            {!isLoading && <Tr
                key={key}
                style={{
                    backgroundColor: success ? '#2c6914' : '#9d1717',
                    color: 'white',
                    fontWeight: 'semi-bold'
                }}
            >
                <Td
                    onClick={() => {
                        //Open in a new tab
                        window.open('/video-edit/' + id, '_blank')
                    }}
                >
                    <Tooltip label={"Open Video"}>
                        <Link>
                            {id}
                        </Link>
                    </Tooltip>

                </Td>
                <Td>{title}</Td>
                <Td>{message}</Td>
                <Td>{success ? <TiTick/> : <TiTimes/>}</Td>
                {<Td><Checkbox
                    onChange={() => {
                        setSelected(id)
                    }}
                    isChecked={isChecked}
                    isDisabled={!success}
                /></Td>}
            </Tr>}
        </>
    )
}

export default VideoUploadResult;

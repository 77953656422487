import React from "react";
import ModuleContentComponent from "../module/module-content-edit";
import {PLAYLIST_PAGE} from "../../types/Strings";
import AlertComponent from "../common/Alert";

interface Props {
    videos: any[];
    isNew: boolean;
    id?: number;
}

const PlaylistVideoOrder: React.FC<Props> = ({videos, isNew, id}) => {
    return (
        <>
            {!videos && !isNew &&
                <AlertComponent isSuccess={false} title={"Can't fetch videos"} description={`Please try again later`}/>}
            {(videos || isNew) &&
                <ModuleContentComponent data={isNew ? [] : videos} source={PLAYLIST_PAGE} isNew={isNew} playlist_id={id}/>
            }
        </>
    )
}

export default PlaylistVideoOrder;
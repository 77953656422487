import {Box, Button} from "@chakra-ui/react";
import React from "react";
import {Form, Formik} from "formik";
import InputField from "../custom/InputField";
import * as yup from "yup";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const ResetPasswordForm = (props) => {
    const {token} = props;
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const validationSchema = yup.object({
        id: yup.string().required(),
        password: yup.string().min(5).required(),
        password_confirm: yup.string().min(5).required(),
    });

    return (
        <Box my={8} textAlign='left'>
            <Formik
                enableReinitialize={true}
                validateOnChange={true}
                initialValues={{
                    password_confirm: '',
                    password: '',
                    id: token || ' ',
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, {setSubmitting}) => {
                    try {
                        if (data.password !== data.password_confirm) {
                            setSubmitting(false)
                            toast.error('Passwords do not match')
                            return
                        }
                        setSubmitting(true);
                        const response = await axiosPrivate.patch('/auth/reset-password', data);
                        if (response.status === 200) {
                            const {message} = response.data;
                            toast.success(message)
                            navigate('/login')
                        } else {
                            setSubmitting(false)
                            toast.error(response?.data?.message ?? "Something went wrong")
                        }

                    } catch (err) {
                        toast.error(err.response?.data?.message);
                    }

                    setSubmitting(false);

                }}
            >
                {({values, isSubmitting}) => (
                    <Form>
                        <InputField
                            placeholder={"New Password"}
                            name={"password"}
                            type={'password'}
                            value={values.password}
                        />

                        <InputField
                            placeholder={"Confirm New Password"}
                            name={"password_confirm"}
                            type={'password'}
                            value={values.password_confirm}
                        />

                        <Button
                            type={'submit'}
                            // variantColor={color}
                            isLoading={isSubmitting}
                            width='full'
                            mt={4}>Reset Password</Button>

                    </Form>
                )}
            </Formik>
        </Box>
    )
}

export default ResetPasswordForm;
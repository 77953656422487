import React from 'react';
import {CHANNEL, CREATOR, SUBCHANNEL} from "../../types/Strings";
import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import PageByType from "../../components/pages/PageByType";

const Pages = () => {
    return (
        <>
            <Tabs isFitted variant='enclosed'>
                <TabList mb='1em' style={{color: "#838a8a"}}>
                    <Tab>Channels</Tab>
                    <Tab>Sub Channels</Tab>
                    <Tab>Creators</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <PageByType type={CHANNEL}/>
                    </TabPanel>
                    <TabPanel>
                        <PageByType type={SUBCHANNEL}/>
                    </TabPanel>
                    <TabPanel>
                        <PageByType type={CREATOR}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default Pages;

import {Box, Button, Flex, Heading} from "@chakra-ui/react";
import React from "react";
import {Form, Formik} from "formik";
import InputField from "../../components/custom/InputField";
import * as yup from "yup";
import toast, {Toaster} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";


function ForgotPassword() {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const validationSchema = yup.object({
        email: yup.string().required().email()
    });

    return (
        <Flex mt={4} width='full' align='center' justifyContent='center'>
            <Box
                borderWidth={1}
                px={4}
                width='full'
                maxWidth='500px'
                borderRadius={4}
                textAlign='center'
                boxShadow='lg'
            >
                <Box p={4}>
                    <Box textAlign='center'>
                        <Heading>Forgot Password</Heading>
                    </Box>
                    <Box my={8} textAlign='left'>
                        <Toaster/>
                        <Formik
                            enableReinitialize={true}
                            validateOnChange={true}
                            initialValues={{
                                email: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={async (data, {setSubmitting}) => {
                                setSubmitting(true);
                                try {
                                    const response = await axiosPrivate.post('/auth/forgot-password', data);

                                    if (response.status === 200) {
                                        const {message} = response.data;
                                        toast.success(message);
                                    } else {
                                        toast.error(response?.data?.message ?? 'Something went wrong');
                                    }
                                } catch (err) {
                                    console.error(err)
                                    toast.error(err?.response?.data?.message ?? 'Something went wrong')
                                    return;
                                }
                                setSubmitting(false);
                            }}
                        >
                            {({values, isSubmitting}) => (
                                <Form>
                                    <InputField
                                        placeholder={"Email"}
                                        name={"email"}
                                        type={'email'}
                                        value={values.email}
                                    />


                                    <Button
                                        type={'submit'}
                                        isLoading={isSubmitting}
                                        width='full'
                                        mt={4}>Sign In</Button>

                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Box>
        </Flex>

    )
}

export default ForgotPassword;

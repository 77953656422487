import React from 'react';
import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/table";
import EditUserModal from "./editUserModal";
import AddUserModal from "./addUserModal";
import Loader from '../common/Loader';
import useAuth from "../../hooks/useAuth";
import GetAccessLevel from "../../utils/GetAccessLevel";
import {ADMIN} from "../../types/Strings";
import UserAccessLevel from "../common/UserAccessLevel";
import StatusIndicator from "../common/StatusIndicator";

interface UsersTableProps {
    users: any[];
    error: string;
    isFetching: boolean;
}

const UsersTable: React.FC<UsersTableProps> = ({users, isFetching, error}) => {
    //@ts-ignore
    const {auth} = useAuth();

    return (
        <>
            {isFetching && <Loader/>}
            {!isFetching &&
            <>
                {GetAccessLevel(auth, [ADMIN]) && <AddUserModal/>}
                <Table>
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Name</Th>
                            <Th>Email</Th>
                            <Th>Phone</Th>
                            <Th>Access Level</Th>
                            <Th>Is Active</Th>
                            <Th>Created At</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users && users.map((user, index) => (
                            <Tr key={index}>
                                <Td>{user.id}</Td>
                                <Td>{user.name}</Td>
                                <Td>{user.email}</Td>
                                <Td>{user.phone}</Td>
                                <Td><UserAccessLevel role={user.access_level}/></Td>
                                <Td><StatusIndicator is_active={user.is_active}/></Td>
                                <Td>{new Date(user.created_at).toDateString()}</Td>
                                <Td>
                                    <EditUserModal
                                        user={user}
                                    />
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </>
            }
        </>
    )
}


export default UsersTable;

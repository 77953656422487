import {useQuery} from "react-query";
import toast from "react-hot-toast";
import PageTableComponent from "../../components/table/table";
import {PLAYLIST_PAGE} from "../../types/Strings";
import React from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loader from "../../components/common/Loader";

const Playlists = () => {
    const axiosPrivate = useAxiosPrivate();
    const {data, isFetching, isLoading} = useQuery("playlists", async function () {
            try {
                return ((await axiosPrivate.get(`/playlist/all`)).data);
            } catch (err) {
                console.log(err.response);
                throw err
            }
        }
    );

    if (data && data?.status !== 200 && !isFetching) {
        toast.error("Error fetching playlists");
    }

    return (
        <>
            {data && <PageTableComponent
                data={data?.data}
                isFetching={isFetching}
                source={PLAYLIST_PAGE}
            />}
            {(isLoading || isFetching) && <Loader/>}
        </>
    );
};

export default Playlists;

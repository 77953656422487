export const CreateButtonNames = {
    "playlists": {
        name: "Playlist",
        route: "playlist-edit/-1"
    },
    "modules": {
        name: "Module",
        route: "module-edit/-1"
    },
    "entities": {
        name: "Entity",
        route: "entity-edit/-1"
    },
    "users": {
        name: "User",
        route: ""
    },
    "pages": {
        name: "Page",
        route: ""
    },

}
import React from 'react';
import {Td, Tr} from "@chakra-ui/table";
import {DragHandleIcon} from "@chakra-ui/icons";
import EntityType from "../../common/EntityType";
import {IoIosRemoveCircleOutline} from "react-icons/io";
import {BsFillLockFill, BsFillUnlockFill} from "react-icons/bs"
import {useDrag, useDrop} from "react-dnd";
import {Box, Checkbox, Link, Tooltip} from "@chakra-ui/react";
import {MODULE_PAGE, PLAYLIST_PAGE} from "../../../types/Strings";
import {SecondsToDuration} from "../../../utils/Time";
import StatusIndicator from "../../common/StatusIndicator";

interface DraggableRowProps {
    item: any;
    handleRemove: (id: string | number) => void;
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
    handleCheck: (checked: boolean, id: string | number,) => void;
    handlePosition: (position: number, id: any) => void;
    source?: string;
    handleLock: (checked: boolean, id: string | number,) => void;

}

const DraggableRow: React.FC<DraggableRowProps> = ({
                                                       item,
                                                       handleRemove,
                                                       index,
                                                       moveRow,
                                                       handleCheck,
                                                       handleLock,
                                                       source
                                                   }) => {
    const DND_ITEM_TYPE = 'row'
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)
    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {
            if (!dropRef.current) {
                return
            }
            // @ts-ignore
            let dragIndex = item.index
            let hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            const hoverBoundingRect = dropRef.current.getBoundingClientRect()
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            const clientOffset = monitor.getClientOffset()
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            moveRow(dragIndex, hoverIndex)
            // @ts-ignore
            item.index = hoverIndex
        },
    })

    const [{isDragging}, drag, preview] = useDrag({
        type: DND_ITEM_TYPE,
        item: {index},
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    preview(drop(dropRef))
    drag(dragRef)

    return (
        <>
            <Tr key={index} ref={dropRef} backgroundColor={
                item?.isNew ? '#3b4966' : item?.is_locked ? '#151f3a' : ''

            }>
                <Td ref={dragRef} style={{
                    cursor: 'move',
                    opacity: isDragging ? 0.5 : 1,
                }}><DragHandleIcon/></Td>
                <Td>{index + 1}</Td>
                <Td
                    onClick={() => {
                        //Open in a new tab
                        window.open('/entity-edit/' + item.id, '_blank')
                    }}
                >
                    <Tooltip label={"Open Entity"}>
                        <Link>
                            {item.id}
                        </Link>
                    </Tooltip>

                </Td>
                <Td
                    _hover={{
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }}
                >
                    <Tooltip label={item?.creator_id}>
                    {item?.creator_name || "NA"}
                    </Tooltip>
                </Td>
                <Td><EntityType type={item?.entity_type}/></Td>
                <Td>{item.title}</Td>
                {source && source === PLAYLIST_PAGE && <Td>{SecondsToDuration(item.duration)}</Td>}
                <Td>

                    <StatusIndicator
                        is_active={item.is_active}
                    />
                </Td>
                <Td><Checkbox isChecked={item.mapping_active}
                              onChange={e => handleCheck(e.target.checked, item.id)}/></Td>

                {source && source === MODULE_PAGE && <Td>
                    <Box
                        onClick={() => handleLock(!item.is_locked, item.id)}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        {item.is_locked ? <BsFillLockFill
                                style={{
                                    color: 'red'
                                }}
                            />
                            : <BsFillUnlockFill
                                style={{
                                    color: 'green',
                                    opacity: 0.5
                                }}
                            />
                        }
                    </Box>
                </Td>}
                <Td>
                    <IoIosRemoveCircleOutline
                        style={{
                            cursor: 'pointer',
                            color: 'red',
                            fontSize: '1.5rem'
                        }}
                        onClick={() => handleRemove(item.id)}/>
                </Td>
            </Tr>
        </>
    )
}

export default DraggableRow;

import React from 'react';
import {useQuery} from "react-query";
import toast from "react-hot-toast";
import {Alert, AlertIcon} from "@chakra-ui/react";
import PageTableComponent from "../../components/table/table";
import {MODULE_PAGE} from "../../types/Strings";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loader from "../../components/common/Loader";

const Modules: React.FC = () => {
    const axiosPrivate = useAxiosPrivate();
    const {data, isFetching, error} = useQuery("all-modules", async function () {
            try {
                return ((await axiosPrivate.get(`/module/all`)).data);
            } catch (err) {
                console.log(err.response);
                throw err
            }
        }
    );

    if (data && data?.status !== 200 && !isFetching) {
        toast.error("Error fetch modules");
    }


    return (
        <>
            {
                error &&
                <Alert status='error'>
                    <AlertIcon/>
                </Alert>
            }
            {isFetching && <Loader/>}
            {!isFetching && data &&
                <PageTableComponent
                    data={data?.data}
                    isFetching={isFetching}
                    source={MODULE_PAGE}
                />
            }

        </>
    )
}

export default Modules;

import {
    Box,
    Button,
    HStack,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import React from "react";
import DatePicker from "react-date-picker";
import {GrAdd} from "react-icons/gr";

interface Props {
    handleSearch: (q, w, e, r, t) => void;
    handleResults: () => void;
    hasSearchedBefore: boolean;
}

const EntitySearchModal: React.FC<Props> = ({handleSearch, handleResults, hasSearchedBefore}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [searchText, setSearchText] = React.useState('');
    const [searchType, setSearchType] = React.useState('all');
    const [searchParam, setSearchParam] = React.useState('any');

    const [entityTypeDisabled, setEntityTypeDisabled] = React.useState(false);
    const [searchParamDisabled, setSearchParamDisabled] = React.useState(false);

    const onSearchTextChange = (e) => {
        const text = e.target.value;
        setSearchText(text);
    };

    const finalRef = React.useRef()
    return (
        <>
            <Button mt={4} onClick={onOpen} colorScheme={'cyan'}>
                <GrAdd style={{marginRight: 5}}/> Add Entities
            </Button>
            {hasSearchedBefore && <Button
                mt={4}
                ml={2}
                onClick={handleResults}
                colorScheme={'cyan'}
            >
                Show Results
            </Button>}
            <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Search for Entities</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <VStack
                            alignItems={"flex-start"}
                        >
                            <Box
                                w={"100%"}
                            >
                                <Input
                                    placeholder="Search for an entity"
                                    size="lg"
                                    variant="filled"
                                    height="50px"
                                    borderRadius="md"
                                    w={"100%"}
                                    value={searchText}
                                    onChange={(e) => onSearchTextChange(e)}
                                    _focus={{
                                        borderColor: "teal.500",
                                        boxShadow: "0 0 0 2px teal.500"
                                    }}
                                    mr={2}
                                />
                            </Box>

                            <Box
                                w={"100%"}
                            >
                                <HStack
                                    spacing={4}
                                    mt={2}>
                                    <Select
                                        placeholder="Select an entity type"
                                        w={"290px"}
                                        height="50px"
                                        value={searchType}
                                        disabled={entityTypeDisabled}
                                        onChange={(e) => setSearchType(e.target.value)}
                                    >
                                        <option value={'all'}>All</option>
                                        <option value={'video'}>Video</option>
                                        <option value={'playlist'}>Playlist</option>
                                        <option value={'link'}>Link</option>
                                        <option value={'post'}>Posts</option>
                                        <option value={'channel'}>Channel</option>
                                        <option value={'subchannel'}>Sub Channel</option>
                                        <option value={'creator'}>Creator</option>
                                    </Select>
                                    <Select
                                        placeholder="Select a parameter"
                                        w={"290px"}
                                        height="50px"
                                        value={searchParam}
                                        disabled={searchParamDisabled}
                                        onChange={(e) => setSearchParam(e.target.value)}
                                    >
                                        <option value={"id"}>Postgres ID</option>
                                        <option value={"title"}>Title</option>
                                        <option value={"any"}>Any</option>
                                    </Select>
                                </HStack>
                                {/*<Box*/}
                                {/*    w={"100%"}*/}
                                {/*>*/}
                                {/*    <VStack*/}
                                {/*        spacing={4}*/}
                                {/*        mt={4}>*/}
                                {/*        <Text>Start Date</Text>*/}
                                {/*        <DatePicker*/}
                                {/*            value={startDate}*/}
                                {/*            onChange={(date: Date) => {*/}
                                {/*                setStartDate(date);*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*        <Text>End Date</Text>*/}
                                {/*        <DatePicker*/}
                                {/*            value={endDate}*/}
                                {/*            onChange={(date: Date) => {*/}
                                {/*                setEndDate(date);*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    </VStack>*/}
                                {/*</Box>*/}
                            </Box>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button mr={2}
                                colorScheme="green"
                                onClick={() => {
                                    handleSearch(searchText, searchParam, searchType, startDate, endDate)
                                    onClose()
                                }}>Search
                        </Button>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default EntitySearchModal;

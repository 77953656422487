import React from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
    Box,
    Button,
    CircularProgress,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure
} from "@chakra-ui/react";
import StatusIndicator from "./StatusIndicator";
import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/table";
import {ENTITY_PAGE} from "../../types/Strings";

interface Props {
    id: number;
    source?: string;
}

const PresentOnModal: React.FC<Props> = ({id, source}) => {
    const [result, setResult] = React.useState<any>(null);
    const [error, setError] = React.useState<any>(null);
    const isEntity = source === ENTITY_PAGE;
    const axiosPrivate = useAxiosPrivate();

    React.useEffect(() => {
        const fetchData = async () => {
            const url = `/${isEntity ? 'entity' : 'module'}/present?id=${id}`
            const res = await axiosPrivate.get(url);
            if (res.status === 200) {
                setResult(res.data?.data);
            } else {
                setError("Error, please try again later");
            }
        }
        fetchData();
    }, [id]);
    const {isOpen, onOpen, onClose} = useDisclosure()

    return (
        <>
            <Box>
                <Text fontSize="lg">{isEntity ? 'Entity' : 'Module'} Present Inside Modules Info</Text>
                <Button
                    mt={2}
                    onClick={onOpen}
                    colorScheme="yellow"
                >
                    Show
                </Button>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} size="3xl">
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>{isEntity ? 'Entity' : 'Module'} Present On</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {!result && <CircularProgress/>}
                        {error && <Text>{error}</Text>}
                        {result && result.length === 0 && <Text color='yellow' fontSize={"20px"}>This Entity is not present inside any module</Text>}
                        {result && result.length > 0 && <Table>
                            <Thead>
                                <Tr>
                                    <Th>ID</Th>
                                    <Th>Name</Th>
                                    <Th>Mapping Status</Th>
                                    <Th>Module Status</Th>
                                    <Th>Created At</Th>
                                    <Th>Created By</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {result && result.map((item: any) => (
                                    <Tr>
                                        <Td
                                            onClick={() => {
                                                //Open in a new tab
                                                window.open('/module-edit/' + item.id, '_blank')
                                            }}
                                        >
                                            <Tooltip label={"Click to edit the module"}>
                                                <Link>
                                                    {item.id}
                                                </Link>
                                            </Tooltip>

                                        </Td>
                                        <Td>{item.name}</Td>
                                        <Td>
                                            <StatusIndicator is_active={item.is_active}/>
                                        </Td>
                                        <Td>
                                            <StatusIndicator is_active={item.module_is_active}/>
                                        </Td>
                                        <Td>{new Date(item.created_at).toDateString()}</Td>
                                        <Td>{item.created_by.name}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default PresentOnModal;
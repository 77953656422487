import {
    Box,
    Button,
    CircularProgress,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {MdOutlineContentCopy} from "react-icons/md";
import React from "react";
import copy from "copy-to-clipboard";
import {toast} from "react-hot-toast";

const AnalyticsStack = ({data, type}) => {
    const navigate = useNavigate();
    const [isMouseOver, setIsMouseOver] = React.useState(false);

    const copyToCopyClipboard = () => {
        const dataToCopy = data.map(item => item.id).join(',');
        copy(dataToCopy);
        toast.success("Copied to clipboard");
    }

    return (
        <Box
            p={3}
            borderWidth="1px"
            borderRadius="md"
            borderColor="#334057"
        >
            {!data && <CircularProgress/>}
            {
                <Box>
                    <TableContainer>
                        {data.length === 0 && <Text>No data available</Text>}
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th>#</Th>
                                    <Th>
                                        <Stack
                                            direction={'row'}
                                        >
                                            <Text>ID</Text>
                                            {data && data.length > 0 && <MdOutlineContentCopy
                                                size={28}
                                                onClick={copyToCopyClipboard}
                                                onMouseOver={() => {
                                                    setIsMouseOver(true)
                                                }}
                                                onMouseLeave={() => {
                                                    setIsMouseOver(false)
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                    opacity: isMouseOver ? 1 : 0.5,
                                                    transform: isMouseOver ? 'scale(1.2)' : 'scale(1)',
                                                }}
                                            />}
                                        </Stack>
                                    </Th>
                                    <Th>Title</Th>
                                    <Th>Created By</Th>
                                    <Th>Created On</Th>
                                    <Th>Edit</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data && data.map((item, index) => (
                                    <Tr>
                                        <Td>{index + 1}</Td>
                                        <Td>{item.id}</Td>
                                        <Td>{item.name}</Td>
                                        <Td>{item.created_by?.name}</Td>
                                        <Td>{new Date(item.created_at).toDateString()}</Td>
                                        <Td><Button
                                            size={"sm"}
                                            colorScheme={"whatsapp"}
                                            ml={2}
                                            onClick={() => {
                                                navigate(`${type}-edit/${item.id}`)
                                            }}
                                        >Open</Button></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            }
        </Box>
    )
}

export default AnalyticsStack;
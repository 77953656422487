import React from "react";
import {
    Box,
    Button,
    CircularProgress,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure
} from "@chakra-ui/react";
import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/table";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

interface Props {
    id: number;
    source?: string;
}

const EntityPresentInModuleModal: React.FC<Props> = ({id}) => {
    const [result, setResult] = React.useState<any>(null);
    const [error, setError] = React.useState<any>(null);
    const axiosPrivate = useAxiosPrivate();

    React.useEffect(() => {
        const fetchData = async () => {
            const url = `/entity/present?id=${id}`;
            const res = await axiosPrivate.get(url);
            if (res.status === 200) {
                if (!res.data.status) {
                    setError(res.data.message);
                } else {
                    setResult(res.data?.data);
                }
            } else {
                setError("Error, please try again later");
            }
        }
        fetchData();
    }, []);

    const {isOpen, onOpen, onClose} = useDisclosure()
    return (
        <>
            <Box>
                <Text fontSize="lg">Entity present in modules Info</Text>
                <Button
                    mt={2}
                    onClick={onOpen}
                    colorScheme="yellow"
                >
                    Show
                </Button>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} size="3xl">
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Present In Modules as follows</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        {!result && <CircularProgress/>}
                        {error && <Text>{error}</Text>}
                        {result && result.length === 0 &&
                            <Text color='yellow' fontSize={"20px"}>This Entity is not present inside any Module</Text>}
                        {result && result.length > 0 && <Table>
                            <Thead>
                                <Tr>
                                    <Th>ID</Th>
                                    <Th>Name</Th>
                                    <Th>Created By</Th>
                                    <Th>Last Modified</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {result && result.map((item: any) => (
                                    <Tr>
                                        <Td
                                            onClick={() => {
                                                //Open in a new tab
                                                window.open('/module-edit/' + item.id, '_blank')
                                            }}
                                            color={"#48beea"}
                                        >
                                            <Tooltip label={"Click to edit the module"}>
                                                <Link>
                                                    {item.id}
                                                </Link>
                                            </Tooltip>
                                        </Td>
                                        <Td color={"#58ca40"}>{item.name}</Td>
                                        <Td>
                                            {item.created_by?.name}
                                        </Td>
                                        <Td>{new Date(item.last_modified).toDateString()}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default EntityPresentInModuleModal;
import React from "react";
import {Box, Button, Flex, Image, Progress, Text} from "@chakra-ui/react";
import ModuleContentSearch from "../../components/module/content-order/module-content-search";
import {MODULE_PAGE} from "../../types/Strings";
import {produce} from "immer";
import toaster, {toast} from "react-hot-toast";
import axios from "axios";
import {MdOutlineContentCopy} from "react-icons/md";
import copy from "copy-to-clipboard";

const NotificationImages: React.FC = () => {
    const [selectedEntities, setSelectedEntities] = React.useState([]);
    const [apiResponse, setApiResponse] = React.useState<Array<{
        id: string,
        url: string
    }>>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isMouseOver, setIsMouseOver] = React.useState(null);

    const handleSelect = (items) => {
        setSelectedEntities(produce(selectedEntities, draft => {
            items.forEach(item => {
                if (draft.findIndex(i => i.id === item.id) === -1) {
                    draft.push(item.id);
                }
            });
        }))
    }

    const onSubmit = async () => {
        if (selectedEntities.length === 0) {
            toaster.error("Please select some videos or playlists")
        }
        setIsLoading(true)
        try {

            const response = await axios.post(`${process.env.REACT_APP_IMAGE_PROCESS_API}/get_notification_image`, {
                entity_ids: selectedEntities
            }).then(res => {
                console.log(res)
                return res.data
            }).catch(err => {
                console.log("Error in Notification Image", err)
                console.log(err.message)
                throw err
            })
            setApiResponse(response)
        } catch (err) {
            console.log(err)
        }
        setIsLoading(false)
    }

    return (
        <>
            <Box>
                <Button
                    isLoading={isLoading}
                    onClick={onSubmit}
                    colorScheme={"green"}
                    mb={4}
                >Generate Images for {selectedEntities.length} Entities</Button>
                <Text
                    fontSize="lg"
                    fontWeight="bold"
                    color="gray.500"
                    mb={2}
                >
                    Search for entities that you want Notification generated of
                </Text>
                <ModuleContentSearch handleSelect={handleSelect} source={MODULE_PAGE}/>
            </Box>
            <Box>
                <Flex
                    flexDirection={"column"}
                >
                    {isLoading && <Progress size='lg' isIndeterminate/>}
                    {apiResponse && <Flex flexDirection={"row"}
                                          justifyContent={"space-between"}
                                          alignItems={"center"}
                                          alignContent={"center"}
                                          style={{
                                              padding: 8,
                                          }}
                    >
                        <Box>Entity ID</Box>
                        <Flex flexDirection={"row"} alignItems={"center"}
                              alignContent={"center"}>
                            <Box mr={4}></Box>
                            <Box><Button isDisabled={true}>Copy All</Button></Box>
                        </Flex>

                    </Flex>}
                    {apiResponse && apiResponse.map(item => {
                        return <Flex
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            alignContent={"center"}
                            mb={4}
                            style={{
                                padding: 8,
                                borderRadius: 8,
                                backgroundColor: "#2d3649"
                            }}>
                            <Box>{item.id}</Box>
                            <Flex
                                flexDirection={"row"}
                                alignItems={"center"}
                                alignContent={"center"}
                            >
                                <Box><Image
                                    h={"60px"}
                                    mr={4}
                                    src={item.url}
                                /></Box>
                                <Box>
                                    <Button
                                        colorScheme={"facebook"}
                                        onClick={() => {
                                            copy(item.url)
                                            toast.success("Copied Link to clipboard")
                                        }}
                                        onMouseOver={() => {
                                            setIsMouseOver(item.id)
                                        }}
                                        onMouseLeave={() => {
                                            setIsMouseOver(null)
                                        }}
                                    >

                                        <MdOutlineContentCopy
                                            size={20}
                                            style={{
                                                cursor: 'pointer',
                                                opacity: isMouseOver === item.id ? 1 : 0.5,
                                                transform: isMouseOver === item.id ? 'scale(1.2)' : 'scale(1)',
                                                transition: 'all 0.2s ease-in-out',
                                                transitionDelay: isMouseOver === item.id ? '0s' : '0.2s',
                                            }}
                                        />
                                    </Button></Box>
                            </Flex>
                        </Flex>
                    })}
                </Flex>
            </Box>
        </>
    )
}

export default NotificationImages;

import React from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import * as yup from "yup";
import toast from "react-hot-toast";
import {Form, Formik} from "formik";
import InputField from "../custom/InputField";
import {Box, Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, Tab, Text} from "@chakra-ui/react";
import ModuleContentSearch from "../module/content-order/module-content-search";
import {MODULE_PAGE} from "../../types/Strings";
import {produce} from "immer";
import {MdDelete} from "react-icons/md"
import {Table, Thead, Tr, Th, Td, Tbody} from "@chakra-ui/table";


const CreateDeeplink: React.FC = () => {
    const [selectedEntities, setSelectedEntities] = React.useState([]);
    // const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = React.useState(false);

    const axiosPrivate = useAxiosPrivate();

    const validationSchema = yup.object({
        ids: yup.array().of(yup.object({
            id: yup.string().required(),
            name: yup.string().required(),
        })).required(),
        utm_source: yup.string(),
        utm_medium: yup.string(),
        utm_campaign: yup.string(),
        utm_term: yup.string(),
        utm_content: yup.string(),
    })

    const initialValues = {
        ids: selectedEntities.map(entity => {
            return {
                id: entity.id,
                name: entity.title,
            }
        }),
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_term: "",
        utm_content: "",
    }

    const handleSelect = (items) => {
        setSelectedEntities(produce(selectedEntities, draft => {
            items.forEach(item => {
                if (draft.findIndex(i => i.id === item.id) === -1) {
                    draft.push({...item, isNew: true, mapping_active: true});
                }
            });
        }))
    }

    const handleRemove = (id) => {
        //Add remove animation here
        setSelectedEntities(produce(selectedEntities, draft => {
            const index = draft.findIndex(i => i.id === id);
            if (index !== -1) {
                draft.splice(index, 1);
            }
        }))
    }

    return (
        <>
            <Box>
                <Text
                    fontSize="lg"
                    fontWeight="bold"
                    color="gray.500"
                    mb={2}
                >Search for entities that you want Link generated of</Text>
                <ModuleContentSearch handleSelect={handleSelect} source={MODULE_PAGE}/>
            </Box>
            <Formik
                enableReinitialize={true}
                validateOnChange={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (data, {setSubmitting, setValues}) => {
                    setSubmitting(true);
                    try {
                        try {
                            const response = await axiosPrivate.post('/analytics/deeplink', {
                                ...data,
                            })

                            if (response && response.status === 200) {
                                if (response.data.status == 200) {
                                    toast.success("Deeplink created successfully");
                                } else {
                                    toast.error(response.data.message);
                                }

                            }

                        } catch (err) {
                            toast.error(err.message);
                        }
                    } catch (err) {
                        console.error(err)
                        toast.error(err.response.data.message);
                        return;
                    }
                    setSubmitting(false);
                }}
            >
                {({values, isSubmitting, setValues, errors}) => (
                    <Form>

                        {values.ids && values.ids.length > 0 &&
                            <>
                                <Text
                                    fontSize="lg"
                                    fontWeight="bold"
                                    color="gray.500"
                                    mb={2}
                                >Enter Link Details Here</Text>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>ID</Th>
                                            <Th>Link Name</Th>
                                            <Th>Delete</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody
                                        style={{
                                            overflow: "auto",
                                            marginBottom: "1rem",
                                        }}
                                    >
                                        {values["ids"].map((entity, index) => {
                                            return (
                                                <>

                                                    <Tr>
                                                        <Td>
                                                            <InputField
                                                                name={`ids[${index}].id`}
                                                                placeholder="ID"
                                                                disabled={true}
                                                            />
                                                        </Td>
                                                        <Td>
                                                            <InputField
                                                                name={`ids[${index}].name`}
                                                                placeholder="Name"
                                                                disabled={false}
                                                            />
                                                        </Td>
                                                        <Td
                                                            style={{
                                                                alignContent: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <MdDelete
                                                                onClick={() => {
                                                                    handleRemove(entity.id)
                                                                }}
                                                                size={24}
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                        </Td>
                                                    </Tr>

                                                </>
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                            </>
                        }
                        <Box
                            mt={10}
                        >
                            <Text
                                fontSize="lg"
                                fontWeight="bold"
                                color="gray.500"
                                mb={2}
                            >UTM Parameter Details</Text>
                            <InputField
                                placeholder={"UTM Source"}
                                name={"utm_source"}
                                value={values.utm_source}
                                disabled={false}
                                style={InputFieldStyle}
                            />

                            <InputField
                                placeholder={"UTM Medium"}
                                name={"utm_medium"}
                                value={values.utm_medium}
                                disabled={false}
                                style={InputFieldStyle}
                            />

                            <InputField
                                placeholder={"UTM Campaign"}
                                name={"utm_campaign"}
                                value={values.utm_campaign}
                                disabled={false}
                                style={InputFieldStyle}
                            />

                            <InputField
                                placeholder={"UTM Content"}
                                name={"utm_content"}
                                value={values.utm_content}
                                disabled={false}
                                style={InputFieldStyle}
                            />

                            <InputField
                                placeholder={"UTM Term"}
                                name={"utm_term"}
                                value={values.utm_term}
                                disabled={false}
                                style={InputFieldStyle}
                            />
                            <Button
                                type={'submit'}
                                isLoading={isSubmitting}
                                colorScheme={'green'}
                                mt={4}>Save</Button>
                        </Box>
                    </Form>
                )}


            </Formik>

        </>
    )
}

const InputFieldStyle = {
    "width": "30%",
    "marginBottom": "10px"
}

export default CreateDeeplink;
import React from 'react'
import {Box, CircularProgress, Flex, Text} from '@chakra-ui/react'
import {CurrentPageImpl} from "../../../store/mobx/CreateButton";
import {observer} from "mobx-react";
import RenderNavItems from "./RenderNavItems";
import {SideBarRoutes} from "../../../config/RoutesMaster";

interface Props {
    currentPage?: CurrentPageImpl;
    toggleSidebar?: (e) => void;
    sidebarOpen?: boolean;
}

const Sidebar: React.FC<Props> = observer(({currentPage, toggleSidebar, sidebarOpen}) => {
    return (
        <Box
            w={"220px"}
            flexDir="column"
            justifyContent="space-between"
            bg={"#171923"}
            position={"fixed"}
            left={0}
            top={20}
            bottom={0}
            overflowY={"auto"}
            //Scroll bar color
            sx={{
                "&::-webkit-scrollbar": {
                    width: "4px",

                },
                "&::-webkit-scrollbar-track": {
                    width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                    background: "#b4b6be",
                    borderRadius: "24px",

                },
            }}
        >

            <Flex
                p="5%"
                flexDir="column"
                w="100%"
                alignItems={"flex-start"}
                as="nav"
            >

                {SideBarRoutes && <RenderNavItems navItems={SideBarRoutes} currentPage={currentPage}/>}
                {!SideBarRoutes && <CircularProgress/>}
            </Flex>
        </Box>
    )
})


export default Sidebar;

import {Tab, TabList, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react";
import React from "react";
import SearchResultTable from "./Entity-Wise-Results/SearchResultTable";
import {CHANNEL, CREATOR, LINK, PLAYLIST, POST, SUBCHANNEL, VIDEO} from "../../types/Strings";

interface Props {
    data: any;
    source?: string;

}

const EntitySearchResultTabs: React.FC<Props> = ({data, source}) => {
    console.log(data)
    return (
        <>
            <Tabs isFitted variant='enclosed'>
                <TabList mb='1em' style={{color: "#838a8a"}}>
                    <Tab style={{
                        fontWeight: "bold",
                        alignItems: "center",
                    }}>Videos
                        <Text
                            ml="2"
                            mt="1"
                            color="gray.500"
                            fontSize="sm"
                        >
                            {data.video.length}
                        </Text>
                    </Tab>
                    <Tab style={{fontWeight: "bold"}}>Playlists<Text
                        ml="2"
                        mt="1"
                        color="gray.500"
                        fontSize="sm"
                    >
                        {data.playlist.length}
                    </Text></Tab>
                    <Tab style={{fontWeight: "bold"}}>Creators<Text
                        ml="2"
                        mt="1"
                        color="gray.500"
                        fontSize="sm"
                    >
                        {data.creator.length}
                    </Text></Tab>
                    <Tab style={{fontWeight: "bold"}}>Channels<Text
                        ml="2"
                        mt="1"
                        color="gray.500"
                        fontSize="sm"
                    >
                        {data.channel.length}
                    </Text></Tab>
                    <Tab style={{fontWeight: "bold"}}>Sub Channels<Text
                        ml="2"
                        mt="1"
                        color="gray.500"
                        fontSize="sm"
                    >
                        {data.subchannel.length}
                    </Text></Tab>
                    <Tab style={{fontWeight: "bold"}}>Links<Text
                        ml="2"
                        mt="1"
                        color="gray.500"
                        fontSize="sm"
                    >
                        {data.link.length}
                    </Text></Tab>
                    <Tab style={{fontWeight: "bold"}}>Posts<Text
                        ml="2"
                        mt="1"
                        color="gray.500"
                        fontSize="sm"
                    >
                        {data.posts.length}
                    </Text></Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <SearchResultTable data={data.video} type={VIDEO} source={source}/>
                    </TabPanel>
                    <TabPanel>
                        <SearchResultTable data={data.playlist} type={PLAYLIST} source={source}/>
                    </TabPanel>
                    <TabPanel>
                        <SearchResultTable data={data.creator} type={CREATOR} source={source}/>
                    </TabPanel>
                    <TabPanel>
                        <SearchResultTable data={data.channel} type={CHANNEL} source={source}/>
                    </TabPanel>
                    <TabPanel>
                        <SearchResultTable data={data.subchannel} type={SUBCHANNEL} source={source}/>
                    </TabPanel>
                    <TabPanel>
                        <SearchResultTable data={data.link} type={LINK} source={source}/>
                    </TabPanel>
                    <TabPanel>
                        <SearchResultTable data={data.posts} type={POST} source={source}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default EntitySearchResultTabs;

import React from "react";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import toast from "react-hot-toast";
import PlaylistEditComponent from "../../components/playlist/playlist-edit";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const PlaylistEdit: React.FC = () => {
    const {id} = useParams();
    const isNewPlaylist = id == '-1';
    const axiosPrivate = useAxiosPrivate();

    const {data, isFetching} = useQuery(`playlist-${id}`, async function () {
            try {
                if (id != '-1') {
                    return ((await axiosPrivate.get(`/playlist?id=${id}`))?.data);
                }
            } catch (err) {
                console.log(err.response);
                throw err
            }
        }
    );

    if (data && data?.status !== 200 && !isFetching) {
        toast.error("Error fetching this playlist");
    }

    return (
        <>
            {((data && data?.status === 200) || isNewPlaylist) &&
                 <PlaylistEditComponent playlist={isNewPlaylist ? {} : data?.data} isNew={isNewPlaylist}/>
            }
        </>
    )
}

export default PlaylistEdit;

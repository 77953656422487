import {ADMIN, EDITOR, VIEWER} from "../types/Strings";

const GetAccessLevel = (auth: any, accessLevel: string[]): boolean => {
    const role = auth?.user?.access_level;

    const accessLevels = {
        '0': ADMIN,
        '1': EDITOR,
        '2': VIEWER
    };

    if (accessLevel.includes(accessLevels[role])) {
        return true;
    } else {
        return false;
    }
};

export default GetAccessLevel;
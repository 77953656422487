import React from "react";
import {Box, Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import SavedQueries from "../../components/misc/data-query-manager/SavedQueries";
import SavedQueryRunResult from "../../components/misc/data-query-manager/SavedQueryRunResult";
import PreviouslyRunQueries from "../../components/misc/data-query-manager/PreviouslyRunQueries";

const DataQueriesManager: React.FC = () => {
    const [currentQuery, setCurrentQuery] = React.useState(null);
    return (
        <>
            <Tabs isFitted variant='enclosed'>
                <TabList mb='1em' style={{color: "#838a8a"}}>
                    <Tab>Saved Queries</Tab>
                    <Tab>Previously Run Queries</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Box
                            display="grid"
                            gridTemplateColumns="1fr 2fr"
                            w={"100%"}
                            h={"60vh"}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                            >
                                <SavedQueries
                                    setCurrentQuery={setCurrentQuery}
                                    currentQuery={currentQuery}
                                />
                            </Box>
                            <Box
                                w={"100%"}
                                p={2}
                                ml={2}
                            >
                                {currentQuery &&
                                    <SavedQueryRunResult currentQuery={currentQuery}/>
                                }
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <PreviouslyRunQueries/>
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </>
    )
}

export default DataQueriesManager
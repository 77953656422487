import ModuleEditForm from "./module-edit-form";

const ModuleEditPage = ({data, isNew}) => {
    return (
        <>
            <ModuleEditForm data={data} isNew={isNew}/>
        </>
    )
}

export default ModuleEditPage;
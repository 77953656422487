import {Text} from "@chakra-ui/react";
import React from "react";

const StatusIndicator = ({is_active}) => {
    if (is_active === null || is_active === undefined) {
        return <Text color="red.500">NA</Text>
    }
    return (<>
            {is_active ? <Text color={"#35DF91"} style={{fontWeight:"bold"}}>Active</Text> : <Text color="#D52F0D">Inactive</Text>}
        </>

    )
}

export default StatusIndicator;
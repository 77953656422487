import React from "react";
import {FormControl, FormLabel} from "@chakra-ui/react";
import Select from "react-select";

interface Props {
    options: Array<{
        value: number | string,
        label: string,
    }>,
    handleChange: (e: any) => void,
    isLoading: boolean,
    placeholder: string,
    value: any,
    isDisabled?: boolean,
}

const SearchableSelect: React.FC<Props> = ({
                                               options,
                                               handleChange,
                                               isLoading,
                                               placeholder,
                                               value,
                                               isDisabled = false,
                                           }) => {
    return (
        <>
            <FormControl>
                <FormLabel>
                    {placeholder}
                </FormLabel>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={value}
                    defaultValue={options[0]}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    isClearable={true}
                    isSearchable={true}
                    options={options}
                    styles={{
                        //#1A202C
                        control: (provided, state) => ({
                                ...provided,
                                width: "300px",
                                border: "1px solid #e2e8f0",
                                borderRadius: "5px",
                                backgroundColor: "#2d3d50",

                                ...(state.isFocused && {
                                    color: 'white',
                                }),
                                "&:hover": {
                                    border: "1px solid #e2e8f0",
                                    color: 'white',
                                },
                                ...(state.isDisabled && {
                                    opacity: 0.5,
                                })
                            }
                        ),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: '#2d3d50',
                            color: 'white',
                            "&:hover": {
                                backgroundColor: "#1c2633",
                                color: "white",
                            },
                            ...(state.isSelected && {
                                backgroundColor: "#1c2633",
                                color: "white",
                            })
                        }),
                        menu: (provided, state) => ({
                            ...provided,
                            zIndex: 2,
                            backgroundColor: 'red',
                        }),
                        menuList: (provided, state) => ({
                            ...provided,
                            zIndex: 2,
                            backgroundColor: '#2d3d50',
                        }),
                        placeholder: (provided, state) => ({
                            ...provided,
                            color: 'white',
                        }),
                        singleValue: (provided, state) => ({
                            ...provided,
                            color: 'white',

                        }),
                        input: (provided, state) => ({
                            ...provided,
                            color: 'white',
                        }),
                        loadingIndicator: (provided, state) => ({
                            ...provided,
                            color: 'white',
                        }),
                    }}
                    onChange={(e) => handleChange(e)}
                />
            </FormControl>
        </>
    );
};

export default SearchableSelect;

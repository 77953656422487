import React from 'react';
import toast, {Toaster} from "react-hot-toast";
import {useQuery} from "react-query";
import PageTableComponent from "../../components/table/table";
import {useParams} from "react-router-dom";
import {PAGE, STATIC} from "../../types/Strings";
import ModuleSearchModal from "../../components/module/module-search-modal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loader from "../../components/common/Loader";

const Page = () => {
    const {type, id} = useParams();
    const axiosPrivate = useAxiosPrivate();
    const {data, isFetching, error, status, refetch} = useQuery(`page-${type}-${id}`, async function () {
            try {

                if (type === STATIC && id ==='20132') {
                    const res = (await axiosPrivate.get('/static/home')).data;
                    if (res.status === 404) {
                        toast.error(res.message);
                        return null
                    }
                    return (res?.data?.modules);
                } else {
                    const res = (await axiosPrivate.get(`/page?id=${id}`)).data;
                    if (res.status === 404) {
                        toast.error(res.message);
                        return null
                    }

                    return (res?.data?.modules);
                }
            } catch (err) {
                console.log(err.response);
                throw err
            }
        }
    );


    if (data && data.length === 0) {
        toast.error("No Data found");
    }

    return (

        <>
            {isFetching && <Loader/>}
            {status === 'success' && <ModuleSearchModal refetch={refetch} page_id={id} page_type={type}/>}
            {status === 'success' && data &&
                <PageTableComponent
                    data={data}
                    setData={() => {
                    }}
                    isFetching={isFetching}
                    source={PAGE}
                    error={error as string}/>
            }
            <Toaster
            />
        </>

    )
}

export default Page;

import {ColorModeScript} from "@chakra-ui/react"
import * as React from "react"
import ReactDOM from "react-dom"
import {App} from "./App"
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthProvider} from "./store/context/AuthProvider";
import NoInternetConnection from "./components/common/InternetConnection";


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <NoInternetConnection>
            <AuthProvider>
                <ColorModeScript/>
                <Routes>
                    <Route path="/*" element={<App/>}/>
                </Routes>
            </AuthProvider>
            </NoInternetConnection>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
)


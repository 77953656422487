export const VIDEO = "video";
export const PLAYLIST = "playlist";
export const CHANNEL = "channel";
export const SUBCHANNEL = "subchannel";
export const LINK = "link";
export const SEARCH = "search";
export const QUOTE = "quote";
export const PAGE = "page";
export const COURSE = "course";
export const CREATOR = "creator";
export const MODULE = "module";
export const STATIC = "static";
export const MANTRA = 'mantra';
export const PANCHANG = 'panchang';
export const SUVICHAR = 'suvichar';
export const POST = 'post';
export const LARGE_RECTANGLE = "LARGE_RECTANGLE"
export const LARGE_RECTANGLE_169 = "LARGE_RECTANGLE_169"
export const LARGE_RECTANGLE_RICH = "LARGE_RECTANGLE_RICH"
export const MEDIUM_SQUARE = "MEDIUM_SQUARE"
export const LARGE_SQUARE = "LARGE_SQUARE"
export const SMALL_SQUARE = "SMALL_SQUARE"
export const HORIZONTAL_RECTANGLE = "HORIZONTAL_RECTANGLE"
export const MEDIUM_RECTANGLE = "MEDIUM_RECTANGLE"
export const VERTICAL_RECTANGLE = "VERTICAL_RECTANGLE"
export const CIRCLE = "CIRCLE"
export const SMALL_RECTANGLE = "SMALL_RECTANGLE"
export const RECENTLY_PLAYED = "RECENTLY_PLAYED"
export const VIDEO_SQUARE = "VIDEO_SQUARE"
export const PROFILE = "profile"
export const VERTICAL_SCROLL_SINGLE_COLUMN = "VERTICAL_SCROLL_SINGLE_COLUMN"
export const VERTICAL_SCROLL_TWO_COLUMN = "VERTICAL_SCROLL_TWO_COLUMN"
export const THREE_BY_THREE_SCROLL = "THREE_BY_THREE_SCROLL";
export const HORIZONTAL_SCROLL_SIDE_TITLE = "HORIZONTAL_SCROLL_SIDE_TITLE";
export const CONTINUE_WATCHING_SQUARE = "CONTINUE_WATCHING_SQUARE";

export const THREE_COLUMN_VERTICAL_RECTANGLE = "THREE_COLUMN_VERTICAL_RECTANGLE";

export const HORIZONTAL_SCROLL_TOP_TITLE = "HORIZONTAL_SCROLL_TOP_TITLE";

export const PAGE_END_QUOTE = "PAGE_END_QUOTE";

export const CHANNEL_HEADER = "CHANNEL_HEADER";

export const LARGE_RECTANGLE_V2 = "LARGE_RECTANGLE_V2";

export const LARGE_SQUARE_V2 = "LARGE_SQUARE_V2";

export const SMALL_SQUARE_V2 = "SMALL_SQUARE_V2";

export const VERTICAL_RECTANGLE_V2 = "VERTICAL_RECTANGLE_V2";


//V2
export const FEATURED_LARGE_SQUARE = "FEATURED_LARGE_SQUARE";
export const MEDIUM_VERTICAL_RECTANGLE = "MEDIUM_VERTICAL_RECTANGLE";
export const MEDIUM_VERTICAL_RECTANGLE_COLLECTION_CTA = "MEDIUM_VERTICAL_RECTANGLE_COLLECTION_CTA";
export const FEATURED_VERTICAL_RECTANGLE = "FEATURED_VERTICAL_RECTANGLE";
export const MEDIUM_VERTICAL_RECTANGLE_COLLECTION_NO_CTA = "MEDIUM_VERTICAL_RECTANGLE_COLLECTION_NO_CTA";
export const FEATURED_LARGE_RECTANGLE = "FEATURED_LARGE_RECTANGLE";
export const COLLECTION_CHIP = "COLLECTION_CHIP";
export const STATEMENT_CARDS = "STATEMENT_CARDS";
export const STATEMENT_CARDS_CTA = "STATEMENT_CARDS_CTA";

export const RANDOM = "random"
export const TRENDING_SCORE = "trending_score"
export const MANUAL = "manual"
export const DEFAULT = "default"
export const SLIDER = "SLIDER"
export const HORIZONTAL_SCROLL = "HORIZONTAL_SCROLL"
export const THREE_COLUMN_LAYOUT = "THREE_COLUMN_LAYOUT"
export const TWO_COLUMN_LAYOUT = "TWO_COLUMN_LAYOUT"
export const HEADER = 'HEADER'


//MISC
export const POSITION = "position"
export const SIZE = "size"
export const STATUS = "status"
export const HORIZONTAL = "horizontal"
export const VERTICAL = "vertical"


export const MODULE_PAGE = "module_page"
export const ENTITY_PAGE = "entity_page"
export const PAGES_PAGE = "pages_page"
export const HOME = "home"
export const ANY = "any"
export const PLAYLIST_PAGE = "playlist_page"


export const DEEP_LINK = "DEEP_LINK"
export const EXTERNAL_LINK = "EXTERNAL_LINK"
export const ENTITY = "ENTITY"


export const DND_ITEM_TYPE = 'row'


//User Strings

export const ADMIN = "admin"
export const EDITOR = "editor"
export const VIEWER = "viewer"


export const COLLAPSIBLE = "collapsible"
export const NONCOLLAPSIBLE = "noncollapsible"
export const EVENT_TESTING_SOCKET_EVENT = "event_testing";
export const CONNECTED_SOCKET_IO = "connection";

export const FEATURED_CAROUSEL_BANNER = "FEATURED_CAROUSEL_BANNER";
export const FEATURED_PARALLAX_CAROUSEL_BANNER = "FEATURED_PARALLAX_CAROUSEL_BANNER";

import {RouteType} from "./enums";
import Entities from "../pages/entities/Entities";
import Index from "../pages";
import VideoUpload from "../pages/video/VideoUpload";
import Playlists from "../pages/playlist/Playlists";
import Modules from "../pages/module/Modules";
import Pages from "../pages/page/Pages";
import TagManager from "../pages/TagManager";
import Users from "../pages/auth/Users";
import Page from "../pages/page/Page";
import ModuleEdit from "../pages/module/ModuleEdit";
import PlaylistEdit from "../pages/playlist/PlaylistEdit";
import EntityEdit from "../pages/entities/EntityEdit";
import VideoEdit from "../pages/video/VideoEdit";
import Profile from "../pages/auth/Profile";
import GetDocID from "../pages/misscellaneous/GetDocID";
import DatabaseConfigManager from "../pages/misscellaneous/DatabaseConfigManager";
import React from "react";
import CronJobManager from "../pages/misscellaneous/CronJobManager";
import DeeplinkManager from "../pages/misscellaneous/DeeplinkManager";
import CreateDeeplink from "../components/deeplink-manager/CreateDeeplink";
import EventTesting from "../pages/misscellaneous/EventTesting";
import RazorPay from "../pages/RazorPay";
import UploadSocials from "../pages/misscellaneous/UploadSocials";
import NotificationImages from "../pages/misscellaneous/NotificationImages";
import SocialsPageV2 from "../pages/misscellaneous/SocialsPageV2";
import DataQueriesManager from "../pages/misscellaneous/DataQueriesManager";
import {
    FaChartLine,
    FaCode,
    FaDatabase, FaImage, FaLink,
    FaMobileAlt,
    FaSearch,
    FaTools,
    FaUpload,
    FaUserFriends,
} from "react-icons/fa";
import {MdOutlineEventSeat, MdPlaylistPlay, MdViewModule} from "react-icons/md";

interface RouteMaster {
    path: string;
    component?: React.ComponentType<any>;
    name: string;
    accessLevel: number;
    showInSideBar: boolean;
    type: RouteType;
    children?: RouteMaster[];
    icon?: React.ReactNode;
}

const RoutesMaster: () => RouteMaster[] = () => {
    return [
        {
            path: "",
            component: Index,
            name: "Analytics",
            accessLevel: 0,
            showInSideBar: false,
            type: RouteType.NONCOLLAPSIBLE,
            icon: FaChartLine
        },
        {
            path: "entities",
            component: Entities,
            name: "Entities",
            accessLevel: 0,
            showInSideBar: true,
            type: RouteType.NONCOLLAPSIBLE,
            icon: FaSearch
        },
        {
            name: "Video Upload",
            component: VideoUpload,
            accessLevel: 0,
            showInSideBar: true,
            path: "video-upload",
            type: RouteType.NONCOLLAPSIBLE,
            icon: FaUpload
        },
        {
            name: "Playlists",
            path: "playlists",
            type: RouteType.NONCOLLAPSIBLE,
            component: Playlists,
            accessLevel: 0,
            showInSideBar: true,
            icon: MdPlaylistPlay

        },
        {
            name: "Modules",
            path: "modules",
            type: RouteType.NONCOLLAPSIBLE,
            component: Modules,
            accessLevel: 0,
            showInSideBar: true,
            icon: MdViewModule
        },
        {
            name: "Pages",
            path: "pages",
            type: RouteType.NONCOLLAPSIBLE,
            component: Pages,
            accessLevel: 0,
            showInSideBar: true,
            icon: FaMobileAlt
        },
        // {
        //     name: "Tag Manager",
        //     path: "tags",
        //     type: RouteType.NONCOLLAPSIBLE,
        //     component: TagManager,
        //     accessLevel: 0,
        //     showInSideBar: true
        // },
        {
            name: "Users",
            path: "users",
            type: RouteType.NONCOLLAPSIBLE,
            component: Users,
            accessLevel: 0,
            showInSideBar: true,
            icon: FaUserFriends
        },
        {
            name: "Pages",
            path: "pages/:type/:id",
            type: RouteType.NONCOLLAPSIBLE,
            component: Page,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Module Edit",
            path: "module-edit/:moduleID",
            type: RouteType.NONCOLLAPSIBLE,
            component: ModuleEdit,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Playlist Edit",
            path: "playlist-edit/:id",
            type: RouteType.NONCOLLAPSIBLE,
            component: PlaylistEdit,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Entity Edit",
            path: "entity-edit/:id",
            type: RouteType.NONCOLLAPSIBLE,
            component: EntityEdit,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Video Edit",
            path: "video-edit/:id",
            type: RouteType.NONCOLLAPSIBLE,
            component: VideoEdit,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Profile",
            path: "profile",
            type: RouteType.NONCOLLAPSIBLE,
            component: Profile,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Tag Manager",
            path: "tags",
            type: RouteType.NONCOLLAPSIBLE,
            component: TagManager,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Get Document ID",
            path: "/get-doc-id",
            type: RouteType.NONCOLLAPSIBLE,
            component: GetDocID,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Database Cache Manager",
            path: "database-config-manager",
            type: RouteType.NONCOLLAPSIBLE,
            component: DatabaseConfigManager,
            accessLevel: 0,
            showInSideBar: false,
            icon: FaDatabase
        },
        {
            name: "Cron Jobs Manager",
            path: "cron-jobs-manager",
            type: RouteType.NONCOLLAPSIBLE,
            component: CronJobManager,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Event Testing Panel",
            path: "event-testing",
            type: RouteType.NONCOLLAPSIBLE,
            component: EventTesting,
            accessLevel: 0,
            showInSideBar: false,
            icon: MdOutlineEventSeat
        },
        {
            name: "Deeplink Manager",
            path: "deeplink-manager",
            type: RouteType.NONCOLLAPSIBLE,
            component: DeeplinkManager,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Deeplink Manager - Creator",
            path: "deeplink-manager/create",
            type: RouteType.NONCOLLAPSIBLE,
            component: CreateDeeplink,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Razor Pay Testing",
            path: "razorpay",
            type: RouteType.NONCOLLAPSIBLE,
            component: RazorPay,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Upload Socials",
            path: "upload-socials",
            type: RouteType.NONCOLLAPSIBLE,
            component: UploadSocials,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Generate Notification Images",
            path: "notifications-image",
            type: RouteType.NONCOLLAPSIBLE,
            component: NotificationImages,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Suvichar Page Manager",
            path: "suvichar-page-manager",
            type: RouteType.NONCOLLAPSIBLE,
            component: SocialsPageV2,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Data Query Manager",
            path: "data-queries",
            type: RouteType.NONCOLLAPSIBLE,
            component: DataQueriesManager,
            accessLevel: 0,
            showInSideBar: false
        },
        {
            name: "Developer Tools",
            path: "devs",
            type: RouteType.COLLAPSIBLE,
            showInSideBar: true,
            accessLevel: 0,
            icon: FaCode,
            children: [
                {
                    name: "Event Testing Panel",
                    path: "event-testing",
                    type: RouteType.NONCOLLAPSIBLE,
                    component: EventTesting,
                    accessLevel: 0,
                    showInSideBar: true,
                    icon: MdOutlineEventSeat
                },
                {
                    name: "Manage Database Cache",
                    path: "database-config-manager",
                    type: RouteType.NONCOLLAPSIBLE,
                    component: DatabaseConfigManager,
                    accessLevel: 0,
                    showInSideBar: true,
                    icon: FaDatabase
                },
                {
                    name: "Cron Jobs Manager",
                    path: "cron-jobs-manager",
                    type: RouteType.NONCOLLAPSIBLE,
                    component: CronJobManager,
                    accessLevel: 0,
                    showInSideBar: false,
                    // icon:FaLink
                },
            ]
        },
        {
            name: "Miscellaneous",
            path: "misc",
            type: RouteType.COLLAPSIBLE,
            showInSideBar: true,
            accessLevel: 0,
            icon: FaTools,
            children: [
                {
                    name: "Get Document ID",
                    path: "get-doc-id",
                    type: RouteType.NONCOLLAPSIBLE,
                    component: GetDocID,
                    accessLevel: 0,
                    showInSideBar: false
                },
                {
                    name: "Deeplink Manager",
                    path: "deeplink-manager",
                    type: RouteType.NONCOLLAPSIBLE,
                    component: DeeplinkManager,
                    accessLevel: 0,
                    showInSideBar: true,
                    icon: FaLink
                },

                {
                    name: "Upload Socials",
                    path: "upload-socials",
                    type: RouteType.NONCOLLAPSIBLE,
                    component: UploadSocials,
                    accessLevel: 0,
                    showInSideBar: true,
                    icon: FaUpload
                },
                {
                    name: "Generate Notification Images",
                    path: "notifications-image",
                    type: RouteType.NONCOLLAPSIBLE,
                    component: NotificationImages,
                    accessLevel: 0,
                    showInSideBar: true,
                    icon: FaImage
                },
                {
                    name: "Suvichar Page Manager",
                    path: "suvichar-page-manager",
                    type: RouteType.NONCOLLAPSIBLE,
                    component: SocialsPageV2,
                    accessLevel: 0,
                    showInSideBar: false
                },
                {
                    name: "Data Query Manager",
                    path: "data-queries",
                    type: RouteType.NONCOLLAPSIBLE,
                    component: DataQueriesManager,
                    accessLevel: 0,
                    showInSideBar: true,
                    icon: FaDatabase
                },
            ]
        }
    ]
}
const routesMaster = RoutesMaster();

export const SideBarRoutes = routesMaster.filter(route => route.showInSideBar === true);

export const MainRoutes = routesMaster;

import {Box, Button, Flex, HStack, Text} from "@chakra-ui/react";
import React from "react";
import {BiArrowBack} from "react-icons/bi";
import {VscAdd} from "react-icons/vsc";
import {CreateButtonNames} from "../../utils/RouteNames";
import {CurrentPageImpl} from "../../store/mobx/CreateButton";
import {useNavigate} from "react-router-dom";
import GetPageName from "../../utils/GetPageName";
import {queryClient} from "../../App";

interface Props {
    currentPage: CurrentPageImpl
}

const AppBar: React.FC<Props> = ({currentPage}) => {
    const navigate = useNavigate();
    const rou = window.location.pathname;
    const path = rou.split('/').filter((item: string) => item.trim() !== '');


    const RefreshRefetch = async () => {
        await queryClient.refetchQueries();

    };

    const CreateButton = {
        name: CreateButtonNames[currentPage.current_page]?.name,
        is_active: CreateButtonNames[currentPage.current_page] !== undefined,
        route: CreateButtonNames[currentPage.current_page]?.route,
    }
    // @ts-ignore
    return (
        <>
            <Flex
                zIndex={1}
                position="sticky"
                top={0}
                mb={3}
                mt={-3}
                h={'50px'}
            >
                <HStack
                    spacing={4}
                    flex={1}
                    m="auto"
                    align="center"
                    justify="space-between"
                >
                    <Box>
                        <HStack
                            spacing={12}
                        >
                            <BiArrowBack
                                size={'2rem'}
                                style={{
                                    cursor: 'pointer',
                                    marginTop: '0.4rem'
                                }}
                                onClick={() => {
                                    navigate(-1)
                                }}
                                color={'#fff'}
                            />
                            <Text
                                fontSize="24px"
                                fontWeight="bold"
                            >
                                {GetPageName(path)}
                            </Text>
                        </HStack>
                    </Box>
                    <Box>
                        <HStack
                            spacing={3}
                        >
                            {/*<Text mt={1}>Refresh</Text>*/}
                            {/*<RiRefreshLine*/}
                            {/*    size={'2rem'}*/}
                            {/*    style={{*/}
                            {/*        cursor: 'pointer',*/}
                            {/*        marginTop: '0.4rem',*/}
                            {/*    }}*/}
                            {/*    //Reload page*/}
                            {/*    onClick={() => RefreshRefetch()}*/}
                            {/*    // onClick={() => window.location.reload()}*/}
                            {/*    color={'#fff'}*/}
                            {/*/>*/}
                            {CreateButton.is_active && CreateButton.route !== "" &&
                                <Button
                                    leftIcon={<VscAdd size={20}/>}
                                    borderRadius={8}
                                    p={3}
                                    colorScheme={'green'}
                                    w={"100%"}
                                    onClick={() => {
                                        navigate(CreateButton.route, {replace: true})
                                    }}
                                >
                                    Create {CreateButton.name}
                                </Button>}
                        </HStack>
                    </Box>
                </HStack>
            </Flex>
        </>
    )
}

export default AppBar;

import {MainRoutes} from "../config/RoutesMaster";
const GetPageName = (path: string[]) => {
    let PageName = MainRoutes.find(route => route.path.includes(path[0]))?.name ?? path[0] ?? "Page";
    let AdditionalPageName = path.length > 1 ? " / " + path[1] : ""
    if (PageName === "Pages" && path.length > 1) {
        PageName = GetPageName([path[1]])
        AdditionalPageName = path.length > 1 ? " / " + path[2] : ""
    }
    return PageName + AdditionalPageName;
};

export default GetPageName;
import React from 'react';
import {Box, Button, Input, Progress, Table, Td, Text, Thead, Tr} from "@chakra-ui/react";
import {Tbody} from "@chakra-ui/table";
import EntityType from "../../common/EntityType";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const PostgresIDToDocID = () => {
    const [searchText, setSearchText] = React.useState("");
    const [result, setResult] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>("");
    const axiosPrivate = useAxiosPrivate();

    const onSubmit = async () => {
        setIsLoading(true);
        const ids = searchText.split(",").map(s => s.trim());

        try {
            if (ids.length > 0) {
                const res = await axiosPrivate.post("/analytics/firebase", {ids});

                if (res.status === 200) {
                    if (res.data.status) {
                        setResult(res.data.data);
                    } else {
                        setError(res.data.message);
                    }
                }
            } else {
                setError("Please enter at least one id");
            }
        } catch (err) {
            setError(err.message);
        }
        setIsLoading(false);
    }
    return (
        <Box>
            <Text as={"h3"} fontWeight={"bold"} mb={2}>Postgres ID To Doc ID</Text>
            <Box
                w={"100%"}
            >
                <Input
                    placeholder="Enter comma seperated postgres master ids"
                    size="lg"
                    variant="filled"
                    width="50%"
                    height="50px"
                    borderRadius="md"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    _focus={{
                        borderColor: "teal.500",
                        boxShadow: "0 0 0 2px teal.500"
                    }}
                    mr={2}
                />
                <Button
                    size="lg"
                    variant="solid"
                    width="10%"
                    height="50px"
                    borderRadius="md"
                    mb={2}
                    isDisabled={!!error}
                    isLoading={isLoading}
                    onClick={onSubmit}
                >Search</Button>
            </Box>
            {!!error && <Text color={'red'}>{error}</Text>}
            <Box>
                {isLoading && <Progress size="lg" isIndeterminate/>}
                {!isLoading &&
                    <Table>
                        {result && result.length > 0 &&
                            <>
                                <Thead>
                                    <Tr>
                                        <Td>
                                            Document ID
                                        </Td>
                                        <Td>
                                            Postgres ID
                                        </Td>
                                        <Td>
                                            Type
                                        </Td>
                                        <Td>
                                            Created At
                                        </Td>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {result.map((item, index) => {
                                        return (
                                            <Tr key={index}>
                                                <Td>
                                                    {item.doc_id}
                                                </Td>
                                                <Td>
                                                    {item.pg_id}
                                                </Td>
                                                <Td>
                                                    <EntityType type={item.type}/>
                                                </Td>
                                                <Td>
                                                    {new Date(item.created_at).toDateString()}
                                                </Td>
                                            </Tr>
                                        )
                                    })}
                                </Tbody>
                            </>
                        }
                    </Table>
                }
            </Box>
        </Box>
    );
};

export default PostgresIDToDocID;
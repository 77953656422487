import React from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {Table, Tbody, Th, Thead, Tr} from "@chakra-ui/table";
import DraggableRow from "./draggable-row";
import update from "immutability-helper";
import {produce} from "immer";
import {Box, Heading, HStack, Tooltip} from "@chakra-ui/react";
import {MODULE_PAGE, PLAYLIST_PAGE} from "../../../types/Strings";
import {MdOutlineContentCopy} from "react-icons/md";
import copy from "copy-to-clipboard";
import {toast} from "react-hot-toast";

interface DraggableTableProps {
    data: any[];
    handleRemove?: (id: string | number) => void;
    setData: (data: any[]) => void;
    handleCheck?: (checked: boolean, id: string | number,) => void;
    source?: string;
    handleLock?: (checked: boolean, id: string | number) => void;
}

const DraggableTable: React.FC<DraggableTableProps> = ({data, handleRemove, setData, handleCheck, source, handleLock}) => {
    const [isMouseOver, setIsMouseOver] = React.useState(false);

    React.useEffect(() => {
        setData(produce(data, draft => {
            draft.forEach((item, index) => {
                item.pos = index;
            });
        }));
    }, [data, setData]);

    const copyToCopyClipboard = () => {
        const dataToCopy = data.map(item => item.id).join(',');
        copy(dataToCopy);
        toast.success("Copied to clipboard");
    }

    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = data[dragIndex]
        setData(
            update(data, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            })
        )
    }
    console.log("Playlist Video", data)

    return (
        <DndProvider backend={HTML5Backend}>
            <Heading size="lg" mb={2}>
                Content Order
            </Heading>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Drag</Th>
                        <Th>Position</Th>
                        <Th>
                            <HStack>
                                <Box>
                                    ID
                                </Box>
                                <Tooltip
                                    label="Click to copy all the IDs to clipboard"
                                    aria-label="Click to copy all the IDs to clipboard"
                                    placement="auto-start"
                                >
                                    <MdOutlineContentCopy
                                        size={28}
                                        onClick={copyToCopyClipboard}
                                        onMouseOver={() => {
                                            setIsMouseOver(true)
                                        }}
                                        onMouseLeave={() => {
                                            setIsMouseOver(false)
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            opacity: isMouseOver ? 1 : 0.5,
                                            transform: isMouseOver ? 'scale(1.2)' : 'scale(1)',
                                        }}
                                    />
                                </Tooltip>
                            </HStack>
                        </Th>
                        <Th>Creator</Th>
                        <Th>Content Type</Th>
                        <Th>Title</Th>
                        {source && source === PLAYLIST_PAGE && <Th>Duration</Th>}
                        <Th>Global Active Status</Th>
                        <Th>Mapping Active Status</Th>
                        {source && source === MODULE_PAGE && <Th>Lock Entity</Th>}
                        <Th>Remove</Th>
                    </Tr>
                </Thead>
                {data && data.map((item, index) => (
                    <Tbody>
                        <DraggableRow
                            item={item}
                            handleRemove={handleRemove}
                            index={index}
                            moveRow={moveRow}
                            handleCheck={handleCheck}
                            source={source}
                            handleLock={handleLock}
                            handlePosition={(position: number, id: any) => {

                            }}
                        />
                    </Tbody>
                ))}
            </Table>
        </DndProvider>
    )
}

export default DraggableTable;

import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import toast from "react-hot-toast";
import React from "react";
import EntityEditForm from "../../components/entities/EntityEdit";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loader from "../../components/common/Loader";

const EntityEdit = () => {
    const {id} = useParams();
    const isNew = id === '-1';
    const axiosPrivate = useAxiosPrivate();
    const {data, isFetching} = useQuery(`entity-${id}`, async function () {
            try {
                if (!isNew) {
                    return ((await axiosPrivate.get(`/entity?id=${id}`)).data);
                }

                return {}
            } catch (err) {
                console.log(err.response);
                throw err
            }
        }
    );


    if (!isNew && data?.status !== 200 && !isFetching) {
        toast.error(data.message);
    }

    return (
        <>
            {isFetching && <Loader/>}
            {!isFetching && data &&
                <EntityEditForm data={isNew ? {} : data.data} isNew={isNew}/>}
        </>
    )
}

export default EntityEdit;

import {debounce} from 'lodash'

export default class BulkProcessor {
    callback: Function
    delay: number

    constructor(callback, delay = 1000) {
        this.callback = callback
        this.delay = delay
        return this
    }

    collectedData = []

    debouncedCallback = debounce(
        () => {
            this.callback(this.collectedData)
            this.collectedData = []
        },
        // @ts-ignore
        this.delay,
        // @ts-ignore
        {maxWait: this.delay},
    )

    processData = data => {
        this.collectedData.unshift(data)
        this.debouncedCallback()
        return this
    }
}
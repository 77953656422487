import React, {useEffect} from "react";
import {
    Box,
    Circle,
    CircularProgress,
    FormControl,
    FormLabel,
    HStack,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Switch,
    Text,
    Tooltip, VStack
} from "@chakra-ui/react";
import {PAGE, POSITION, STATUS} from "../../types/Strings";
import {IoIosRemoveCircleOutline} from "react-icons/io";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {Td, Tr} from "@chakra-ui/table";
import EntityType from "../common/EntityType";
import Select from "react-select";
import SearchableSelect from "../custom/SearchableSelect";

interface SinglePageProps {
    type: string,
    page_id: string,
    created_by_name: string,
    position: string,
    status: boolean,
    handlePageRemove: (page_id: any) => void,
    handleChange: (id: number | any, type: string, value: any, page?: any[]) => void,
    index: number,
    page_arr: any[],
    setToastError: (error: string) => void,
    last_modified: string,
}

const SinglePage: React.FC<SinglePageProps> = ({
                                                   type,
                                                   page_id,
                                                   created_by_name,
                                                   status,
                                                   position,
                                                   handlePageRemove,
                                                   handleChange,
                                                   index,
                                                   page_arr,
                                                   setToastError,
                                                   last_modified
                                               }) => {

    const [pages, setPages] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const axiosPrivate = useAxiosPrivate();

    // @ts-ignore
    useEffect(async () => {
        setIsLoading(true);
        const fetchedPages = (await axiosPrivate.get(`/page/all`))?.data?.data?.map(channel => {
            return {
                value: channel.id,
                label: channel.title,
                type: channel.type,
            }
        });

        setPages(fetchedPages);

        if (position == '-1') {
            setError(true);
            setToastError('Position is not set');
        } else {
            setError(null);
            setToastError(null);
        }

        if (page_id == '-1') {
            setError(true);
        } else {
            setError(null);
            setToastError(null);
        }

        const arr = page_arr.filter(page => page.page_id == page_id);
        if (arr.length > 1) {
            setError(true);
            setToastError(`Page is already set`);
        } else {
            setError(null);
            setToastError(null);
        }
        setIsLoading(false)
    }, [page_id, position, page_arr])

    return (
        <>
            <Tr
                style={{
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                    borderRadius: "5px",
                    ...((error || !page_id) && {
                        backgroundColor: "rgba(255, 0, 0, 0.1)"

                    })
                }}
            >
                <Td>
                    <SearchableSelect options={pages}
                                      handleChange={(e) => {
                                          handleChange(index, PAGE, e.value, pages)
                                      }}
                                      isLoading={isLoading}
                                      placeholder={"Select Page"}
                                      value={{
                                          value: page_id,
                                          label: pages?.find(page => page.value == page_id)?.label
                                      }}
                                      isDisabled={page_id != '-1'}
                    />
                </Td>
                <Td>
                    {page_id}
                </Td>
                <Td>
                    <EntityType type={type}/>
                </Td>
                <Td>
                    <NumberInput
                        w={20}
                        min={-20}
                        max={20}
                        value={position ?? index + 1}
                        onChange={(e) => handleChange(index, POSITION, e)}
                    >
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                </Td>

                <Td>
                    <Switch
                        isChecked={status}
                        onChange={(e) => handleChange(index, STATUS, e.target.checked)}
                    />
                </Td>

                <Td>
                    <Box>
                        <HStack align={"center"}>
                            <Tooltip
                                label="Created By"
                            >
                                <Text>{created_by_name ? created_by_name : "N.A."} </Text>
                            </Tooltip>
                            <Circle size={"4px"} bg={"#838a8a"}/>
                            <Tooltip
                                label={"Last modification date"}
                            >
                                <Text>{new Date(last_modified ?? new Date()).toDateString()}</Text>
                            </Tooltip>
                        </HStack>
                    </Box>
                </Td>
                <Td>
                    <IoIosRemoveCircleOutline
                        style={{
                            cursor: 'pointer',
                            color: 'red',
                            fontSize: '1.5rem'
                        }}
                        onClick={() => handlePageRemove(page_id)}/>

                </Td>
            </Tr>
            {!page_id && <CircularProgress/>}
        </>
    )
}


export default SinglePage;

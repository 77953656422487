import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/table";
import StatusIndicator from "../../common/StatusIndicator";
import React from "react";
import {Box, Checkbox, Circle, Heading, HStack, Link, Text, Tooltip, VStack} from "@chakra-ui/react";
import EntityType from "../../common/EntityType";
import PaginationBar from "../../table/pagination-bar";

const EntitiesSearchResult = ({data, handleAdd, handleAll}) => {
    const [results, setResults] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState<number>(0);
    const [filter, setFilter] = React.useState<string>("all");
    const searchSize = data.length;
    const perPage = 10;

    React.useEffect(() => {
        setResults(data.slice(pageNumber * perPage, pageNumber * perPage + perPage));
        //Filter data based on type
        if (filter === "all") {
            setResults(data.slice(pageNumber * perPage, pageNumber * perPage + perPage));
        } else {
            setResults(data.filter(item => item.type === filter));
        }
    }, [data, pageNumber, filter]);

    const handlePaginationNext = () => {
        if ((searchSize / perPage > pageNumber + 1)) {
            setPageNumber(pageNumber + 1);
        }
    }

    const handlePaginationPrevious = () => {
        if (pageNumber > 0) {
            setPageNumber(pageNumber - 1);
        }
    }

    const handleGoToPage = (page: number) => {
        if (page >= 0 && page < Math.ceil(searchSize / perPage)) {
            setPageNumber(page);
        }
    }

    return (
        <>
            <PaginationBar
                perPage={perPage}
                pageNumber={pageNumber}
                totalModules={searchSize}
                next={handlePaginationNext}
                previous={handlePaginationPrevious}
                goToPage={handleGoToPage}
                filter={filter}
                setFilter={setFilter}
                setOpenSearchModal={null}
            />
            <Table>
                <Thead>
                    <Tr>
                        <Th>
                            <Checkbox onChange={(e) => handleAll(e.target.checked)}/>
                        </Th>
                        <Th>
                            ID
                        </Th>
                        <Th>
                            Title
                        </Th>
                        <Th>
                            Type
                        </Th>
                        <Th>
                            Is Active
                        </Th>

                    </Tr>
                </Thead>
                <Tbody>
                    {results && results.map((item, index) => (
                        <Tr key={index}>
                            <Td>
                                <Checkbox
                                    isChecked={item.checked}
                                    onChange={(e) => handleAdd(item.id, e.target.checked)}
                                />
                            </Td>
                            <Td
                                onClick={() => {
                                    //Open in a new tab
                                    window.open('/entity-edit/' + item.id, '_blank')

                                }}
                            >
                                <Tooltip label={"Open Entity"}>
                                    <Link>
                                        {item.id}
                                    </Link>
                                </Tooltip>

                            </Td>
                            <Td>
                                <VStack align={"left"}>
                                    <Box>
                                        {item?.title || item?.name}
                                    </Box>
                                    <Box>
                                        <HStack align={"center"}>
                                            <Tooltip
                                                label="Created By"
                                            >
                                                <Text color={"#838a8a"}>{item?.created_by} </Text>
                                            </Tooltip>
                                            <Circle size={"4px"} bg={"#838a8a"}/>
                                            <Tooltip
                                                label={"Last modification date"}
                                            >
                                                <Text
                                                    color={"#838a8a"}>{new Date(item.last_modified).toDateString()} </Text>
                                            </Tooltip>
                                        </HStack>
                                    </Box>
                                </VStack>
                            </Td>
                            <Td><EntityType type={item?.type}/></Td>
                            <Td><StatusIndicator is_active={item?.is_active}/></Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>

        </>
    )
}

export default EntitiesSearchResult;

import React from "react";
import ReactLoading from "react-loading";
import {Flex} from "@chakra-ui/react";

function Loader() {
    return (
        <>
            <Flex
                justifyContent="center"
                alignItems="center"
                height="70vh"
                width="80vw"
            >
                <ReactLoading type={"bars"} color={"#e1970b"} height={'10%'} width={'10%'}/>
            </Flex>

        </>
    );
}

export default Loader;
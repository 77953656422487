import {NONCOLLAPSIBLE} from "../../../types/Strings";
import NavItem from "./NavItem";
import CollapsibleNavitem from "./CollapsibleNavitem";
import React from "react";
import {Flex, Icon, Text} from "@chakra-ui/react";

const RenderNavItems = ({navItems, currentPage}) => {
    navItems = navItems.filter(item => item.showInSideBar === true);
    return (
        <>
            {
                navItems && navItems.map((route, index) => {
                    if (route.type === NONCOLLAPSIBLE) {
                        return (
                            <NavItem key={index}
                                     index={index}
                                     route={route.path}
                                     active={true}
                                     currentPage={currentPage}
                            >
                                <Flex
                                    w={"100%"}
                                    alignItems={"center"}
                                    justifyContent={"flex-start"}
                                >
                                    <Icon
                                        as={route.icon}
                                        color={currentPage.current_page === route.path ? "#767a7a" : "#AEC8CA"}
                                        w={4}
                                        h={4}
                                        mr={2}
                                    />


                                    <Text
                                        display={"flex"}
                                        style={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        {route.name}
                                    </Text>
                                </Flex>

                            </NavItem>
                        )
                    } else {
                        return (
                            <CollapsibleNavitem key={index}
                                                index={index}
                                                // title={route.name}
                                                route={route.path}
                                                active={true}
                                                currentPage={currentPage}
                                                childNavItems={route.children}
                            >
                                <Flex
                                    w={"100%"}
                                    alignItems={"center"}
                                    justifyContent={"flex-start"}
                                >
                                    <Icon
                                        as={route.icon}
                                        color={currentPage.current_page === route.path ? "#767a7a" : "#AEC8CA"}
                                        w={4}
                                        h={4}
                                        mr={2}
                                    />


                                    <Text
                                        display={"flex"}
                                        style={{
                                            textAlign: 'left',
                                        }}
                                    >
                                        {route.name}
                                    </Text>
                                </Flex>
                            </CollapsibleNavitem>
                        )
                    }
                })
            }
        </>
    )
}

export default RenderNavItems;

import ProfileForm from "../../components/profile/profileForm";

const Profile = () => {

    return (
        <ProfileForm/>
    );
};

export default Profile;

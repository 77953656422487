import React, {InputHTMLAttributes, useEffect} from 'react';
import {Box, Flex, FormControl, FormErrorMessage, FormLabel, Image, Select, Text} from "@chakra-ui/react";
import {useField} from "formik";
import ReactSelect from "react-select";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
    placeholder: string;
    name: string;
    options: Array<{
        label: string;
        value: string;
        image_url?: string;
    }>;
    props?: any;
    isDisabled?: boolean;
    setValue?: (value: any) => void;
    value?: any;
};

const SelectField: React.FC<InputFieldProps> = ({
                                                    placeholder,
                                                    size: _,
                                                    style,
                                                    ...props
                                                }) => {

    const [field, meta] = useField({...props});
    const errorText = meta.error && meta.touched ? meta.error : "";

    //Autoselect first option if no value is selected

    useEffect(() => {
        if (props?.options?.length > 0
            //and no value is selected
            && !field.value
        ) {
            field.onChange({
                target: {
                    name: field.name,
                    value: props?.options[0]?.value
                }
            });
        }
    }, [props?.options?.length]);

    return (
        <>
            <FormControl isInvalid={!!meta.error} style={style}>
                <FormLabel htmlFor={field.name}>{placeholder}</FormLabel>
                {/*@ts-ignore*/}
                <Select
                    id={field.name}
                    {...field}
                    {...props}
                >
                    {props.options?.map((option, index) => (
                        <option
                            key={index}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    ))}
                </Select>
                {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
            </FormControl>
        </>
    );
};

const SelectFieldWithImage: React.FC<InputFieldProps> = ({
                                                             placeholder,
                                                             size: _,
                                                             style,
                                                             value,
                                                             setValue,
                                                             ...props
                                                         }) => {

    const [field, meta] = useField({...props});
    const errorText = meta.error && meta.touched ? meta.error : "";

    return (
        <FormControl isInvalid={!!meta.error} style={style}>
            <FormLabel htmlFor={field.name}>{placeholder}</FormLabel>
            <ReactSelect
                //previous value
                value={props.options?.find(option => option.value === value)}
                options={props.options}
                onChange={option => {
                    setValue(option.value)
                }}
                styles={{
                    control: (provided, state) => ({
                            ...provided,
                            width: "300px",
                            border: "1px solid #e2e8f0",
                            borderRadius: "5px",
                            backgroundColor: "#1A202C",

                            ...(state.isFocused && {
                                color: 'white',
                            }),
                            "&:hover": {
                                border: "1px solid #e2e8f0",
                                color: 'white',
                            },
                            ...(state.isDisabled && {
                                opacity: 0.5,
                            })
                        }
                    ),
                    option: (provided, state) => ({
                        ...provided,
                        backgroundColor: '#1A202C',
                        color: 'white',
                        "&:hover": {
                            backgroundColor: "#243142",
                            color: "white",
                        },
                        ...(state.isSelected && {
                            backgroundColor: "#243142",
                            color: "white",
                        })
                    }),
                    menu: (provided, state) => ({
                        ...provided,
                        zIndex: 2,
                        backgroundColor: 'red',
                    }),
                    menuList: (provided, state) => ({
                        ...provided,
                        zIndex: 2,
                        backgroundColor: '#2d3d50',
                    }),
                    placeholder: (provided, state) => ({
                        ...provided,
                        color: 'white',
                    }),
                    singleValue: (provided, state) => ({
                        ...provided,
                        color: 'white',

                    }),
                    input: (provided, state) => ({
                        ...provided,
                        color: 'white',
                    }),
                    loadingIndicator: (provided, state) => ({
                        ...provided,
                        color: 'white',
                    }),
                }}
                formatOptionLabel={item => (
                    <Flex
                        alignItems="center"
                    >
                        <Image src={item.image_url} h={30} w={30}/>
                        <Text ml={4}>{item.label}</Text>
                    </Flex>
                )}
            />
            {errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}
        </FormControl>
    );
};


export {
    SelectField as default,
    SelectFieldWithImage
}

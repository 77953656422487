import React from "react";
import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton} from "@chakra-ui/react";

interface Props {
    isSuccess: boolean;
    title: string;
    description: string;
}

const AlertComponent: React.FC<Props> = ({isSuccess, title, description}) => {
    return (
        <>
            <Alert status={isSuccess ? 'success' : 'error'}>
                <AlertIcon/>
                <Box flex='1'>
                    <AlertTitle>{title}</AlertTitle>
                    <AlertDescription display='block'>
                        {description}
                    </AlertDescription>
                </Box>
                <CloseButton position='absolute' right='8px' top='8px'/>
            </Alert>
        </>
    )
}

export default AlertComponent;
import React from 'react';
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Radio,
    RadioGroup,
    Stack,
    Textarea
} from "@chakra-ui/react";
import {Form, Formik} from "formik";
import InputField from "../custom/InputField";
import SelectField from "../custom/SelectField";
import * as yup from "yup";
import toast from "react-hot-toast";
import {CardLayout, EntityRanking, ScrollTypes, ViewAllEnableTypes} from "../../config/Options";
import {useNavigate} from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import LockAccess from "../../utils/LockAccess";
import {ADMIN, EDITOR, MODULE} from "../../types/Strings";
import UploadImageComponent from "../common/UploadImage";
import {suggestCardTypeBasedOnImageDimensions} from "../../utils/SuggestCardType";
import {GlobalFormStyles} from "../../styles/FormStyles";

interface ModuleEditFormProps {
    data: any;
    isNew: boolean;
}

const ModuleEditForm: React.FC<ModuleEditFormProps> = ({data, isNew}) => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const validationSchema = yup.object({
        name: yup.string(),
        display_name: yup.string().required("Display title is required"),
        id: yup.number(),
        scroll_type: yup.string().required("Please select a scroll type"),
        card_layout: yup.string().required("Card Layout Is Required"),
        entity_ranking: yup.string().matches(/^random|trending_score|manual$/).required(),
        view_all_enable: yup.boolean(),
        view_all_enable_type: yup.string().notOneOf(["NA"], "Please select a valid view all enable type"),
        view_all_enable_id: yup.string().notOneOf(["NA"], "Please select a valid view all enable id"),
        hide_module_title: yup.boolean(),
        is_active: yup.boolean(),
        hide_entity_title: yup.boolean().required('Hide Entity Title is a required field'),
        limit_entity_view_count: yup.boolean().required('Limit Entity View Count is a required field'),
        sub_title: yup.string(),
        background_image_url: yup.string(),
        entity_view_count: yup.number().when('limit_entity_view_count', {
            is: true,
            then: yup.number().required('Entity View Count is a required field')
        })
    });
    const initialValues = isNew ? {
        name: '',
        display_name: '',
        id: '',
        scroll_type: ScrollTypes[ScrollTypes.length - 1].value,
        card_layout: CardLayout[CardLayout.length - 1].value,
        entity_ranking: EntityRanking[0].value,
        view_all_enable: false,
        view_all_enable_type: '',
        view_all_enable_id: '',
        hide_module_title: false,
        is_active: true,
        description: '',
        hide_entity_title: false,
        entity_list: [],
        limit_entity_view_count: false,
        entity_view_count: 0,
        sub_title: '',
        background_image_url: ''
    } : {
        name: data['_name'],
        description: data.description,
        display_name: data.display_name,
        id: data.id,
        scroll_type: data.scroll_type,
        card_layout: data.card_layout,
        entity_ranking: data.entity_ranking,
        view_all_enable: data.view_all_enable,
        view_all_enable_type: data.view_all_enable_type,
        view_all_enable_id: data.view_all_enable_id,
        hide_module_title: data.hide_module_title,
        hide_entity_title: data.hide_entity_title,
        is_active: data.is_active,
        limit_entity_view_count: data.limit_entity_view_count,
        entity_view_count: data.entity_view_count === '' ? 0 : data.entity_view_count,
        sub_title: data.sub_title,
        background_image_url: data.background_image_url
    };

    // @ts-ignore
    const {auth} = useAuth();
    return (
        <Box
            my={8}
            textAlign='left'
            opacity={LockAccess(auth, [ADMIN, EDITOR]).opacity}
            style={{...LockAccess(auth, [ADMIN, EDITOR]).style as any}}
        >
            <Formik
                enableReinitialize={true}
                validateOnChange={true}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (data, {setSubmitting, setValues}) => {
                    setSubmitting(true);
                    try {
                        if (!isNew) {
                            const response = await axiosPrivate.patch('/module', {
                                name: data.name,
                                description: data.description,
                                display_name: data.display_name,
                                id: data.id,
                                scroll_type: data.scroll_type,
                                card_layout: data.card_layout,
                                entity_ranking: data.entity_ranking,
                                view_all_enable: data.view_all_enable,
                                view_all_type: data.view_all_enable_type,
                                view_all_id: data.view_all_enable_id,
                                hide_module_title: data.hide_module_title,
                                is_active: data.is_active,
                                hide_entity_title: data.hide_entity_title,
                                limit_entity_view_count: data.limit_entity_view_count,
                                entity_view_count: data.entity_view_count,
                                sub_title: data.sub_title,
                                background_image_url: data.background_image_url
                            });
                            if (response.status === 200) {
                                toast.success(response.data.message);
                            }
                        } else {
                            const response = await axiosPrivate.put('/module', {
                                name: data.name,
                                description: data.description,
                                display_name: data.display_name,
                                scroll_type: data.scroll_type,
                                card_layout: data.card_layout,
                                entity_ranking: data.entity_ranking,
                                view_all_enable: data.view_all_enable,
                                view_all_type: data.view_all_enable_type,
                                view_all_id: data.view_all_enable_id,
                                hide_module_title: data.hide_module_title,
                                is_active: data.is_active,
                                hide_entity_title: data.hide_entity_title,
                                limit_entity_view_count: data.limit_entity_view_count,
                                entity_view_count: data.entity_view_count,
                                sub_title: data.sub_title,
                                background_image_url: data.background_image_url,
                                entity_list: []
                            });
                            if (response.status === 200) {
                                toast.success(response.data.message);
                                setValues({...data, id: response.data?.data.id});
                                navigate(`/module-edit/${response.data?.data.id}`);
                            }
                        }
                    } catch (err) {
                        console.error(err)
                        toast.error(err.response.data.message);
                        return;
                    }
                    setSubmitting(false);
                }}
            >
                {({values, isSubmitting, setValues, errors}) => (
                    <Form
                        style={GlobalFormStyles}
                    >
                        <InputField
                            placeholder={"ID"}
                            name={"id"}
                            value={values.id}
                            disabled={true}
                        />
                        <InputField
                            placeholder={"Name"}
                            name={"name"}
                            value={values.name}
                        />

                        <InputField
                            placeholder={"Display Name"}
                            name={"display_name"}
                            value={values.display_name}
                        />

                        <InputField
                            placeholder={"Sub Title"}
                            name={"sub_title"}
                            value={values.sub_title}
                        />

                        <FormControl isInvalid={!!errors.is_active}>
                            <FormLabel>Active Status</FormLabel>
                            <RadioGroup
                                onChange={(e) => {
                                    setValues({...values, is_active: e === "true"})
                                }}
                                value={values.is_active.toString()}
                            >
                                <Stack direction={"row"}>
                                    {
                                        [{value: "true", label: "Active"}, {
                                            value: "false",
                                            label: "Inactive"
                                        }]?.map((option, index) => (
                                            <Radio
                                                key={index}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </Radio>
                                        ))
                                    }
                                </Stack>

                            </RadioGroup>
                            {errors.is_active && <FormErrorMessage>{errors.is_active}</FormErrorMessage>}
                        </FormControl>

                        <SelectField
                            placeholder={"Scroll Type"}
                            name={"scroll_type"}
                            options={ScrollTypes}
                        />

                        <SelectField
                            placeholder={"Card Layout"}
                            name={"card_layout"}
                            options={CardLayout}
                        />

                        <FormControl isInvalid={!!errors.view_all_enable}>
                            <FormLabel>View All Enable</FormLabel>
                            <RadioGroup
                                onChange={(e) => {
                                    setValues({...values, view_all_enable: e === "true"})
                                }}
                                value={values.view_all_enable.toString()}
                            >
                                <Stack direction={"row"}>
                                    {
                                        [{value: "true", label: "Active"}, {
                                            value: "false",
                                            label: "Inactive"
                                        }]?.map((option, index) => (
                                            <Radio
                                                key={index}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </Radio>
                                        ))
                                    }
                                </Stack>

                            </RadioGroup>
                            {errors.view_all_enable && <FormErrorMessage>{errors.view_all_enable}</FormErrorMessage>}
                        </FormControl>

                        {
                            values.view_all_enable &&
                            <>
                                <SelectField
                                    placeholder={"View All Enable Type"}
                                    name={"view_all_enable_type"}
                                    options={ViewAllEnableTypes}
                                />

                                <InputField
                                    placeholder={"View All Enable ID"}
                                    name={"view_all_enable_id"}
                                    value={values.view_all_enable_id}
                                />
                            </>
                        }

                        <FormControl isInvalid={!!errors.hide_module_title}>
                            <FormLabel>Hide Module Title</FormLabel>
                            <RadioGroup
                                onChange={(e) => {
                                    setValues({...values, hide_module_title: e === "true"})
                                }}
                                value={values.hide_module_title.toString()}
                            >
                                <Stack direction={"row"}>
                                    {
                                        [{value: "true", label: "Active"}, {value: "false", label: "Inactive"}]
                                            ?.map((option, index) => (
                                                <Radio
                                                    key={index}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </Radio>
                                            ))
                                    }
                                </Stack>
                            </RadioGroup>

                            {errors.hide_module_title &&
                                <FormErrorMessage>{errors.hide_module_title}</FormErrorMessage>}
                        </FormControl>

                        <FormControl isInvalid={!!errors.hide_entity_title}>
                            <FormLabel>Hide Entity Title</FormLabel>
                            <RadioGroup
                                onChange={(e) => {
                                    setValues({...values, hide_entity_title: e === "true"})
                                }}
                                value={!values.hide_entity_title ? "false" : "true"}
                            >
                                <Stack direction={"row"}>
                                    {
                                        [{value: "true", label: "Active"}, {value: "false", label: "Inactive"}]
                                            ?.map((option, index) => (
                                                <Radio
                                                    key={index}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </Radio>
                                            ))
                                    }
                                </Stack>

                            </RadioGroup>

                            {errors.hide_module_title &&
                                <FormErrorMessage>{errors.hide_module_title}</FormErrorMessage>}
                        </FormControl>

                        <SelectField
                            placeholder={"Entity Ranking"}
                            name={"entity_ranking"}
                            options={EntityRanking}
                        />

                        <FormControl isInvalid={!!errors.limit_entity_view_count}>
                            <FormLabel>Limit Entity View Count</FormLabel>
                            <RadioGroup
                                onChange={(e) => {
                                    setValues({...values, limit_entity_view_count: e === "true"})
                                }}
                                value={values.limit_entity_view_count.toString()}
                            >
                                <Stack direction={"row"}>
                                    {
                                        [{value: "true", label: "Yes"}, {value: "false", label: "No"}]
                                            ?.map((option, index) => (
                                                <Radio
                                                    key={index}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </Radio>
                                            ))
                                    }
                                </Stack>
                            </RadioGroup>

                            {errors.hide_module_title &&
                                <FormErrorMessage>{errors.hide_module_title}</FormErrorMessage>}
                        </FormControl>

                        {values.limit_entity_view_count && <FormControl isInvalid={!!errors.entity_view_count}>
                            <FormLabel>Entity View Count</FormLabel>
                            <NumberInput
                                name={"entity_view_count"}
                                value={values.entity_view_count}
                                onChange={(e) => {
                                    setValues({...values, entity_view_count: parseInt(e)})
                                }}
                                defaultValue={0}
                                min={0}
                            >
                                <NumberInputField/>
                                <NumberInputStepper>
                                    <NumberIncrementStepper/>
                                    <NumberDecrementStepper/>
                                </NumberInputStepper>
                            </NumberInput>
                            {errors.entity_view_count &&
                                <FormErrorMessage>{errors.entity_view_count}</FormErrorMessage>}
                        </FormControl>}


                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={values.description}
                                onChange={(e) => {
                                    setValues({...values, description: e.target.value})
                                }}
                                placeholder='Enter the description here'
                            />
                            {errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                        </FormControl>

                        <InputField
                            placeholder={"Background Image URL"}
                            name={"background_image_url"}
                            value={values.background_image_url}
                        />

                        <UploadImageComponent
                            setImageUrl={(imageUrl) => setValues({...values, background_image_url: imageUrl})}
                            imageURL={values.background_image_url}
                            entityID={values.id}
                            type={MODULE}
                        />

                        <Button
                            type={'submit'}
                            isLoading={isSubmitting}
                            colorScheme={'green'}
                            mt={8}>Save</Button>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}


export default ModuleEditForm;

import {
    Box, Button,
    Flex,
    IconButton,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text,
    Tooltip
} from "@chakra-ui/react";
import {ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import SelectFieldCommon from "../custom/common/SelectCommon";
import {HORIZONTAL} from "../../types/Strings";
import React from "react";
import {EntityTypes} from "../../config/Options";
import PageFilter from "../common/filter";
import {BsSearch} from "react-icons/bs";

interface Props {
    pageNumber: number;
    totalModules: number;
    perPage: number;
    setPerPage?: (perPage: number) => void;
    goToPage: (pageNumber: number) => void;
    previous: () => void;
    next: () => void;
    filter: string;
    setFilter: (filter: any) => any;
    getFilterDetails?: (data: any) => void;
    setOpenSearchModal: (open: boolean) => void;
}

const PaginationBar: React.FC<Props> = ({
                                            pageNumber,
                                            totalModules,
                                            perPage,
                                            setPerPage,
                                            goToPage,
                                            previous,
                                            next,
                                            filter,
                                            setFilter,
                                            getFilterDetails,
                                            setOpenSearchModal
                                        }) => {
    const pageCount = Math.ceil(totalModules / perPage);

    return (
        <>
            <Flex my={4} alignItems="center">
                <Flex>
                    <Tooltip label="First Page">
                        <IconButton
                            onClick={() => goToPage(0)}
                            isDisabled={pageNumber === 0}
                            icon={<ArrowLeftIcon h={3} w={3}/>}
                            mr={4}
                            aria-label={"First Page"}
                        />
                    </Tooltip>
                    <Tooltip label="Previous Page">
                        <IconButton
                            onClick={previous}
                            isDisabled={pageNumber === 0}
                            icon={<ChevronLeftIcon h={6} w={6}/>}
                            aria-label={"Previous Page"}
                        />
                    </Tooltip>

                    <Flex align="center">
                        {/* <Text color={"#e1970b"} flexShrink="0" ml={4} mr={4}> */}
                        <Text color={"#e1970b"} ml={4} mr={4}>
                            Page{" "}
                            <Text fontWeight="bold" as="span">
                                {pageNumber + 1}
                            </Text>{" "}
                            of{" "}
                            <Text fontWeight="bold" as="span">
                                {pageCount}
                            </Text>
                        </Text>
                    </Flex>

                    <Tooltip label="Next Page">
                        <IconButton
                            isDisabled={pageNumber === pageCount - 1}
                            onClick={next}
                            icon={<ChevronRightIcon h={6} w={6}/>}
                            aria-label={"Next Page"}
                        />
                    </Tooltip>

                    <Tooltip label="Last Page">
                        <IconButton
                            isDisabled={pageNumber === pageCount - 1}
                            onClick={() => goToPage(pageCount - 1)}
                            icon={<ArrowRightIcon h={3} w={3}/>}
                            ml={4}
                            aria-label={"Last Page"}
                            mr={8}
                        />
                    </Tooltip>
                </Flex>

                <Flex alignItems="center">

                    {/* <Text flexShrink="0" color={"#e1970b"}>Go to page:</Text>{" "} */}
                    <Text color={"#e1970b"}>Go to page:</Text>{" "}
                    <NumberInput
                        ml={2}
                        mr={8}
                        w={20}
                        min={1}
                        max={pageCount}
                        onChange={(value) => {
                            const page: number = parseInt(value) ? parseInt(value) - 1 : 0;
                            goToPage(page);
                        }}
                        defaultValue={pageNumber + 1}
                    >
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper/>
                            <NumberDecrementStepper/>
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>

                <Flex ml={2} alignItems="center">
                    {setPerPage && <Box>
                        <SelectFieldCommon
                            placeholder={'Per Page'}
                            value={perPage}
                            layout={HORIZONTAL}
                            setValue={(d) => setPerPage(d as number)}
                            options={[
                                {value: 5, label: '5'},
                                {value: 10, label: '10'},
                                {value: 20, label: '20'},
                                {value: 30, label: '30'},
                                {value: 40, label: '40'},
                                {value: 50, label: '50'},
                            ]}/>

                    </Box>}

                    {/*<Box ml={2}>*/}
                    {/*    <PageFilter getFilterDetails={getFilterDetails}/>*/}
                    {/*</Box>*/}

                    {/*<Box ml={4}>*/}
                    {/*    <SelectFieldCommon setValue={(e) => setFilter(e)}*/}
                    {/*                       options={[...EntityTypes, {value: 'all', label: "All"}]}*/}
                    {/*                       value={filter}*/}
                    {/*                       disabled={true}*/}
                    {/*    />*/}
                    {/*</Box>*/}
                    {setOpenSearchModal && <Button
                        onClick={() => setOpenSearchModal(true)}
                        ml={4}
                    >
                        <BsSearch color='gray.300'/>
                        <Text
                            ml={2}
                        >
                            Search
                        </Text>
                    </Button>}
                </Flex>


            </Flex>
        </>
    )
}

export default PaginationBar;

import React from 'react';
import {Box, Button, HStack,} from "@chakra-ui/react";
import SinglePage from "./SinglePage";
import {produce} from "immer";
import {ADMIN, EDITOR, PAGE, POSITION, STATUS} from "../../types/Strings";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {Table, Tbody, Th, Thead, Tr} from "@chakra-ui/table";
import LockAccess from "../../utils/LockAccess";
import useAuth from "../../hooks/useAuth";
import {toast} from "react-hot-toast";

interface Props {
    data: [any];
    isNew: boolean;
    module_id: number;
}

const PagesPresent: React.FC<Props> = ({data, isNew, module_id}) => {
    const [pages, setPages] = React.useState(isNew ? [] : data);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState('');
    const axiosPrivate = useAxiosPrivate();

    React.useEffect(() => {
        if (!!error) {
            toast.error(error);
            setError('');
        }
        if (success) {
            toast.success(success);
            setSuccess('');
        }
    }, [error, success])


    const handleAddPage = () => {
        setPages(produce(pages, draft => {
            draft.push({
                page_id: -1,
                page_type: "",
                page_name: "",
                position: 0,
                status: false,
                isNew: true,
            })
        }))
    }

    const handlePageRemove = (id) => {
        setPages(produce(pages, draft => {
            const index = draft.findIndex(page => page.page_id === id);
            draft.splice(index, 1);
        }))
    }

    const handlePageUpdate = (index, type, value, page) => {
        setPages(produce(pages, draft => {
            //Update draft with new value whose page_id is id
            if (type === PAGE) {
                draft[index].page_id = value;
                draft[index].page_type = page.find(pg => pg.value == value).type;
            } else if (type === STATUS) {
                draft[index].status = value;
            } else if (type === POSITION) {
                draft[index].position = value;
            }
        }))
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const data = {
            module_id: module_id,
            add: pages.map((item, index) => {
                if (item.page_id && item.page_type && item?.isNew) {
                    return {
                        page_id: parseInt(item.page_id),
                        page_type: item.page_type,
                        position: parseInt(item?.position) ?? index,
                        is_active: item.status
                    }
                }
            }).filter(item => {
                if (item !== undefined) {
                    return item
                }
            }),
            update: pages.map((item, index) => {
                if (item.page_id && item.page_type && !item?.isNew) {
                    return {
                        page_id: parseInt(item.page_id),
                        page_type: item.page_type,
                        position: parseInt(item?.position) ?? index,
                        is_active: item.status
                    }
                }
            }).filter(item => {
                if (item !== undefined) {
                    return item
                }
            })
        };

        try {
            await axiosPrivate.patch('/page/mapping', data).then(res => {
                if (res.status === 200) {
                    console.log(res.data);
                    if (res.data?.status === 200) {
                        setSuccess(res.data.message);
                    } else {
                        setError(res.data.message);
                    }
                } else {
                    setError(res.statusText);
                    console.log(res);
                }
            }).catch(e => {
                console.log(e);
                setError(e.response.data.message);
                setIsSubmitting(false);
            })

        } catch (err) {
            console.log(err);
        }

        setIsSubmitting(false);
    }
    //@ts-ignore
    const {auth} = useAuth();
    return (
        <Box
            opacity={LockAccess(auth, [ADMIN, EDITOR]).opacity}
            style={{...LockAccess(auth, [ADMIN, EDITOR]).style as any}}
        >
            <HStack
                justifyContent="space-between"
            >
                <Box p={4}>
                    <Button onClick={() => handleAddPage()} colorScheme={'cyan'}>Add Page</Button>
                </Box>
                <Box p={4}>
                    <Button
                        isLoading={isSubmitting}
                        onClick={handleSubmit}
                        colorScheme={'green'}
                    >Save</Button>
                </Box>
            </HStack>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Select Page</Th>
                        <Th>Page ID</Th>
                        <Th>Page Type</Th>
                        <Th>Position</Th>
                        <Th>Status</Th>
                        <Th>Created By</Th>
                        <Th>Remove</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {pages && pages.map((item, index) => {
                        return (
                            <SinglePage
                                key={index}
                                type={item.page_type}
                                page_id={item.page_id}
                                created_by_name={item.created_by_name}
                                position={item.position}
                                status={item.status}
                                handlePageRemove={handlePageRemove}
                                handleChange={handlePageUpdate}
                                index={index}
                                page_arr={pages}
                                setToastError={setError}
                                last_modified={item?.last_modified}
                            />
                        )
                    })}
                </Tbody>
            </Table>

        </Box>
    );
};


export default PagesPresent;

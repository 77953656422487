import React from 'react';
import {VStack} from "@chakra-ui/react";
import EntitiesSearch from "../../components/entities/EntitiesSearch";
import {SearchStore} from "../../store/mobx/Search";

const EntitiesPage = () => {
    return (
        <>
            <VStack
                alignItems="left"
            >
                <EntitiesSearch
                    searchProps={SearchStore}
                />
            </VStack>

        </>
    )
}

export default EntitiesPage;

import React from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useQuery} from "react-query";
import {Box, CircularProgress, HStack, Text, VStack} from "@chakra-ui/react";

const DeeplinkDetails: React.FC<{ link: any }> = ({link}) => {
    const axiosPrivate = useAxiosPrivate()
    //TODO:Testing a particular deeplink

    // link = "https://paavanapp.page.link/paavandownload";

    const {data, isLoading, error} = useQuery(['deeplink', link], async () => {
        let response = (await axiosPrivate
            .post('/analytics/deeplink/analytics', {
                link: link,
                days: 30
            }))
            .data;

        if (response && response.data) {
            response.data = response.data.filter(item => (item.platform === "ANDROID" || item.platform === "OTHER"));
        }

        return response.data;
    })

    return (
        <>
            {isLoading &&
                <Box
                    textAlign={"center"}
                    fontSize={20}
                >
                    <CircularProgress isIndeterminate={true}/>
                </Box>
            }
            {error && <Text>{JSON.stringify(error)}</Text>}
            {data &&

                <VStack
                    spacing={4}
                    alignContent={"left"}
                    alignItems={"left"}
                >
                    {data && data.map(item => {
                        return (
                            <HStack
                                spacing={4}
                                style={{
                                    padding: "10px",
                                    borderRadius: "5px",
                                    //shadow
                                    boxShadow: "0px 0px 2px #ccc",
                                }}
                            >
                                <Text style={{
                                    fontWeight: "bold",
                                    fontSize: "16px",
                                    color: 'green'
                                }}>
                                    {item.event.split("_").join(" ")}
                                </Text>
                                <Text
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold"
                                    }}
                                >Count : {item.count}
                                </Text>
                            </HStack>
                        )
                    })}

                </VStack>
            }
            {data && data.length === 0 && !isLoading && <Text>No data available for this link</Text>}
        </>
    )
}

export default DeeplinkDetails;
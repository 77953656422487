import React from "react";
import {Box, Button, HStack, Progress, Text} from "@chakra-ui/react";
import TagTable from "./TagTable";
import {produce} from "immer";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import AlertComponent from "../../common/Alert";
import LockAccess from "../../../utils/LockAccess";
import {ADMIN, EDITOR} from "../../../types/Strings";
import useAuth from "../../../hooks/useAuth";

interface Props {
    id: string;
    type:string;
}

const EntityTagManager: React.FC<Props> = ({id, type}) => {
    const axiosPrivate = useAxiosPrivate();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [error, setError] = React.useState<string | null>("");
    const [success, setSuccess] = React.useState<string | null>("");
    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        const fetchTags = async () => {
            const response = await axiosPrivate.get('/tags/entity?id=' + id);
            if (response.status === 200) {
                if (response.data.status) {
                    const tags_data = response.data.data.map(tag => {
                        return {
                            l1: tag['l1'] ? parseInt(tag['l1']) : null,
                            l2: tag['l2'] ? parseInt(tag['l2']) : null,
                            l3: tag['l3'] ? parseInt(tag['l3']) : null,
                            l4: tag['l4'] ? parseInt(tag['l4']) : null,
                            priority: parseInt(tag.priority),
                            isNew: false
                        }
                    });
                    setData(tags_data);
                }
            } else {
                setError(response.statusText);
            }
        };
        fetchTags();
    }, [])

    const handleAddTag = () => {
        setData(
            produce(data, draft => {
                draft.push({
                    l1: null,
                    l2: null,
                    l3: null,
                    l4: null,
                    priority: null,
                    isNew: true,
                });
            })
        )
    }

    const removeTag = (index) => {
        setData(
            produce(data, draft => {
                draft.splice(index, 1);
            })
        )
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);

        const isNew = data.every(tag => tag?.isNew);

        const data_to_submit = data.map((tag, index) => {
            return {
                l1: tag.l1 ? parseInt(tag.l1) : null,
                l2: tag.l2 ? parseInt(tag.l2) : null,
                l3: tag.l3 ? parseInt(tag.l3) : null,
                l4: tag.l4 ? parseInt(tag.l4) : null,
                priority: index + 1,
            }
        });

        try {
            let response;
            if (isNew) {
                response = await axiosPrivate.put('/tags/entity', {
                    entity_id: id,
                    tags: data_to_submit,
                    type: type
                });
            } else {
                response = await axiosPrivate.patch('/tags/entity', {
                    entity_id: id,
                    tags: data_to_submit,
                    type: type
                });
            }

            if (response.status === 200) {
                if (response.data.status) {
                    setIsSubmitting(false);
                    setError(null);
                    setSuccess(response.data.message);
                } else {
                    setIsSubmitting(false);
                    setError(response.data.message);
                }
            } else {
                setIsSubmitting(false);
                setError(response.statusText);
            }
        } catch (err) {
            console.log(err);
            setError(err.message);
        }

        setIsSubmitting(false);
    }
    //@ts-ignore
    const {auth} = useAuth();
    return (
        <>

            <HStack
                justifyContent="space-between"
                opacity={LockAccess(auth, [ADMIN, EDITOR]).opacity}
                style={{...LockAccess(auth, [ADMIN, EDITOR]).style as any}}
            >
                <Box p={4}>
                    <Button
                        isDisabled={!!error}
                        onClick={() => handleAddTag()}
                        colorScheme={'cyan'}
                    >
                        Add Tag
                    </Button>
                </Box>
                <Box>
                    <Text color={'red'}>{error}</Text>
                    <Text color={'green'}>{success}</Text>
                </Box>
                <Box p={4}>
                    <Button
                        isLoading={isSubmitting}
                        onClick={handleSubmit}
                        disabled={!!error}
                        colorScheme={'green'}
                    >
                        Save
                    </Button>
                </Box>
            </HStack>
            {data && data.length > 0 && <TagTable
                id={id}
                data={data}
                setData={setData}
                removeTag={removeTag}
            />}
            {data && data.length === 0 && <AlertComponent isSuccess={false}
                                                          title={`No tags found`}
                                                          description={`No tags found`}/>}
            {!data && <Progress isIndeterminate={true}/>}
        </>
    )
}
export default EntityTagManager;
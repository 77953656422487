import React from "react";
import {useQuery} from "react-query";
import {getAllPreviousRunQueries} from "../../../services/query_manager";
import {Box, Button, CircularProgress, Text} from "@chakra-ui/react";
import {PreviousRunQuery} from "../../../types/query_manager";
import copy from "copy-to-clipboard";
import {toast} from "react-hot-toast";

const PreviouslyRunQueries: React.FC = () => {
    const {data, error, isLoading} = useQuery("previouslyRunQueries", getAllPreviousRunQueries);
    return (
        <>
            <Box
                display={"flex"}
                flexDirection={"column"}
                w={"100%"}
                minH={"40vh"}
                p={2}
                style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                }}
            >

                {isLoading && <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w={"100%"}
                    h={"100%"}
                >
                    <CircularProgress isIndeterminate color="green.300"/>
                </Box>}

                {data && <Box>
                    {data.map((query: PreviousRunQuery) => {
                        return <SinglePreviousRunQuery query={query}/>
                    })}
                </Box>
                }
            </Box>
        </>
    )
}

const SinglePreviousRunQuery: React.FC<{ query: PreviousRunQuery }> = ({query}) => {
    return (
        <Box
            border="1px solid"
            borderColor={"#2d3649"}
            borderRadius="lg"
            backgroundColor={"#2d3649"}
            p={4}
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gridGap={8}
            w={"100%"}
        >
            <Text fontWeight="bold">{query.query_id}</Text>
            <Text>{query.query_title}</Text>
            <Text>{query.created_by}</Text>
            <Box>
                <Button
                    mr={2}
                    onClick={() => {
                        window.open(query.sheet_link, "_blank")
                    }}
                >Open Sheet</Button>
                <Button
                    onClick={() => {
                        copy(query.sheet_link);
                        toast.success("Copied to clipboard");
                    }}
                >Copy Link</Button>
            </Box>
        </Box>
    )
}

export default PreviouslyRunQueries
import {FaVideo} from 'react-icons/fa';
import {MdPlaylistPlay} from 'react-icons/md';
import {GrChannel} from 'react-icons/gr';
import {BsLink} from 'react-icons/bs';
import {IoIosQuote} from 'react-icons/io';
import {BiBookBookmark} from 'react-icons/bi';
import {BsFillPersonFill} from 'react-icons/bs';
import {VscFileSubmodule} from 'react-icons/vsc';
import React from "react";
import {
    CHANNEL,
    COURSE,
    CREATOR,
    LINK, MODULE,
    PLAYLIST, POST,
    QUOTE,
    STATIC,
    SUBCHANNEL,
    VIDEO
} from "../../types/Strings";
import {Text, HStack} from "@chakra-ui/react";
import {AiOutlineHome} from "react-icons/ai";

interface EntityType {
    type: string;
}

const SingleEntity = ({children, text}) => {
    return (
        <>
            <HStack spacing={2}>
                {children}
                <Text>{text}</Text>
            </HStack>
        </>
    )
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const EntityType: React.FC<EntityType> = ({type}) => {
    switch (type) {
        case VIDEO:
            return <SingleEntity text={"Video"} children={<FaVideo/>}/>;

        case PLAYLIST:
            return <SingleEntity text={"Playlist"} children={<MdPlaylistPlay/>}/>;

        case CHANNEL:
            return <SingleEntity text={"Channel"} children={<GrChannel
                style={{
                    fontSize: '1.5rem',
                    backgroundColor: 'white',
                }}
            />}/>;

        case LINK:
            return <SingleEntity text={"Link"} children={<BsLink/>}/>;

        case COURSE:
            return <SingleEntity text={"Course"} children={<BiBookBookmark/>}/>;

        case CREATOR:
            return <SingleEntity text={"Creator"} children={<BsFillPersonFill/>}/>;

        case STATIC:
            return <SingleEntity text={"Static"} children={<AiOutlineHome/>}/>;

        case SUBCHANNEL:
            return <SingleEntity text={"Sub Channel"} children={<GrChannel
                style={{
                    fontSize: '1.5rem',
                    backgroundColor: 'white',
                }}
            />}/>;

        case MODULE:
            return <SingleEntity text={"Module"} children={<VscFileSubmodule
                style={{
                    fontSize: '1.5rem',
                }}
            />}/>;

        default:
            return <Text>{type}</Text>;
    }
}

export default EntityType;
import React from "react";
import {Box, Text} from "@chakra-ui/react";

export const SingleEvent: React.FC<{
    event: any,
    timestamp: number,
    index: number,
    setCurrentEvent: (idx: number) => void,
    currentEventIdx: number
}> = ({event, timestamp, index, setCurrentEvent, currentEventIdx}) => {
    const eventName = event.name;
    return (
        <>
            <Box
                border="1px solid"
                borderColor={"#2d3649"}
                borderRadius="lg"
                backgroundColor={currentEventIdx === index ? "#2d3649" : ""}
                p={4}
                mb={2}
                display="flex"
                justifyContent="space-between"
                gridGap={8}
                onClick={() => {
                    setCurrentEvent(index);
                }}
                style={{
                    cursor: "pointer"
                }}
            >
                <Text fontWeight="bold"

                >{eventName}</Text>
                <Text fontWeight="bold">
                    {new Date(timestamp).toLocaleTimeString()}
                </Text>
            </Box>


        </>
    )
}

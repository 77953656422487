import React from "react";
import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import PlaylistMetaEdit from "./playlist-meta-edit";
import PlaylistVideoOrder from "./playlist-video-order";
import PlaylistTagging from "./playlist-tagging";

interface Props {
    playlist: any;
    isNew: boolean;
}

const PlaylistEditComponent: React.FC<Props> = ({playlist, isNew}) => {
    const playlist_duration = !isNew ? playlist.videos_list.reduce((acc, video) => acc + parseInt(video.duration), 0) : 0;

    return (
        <>
            <Tabs isFitted variant='enclosed'>
                <TabList mb='1em'>
                    <Tab>Playlist Edit</Tab>
                    <Tab>Videos Order</Tab>
                    <Tab>Tagging</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <PlaylistMetaEdit data={{...playlist, playlist_duration}} isNew={isNew}/>
                    </TabPanel>
                    <TabPanel>
                        <PlaylistVideoOrder videos={playlist?.videos_list} isNew={isNew} id={playlist.id}/>
                    </TabPanel>
                    <TabPanel>
                        <PlaylistTagging id={playlist.id}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default PlaylistEditComponent;
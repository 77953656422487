import {useParams} from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {useQuery} from "react-query";
import toast from "react-hot-toast";
import Loader from "../../components/common/Loader";
import VideoEditComponent from "../../components/entities/video";

const VideoEdit = () => {
    const {id} = useParams();

    const axiosPrivate = useAxiosPrivate();

    const {data, isFetching, isLoading} = useQuery(`video-${id}`, async function () {
            try {
                return ((await axiosPrivate.get(`/video?id=${id}`)).data);
            } catch (err) {
                console.log(err.response);
                throw err
            }
        }
    );

    if (data && data?.status !== 200 && !isFetching) {
        toast.error("Error fetching video");
    }
    return (
        <>
            {(isFetching || isLoading) && <Loader/>}
            {data && <VideoEditComponent data={data.data} id={id}/>}
        </>
    );
};

export default VideoEdit;

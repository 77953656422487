import React, {useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    HStack,
    Image,
    Input,
    Text,
    VStack
} from "@chakra-ui/react";
import reactImageSize from 'react-image-size';
import S3 from 'react-aws-s3';
import {s3Config} from "../../config/aws";
import {MODULE} from "../../types/Strings";
//@ts-ignore
window.Buffer = window.Buffer || require("buffer").Buffer;


interface UploadImageProps {
    setImageUrl: (url: string) => void;
    style?: React.CSSProperties;
    imageURL?: string;
    getHeightAndWidth?: (obj: any) => void;
    entityID?: number | string;

    type?: string;
}

//Upload image to firebase storage
const UploadImageComponent: React.FC<UploadImageProps> = ({
                                                              setImageUrl,
                                                              style,
                                                              imageURL,
                                                              entityID,
                                                              type
                                                          }) => {
    const [imageAsFile, setImageAsFile] = useState('')
    const [fileName, setFileName] = useState("")
    const [isUploading, setIsUploading] = useState(false)
    const [error, setError] = useState(null)
    const [uploaded, setUploaded] = useState(false)
    const [imageHash, setImageHash] = useState<number>(Date.now());

    const isEntityID = !!entityID;

    const handleImageAsFile = async (e) => {
        const image = e.target.files[0]
        setImageAsFile(() => (image))
        setFileName(image.name)
    }

    const fetchImageAgain = () => {
        setImageHash(Date.now())
    }

    const getHeightAndWidthFromDataUrl = async (url) => {
        try {
            const {width, height} = await reactImageSize(url);
            return {
                width, height
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleS3Upload = e => {
        e.preventDefault()
        setIsUploading(true);

        if (imageAsFile === '') {
            setError(`not an image, the image file is a ${typeof (imageAsFile)}`)
        } else {
            setError(null)
        }

        const extension = fileName.split('.').pop()
        const fileName_ = entityID + "." + extension;

        const config_ = {
            ...s3Config,
            dirName: type === MODULE ? 'module-bg' : 'entities'
        }


        const ReactS3Client = new S3(config_);

        ReactS3Client
            .uploadFile(imageAsFile, fileName_)
            .then(data => {
                if (data.status === 204) {
                    const uploadedURL = data.location;
                    setIsUploading(false)
                    setUploaded(true)
                    setImageUrl(uploadedURL)
                } else {
                    setIsUploading(false)
                    setUploaded(false)
                }
                fetchImageAgain()
            })
            .catch(err => {
                console.log(err)
                setIsUploading(false)
                setUploaded(false)
                setError(err)
                fetchImageAgain()
            })

        setIsUploading(false)
    }

    return (
        <VStack style={{...style}}>
            <Box>
                <FormControl isInvalid={!!error}>
                    <FormLabel>Upload Image</FormLabel>
                    <HStack>
                        <Input
                            type="file"
                            name="file"
                            id="file"
                            onChange={handleImageAsFile}
                            isDisabled={!isEntityID}
                            accept={"image/*"}
                        />
                        <Button
                            isLoading={isUploading}
                            onClick={handleS3Upload}
                            isDisabled={uploaded || !entityID}
                            colorScheme={uploaded ? "green" : "blue"}
                        >
                            Upload
                        </Button>
                    </HStack>
                    {uploaded && <FormHelperText color={"green"}>Uploaded</FormHelperText>}
                    {error && <FormErrorMessage>{JSON.stringify(error)}</FormErrorMessage>}
                    {!isEntityID && <Text color={'red'} pt={2}>Please Save the entity first to upload the image</Text>}
                </FormControl>
            </Box>

            {imageURL &&
                <Image
                    style={{height: 200, border: "2px solid grey", borderRadius: 5, alignSelf: 'start'}}
                    objectFit='cover'
                    src={imageURL + "?" + imageHash}
                    alt={fileName}
                />
            }
        </VStack>
    )
}

export default UploadImageComponent;
import {SMALL_SQUARE} from "../types/Strings";

export const suggestCardTypeBasedOnImageDimensions = ({
                                                          height,
                                                          width
                                                      }) => {
    try {
        const heightL = height - height*0.1;
        const heightR = height + height*0.1;
        const widthR = width + width*0.1;
        const widthL = width - width*0.1;


        if(height == width){
            return SMALL_SQUARE
        }

    } catch (err) {
        console.log(err)
    }
}
import React from "react";
import {Box} from "@chakra-ui/react";
import AppBar from "./AppBar";
import {observer} from "mobx-react";
import {CurrentPage} from "../../store/mobx/CreateButton";

export type WrapperVariant = "small" | "regular";

interface WrapperProps {
    variant?: WrapperVariant;
}

export const Wrapper: React.FC<WrapperProps> = observer(({
                                                             children,
                                                             variant = "regular",

                                                         }) => {

    return (
            <Box
                mt={8}
                w="100%"
            >
                <AppBar currentPage={CurrentPage}/>
                {children}
            </Box>
        );
    }
)

import React from "react";
import {Table, Tbody, Th, Thead, Tr} from "@chakra-ui/table";
import TagContainer from "./TagContainer";
import update from "immutability-helper";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import AlertComponent from "../../common/Alert";

interface Props {
    id: string;
    data?: any;
    setData: (data: any) => void;
    removeTag: (index: number) => void;
}

const TagTable: React.FC<Props> = ({data, setData, removeTag}) => {
    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = data[dragIndex]
        setData(
            update(data, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            })
        )
    }


    return (
        <>

            {data && data.length > 0 && <DndProvider backend={HTML5Backend}>
                <Table>
                    <Thead>
                        <Tr>
                            <Th></Th>
                            <Th>Priority</Th>
                            <Th>L1</Th>
                            <Th>L2</Th>
                            <Th>L3</Th>
                            <Th>L4</Th>
                            <Th>Remove</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            data && data.map((item: any, index: number) =>
                                <TagContainer
                                    key={index}
                                    data={item}
                                    moveRow={moveRow}
                                    index={index}
                                    removeRow={removeTag}
                                    setData={setData}
                                />
                            )
                        }
                    </Tbody>
                </Table>
            </DndProvider>}
        </>
    )
}

export default TagTable;
import * as React from "react"
import {ChakraProvider} from "@chakra-ui/react"
import {QueryCache, QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools"
import {Route, Routes} from 'react-router-dom';
import Login from "./pages/auth/Login";
import theme from "./styles/themes";
import {toast, Toaster} from "react-hot-toast";
import PageNotFound from "./pages/404";
import Layout from "./components/common/Layout";
import Unauthorized from "./components/common/Unauthorized";
import RequireAuth from "./components/common/RequireAuth";
import ResetPassword from "./pages/auth/ResetPassword";
import PersistLogin from "./components/auth/PersistLogin";
import ForgotPassword from "./pages/auth/ForgotPassword";
import {MainRoutes} from "./config/RoutesMaster";
import {store} from "./store/redux/store";
import {Provider} from "react-redux";
import './styles/override.css'

export const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: () =>
            toast.error(`Something went wrong`),
    }),
    defaultOptions: {
        queries: {
            staleTime: 0,
            refetchOnWindowFocus: false,
            cacheTime: 0,
        }
    }
})

export const App = () => {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <ChakraProvider theme={theme}>
                    <Toaster/>
                    <Routes>
                        <Route path={'/'} element={<Layout/>}>
                            <Route path={'/login'} element={<Login/>}/>
                            <Route path={'/forgot-password'} element={<ForgotPassword/>}/>
                            <Route path={"/unauthorized"} element={<Unauthorized/>}/>
                            <Route path={"/reset-password/:id"} element={<ResetPassword/>}/>
                            <Route element={<PersistLogin/>}>
                                <Route element={<RequireAuth allowedRoles={['viewer']}/>}>
                                    {MainRoutes && MainRoutes.map((route, index) => {
                                        return <Route path={`/${route.path}`} element={<route.component/>}/>
                                    })}
                                </Route>
                            </Route>
                            <Route path="*" element={<PageNotFound/>}/>
                        </Route>
                    </Routes>
                    <ReactQueryDevtools initialIsOpen={false} position={"bottom-left"}/>
                </ChakraProvider>
            </QueryClientProvider>
        </Provider>
    )
}

import {SavedQuery} from "../../types/query_manager";
import axios from "axios";


const QUERY_MANAGER_API = process.env.REACT_APP_DASHBOARD_ANALYTICS_QUERY_URL;
export const getAllSavedQueries = async () => {
    try {
        const data = await axios.get(`${QUERY_MANAGER_API}/query-manager`)
        return data.data;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const getSavedQueryByID = async (queryId: number) => {
    try {
        return await axios.get(`${QUERY_MANAGER_API}/query-manager?id=${queryId}`)
            .then((res) => res.data);
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const createSavedQuery = async (query: SavedQuery) => {
    try {
        return await axios.put(`${QUERY_MANAGER_API}/query-manager`, query).then((res) => res.data);
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const updateSavedQuery = async (query: SavedQuery) => {
    try {
        return await axios.patch(`${QUERY_MANAGER_API}/query-manager`, query).then((res) => res.data);
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const deleteSavedQuery = async (queryId) => {
    try {
        return await axios.delete(`${QUERY_MANAGER_API}/query-manager?id=${queryId}`)
            .then((res) => res.status);
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const runSavedQuery = async (query: SavedQuery) => {
    try {
        return await axios.post(`${QUERY_MANAGER_API}/query-manager/run-query`, query)
            .then(
                (response) => {
                    return response.data;
                }
            )
            .catch((error) => {
                console.log(error);
                return null;
            });
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const getAllPreviousRunQueries = async () => {
    try {
        return await axios.get(`${QUERY_MANAGER_API}/query-manager/previously-run-queries`)
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                console.error(err);
                return null;
            })
    } catch (err) {
        console.error(err);
        return null;
    }
}

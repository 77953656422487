import React from 'react';
import DraggableTable from './content-order/draggable-table';
import {Box, Button, HStack, VStack} from "@chakra-ui/react";
import ModuleContentSearch from "./content-order/module-content-search";
import {produce} from "immer";
import AlertComponent from "../common/Alert";
import {ADMIN, EDITOR, MODULE_PAGE, PLAYLIST_PAGE} from "../../types/Strings";
import toast, {Toaster} from "react-hot-toast";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {BsUpload} from "react-icons/bs";
import Loader from "../common/Loader";
import useAuth from "../../hooks/useAuth";
import LockAccess from "../../utils/LockAccess";
import RequestAccess from "../access/RequestAccess";
import GetAccessLevel from "../../utils/GetAccessLevel";

interface ModuleContentComponentProps {
    data: any[];
    source?: string;
    isNew?: boolean;
    module_id?: number;
    playlist_id?: number;
}

const ModuleContentComponent: React.FC<ModuleContentComponentProps> = ({
                                                                           data,
                                                                           playlist_id,
                                                                           source,
                                                                           isNew,
                                                                           module_id
                                                                       }) => {
    const [content, setContent] = React.useState(isNew ? [] : data);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const axiosPrivate = useAxiosPrivate();

    const handleSelect = (items) => {
        setContent(produce(content, draft => {
            items.forEach(item => {
                if (draft.findIndex(i => i.id === item.id) === -1) {
                    draft.push({...item, isNew: true, mapping_active: true});
                }
            });
        }))
    }

    const handleRemove = (id) => {
        setContent(produce(content, draft => {
            draft.splice(draft.findIndex(i => i.id === id), 1);
        }))
    }

    const handleCheck = (is_active, id,) => {
        setContent(produce(content, draft => {
            draft[draft.findIndex(i => i.id === id)].mapping_active = is_active;
        }))
    }

    const handleLock = (is_locked, id,) => {
        setContent(produce(content, draft => {
            draft[draft.findIndex(i => i.id === id)].is_locked = is_locked;
        }))
    }

    const handleSave = async () => {
        setIsSubmitting(true);
        const new_content = content.map((item, index) => {
            if (item.isNew) {
                return {
                    id: item.id,
                    entity_type: item.entity_type,
                    position: index,
                    is_active: item?.mapping_active,
                    is_locked: (item?.is_locked === "" || !item?.is_locked) ? false : item?.is_locked
                }
            }
        }).filter(item => item !== undefined);

        let old_content = content.map((item, index) => {
            if (item?.isNew !== true) {
                return {
                    id: item.id,
                    entity_type: item.entity_type,
                    position: index,
                    is_active: item?.mapping_active,
                    is_locked: (item?.is_locked === "" || !item?.is_locked) ? false : item?.is_locked
                }
            }
        }).filter(item => item !== undefined);

        try {
            let res;

            if (source === MODULE_PAGE) {
                res = await axiosPrivate.patch('/module', {
                    id: module_id,
                    entity_list: {
                        add: new_content,
                        update: old_content,
                    }
                });
            } else if (source === PLAYLIST_PAGE) {
                const playlist_update_data = {
                    id: playlist_id,
                    videos: {
                        add: new_content.map(item => {
                            return {
                                id: item.id,
                                position: item.position,
                                is_active: item.is_active,
                            }
                        }),
                        update: old_content.map(item => {
                            return {
                                id: item.id,
                                position: item.position,
                                is_active: item.is_active,
                            }
                        })
                    }
                };

                res = await axiosPrivate.patch('/playlist', playlist_update_data);
            }

            if (res && res.status === 200) {

                if (res.data?.status === 200) {
                    toast.success(res.data?.message);
                } else {
                    toast.error(res.data?.message);
                }
            } else {
                toast.error("Something went wrong, please try again");
            }
        } catch (err) {
            console.log(err);
            toast.error(err.response?.data?.message);
        }
        setIsSubmitting(false);
    }

    const {auth} = useAuth();

    return (
        <>
            {!GetAccessLevel(auth, [ADMIN, EDITOR]) ? < RequestAccess/>:<Box/>}
            <Box
                opacity={LockAccess(auth, [ADMIN, EDITOR]).opacity}
                style={{...LockAccess(auth, [ADMIN, EDITOR]).style as any}}
            >
                {(!data && !isNew) && <Loader/>}
                <HStack
                    justifyContent="end"
                >
                    <Button onClick={handleSave} colorScheme={'green'} isLoading={isSubmitting}> <BsUpload
                        style={{marginRight: 10}}/> Save
                    </Button>
                </HStack>
                <VStack
                    alignItems="left"
                >
                    <Box>
                        <ModuleContentSearch handleSelect={handleSelect} source={source}/>
                    </Box>
                    <Box>
                        {content && content.length > 0 && <DraggableTable
                            data={content}
                            handleRemove={handleRemove}
                            setData={setContent}
                            handleCheck={handleCheck}
                            handleLock={handleLock}
                            source={source}
                
                        />}
                        {content && content.length === 0 &&
                            <AlertComponent isSuccess={false}
                                            title={`No ${source === PLAYLIST_PAGE ? "Videos" : "Content"}`}
                                            description={`No ${source === PLAYLIST_PAGE ? "Videos" : "Content"} found`}/>}
                    </Box>
                </VStack>

            </Box>
        </>
    )
}

export default ModuleContentComponent;

import React from "react";
import VideoUpload from "../../components/video-upload/video-upload";
import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import VideoUploadHistory from "../../components/video-upload/VideoUploadHistory";

const VideoUploadManager: React.FC = () => {
    return (
        <Tabs isFitted variant='enclosed'>
            <TabList mb='1em' style={{color: "#838a8a"}}>
                <Tab>Upload Videos</Tab>
                <Tab>Upload History</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <VideoUpload/>
                </TabPanel>
                <TabPanel>
                    <VideoUploadHistory/>
                </TabPanel>

            </TabPanels>
        </Tabs>
    );
};

export default VideoUploadManager;

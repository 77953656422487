import React from "react";
import DatabaseConfigManager from "../../components/misc/database config";

const DatabaseConfig = () => {
    return (
        <>
            <DatabaseConfigManager/>
        </>
    )
}

export default DatabaseConfig;
import React from "react";
import TagManagerMain from "../components/tags/tag-manager/TagManager";

const TagManager: React.FC = () => {
    return (
        <>
            <TagManagerMain/>
        </>
    )
};


export default TagManager;
import {
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Modal,
    ModalContent,
    Select
} from "@chakra-ui/react";
import {BsSearch} from "react-icons/bs";
import React from "react";

interface Props {
    open: boolean;
    search: string;
    setSearch: (search: string) => void;
    searchParam: string;
    setSearchParam: (searchParam: string) => void;
    setOpen: (open: boolean) => void;
}

const SearchModal: React.FC<Props> = ({open, search, setSearch, searchParam, setSearchParam, setOpen}) => {
    return (
        <>
            <Modal isOpen={open} onClose={() => setOpen(false)} size={"xl"}>
                {/*<ModalOverlay/>*/}
                <ModalContent>
                    <InputGroup
                        size={"lg"}
                    >
                        <InputLeftElement
                            pointerEvents='none'
                            children={<BsSearch color='gray.300'/>}
                        />
                        <Input
                            placeholder={"Search title here"}
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                        />
                        <InputRightElement
                            w={'auto'}
                            children={
                                <Select
                                    placeholder={"Select"}
                                    value={searchParam}
                                    onChange={e => setSearchParam(e.target.value)}
                                >
                                    <option value={"id"}>ID</option>
                                    <option value={"title"}>Title</option>
                                </Select>
                            }
                        />
                    </InputGroup>
                </ModalContent>
            </Modal>
        </>
    )
}

export default SearchModal

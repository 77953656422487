import {Tab, TabList, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import React from "react";
import VideoEditForm from "./VideoEdit";
import EntityTagManager from "../../tags/entity-taging/EntityTagManager";
import {VIDEO} from "../../../types/Strings";

interface Props {
    data: any;
    id: string;
}

const VideoEditComponent: React.FC<Props> = ({data, id}) => {
    return (
        <Tabs isFitted variant='enclosed'>
            <TabList mb='1em' style={{color: "#838a8a"}}>
                <Tab>Video Edit</Tab>
                <Tab>Tagging</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <VideoEditForm data={data}/>
                </TabPanel>
                <TabPanel>
                    <EntityTagManager id={id} type={VIDEO}/>
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

export default VideoEditComponent;
import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from "react-query";
import toast, {Toaster} from "react-hot-toast";
import ModuleEditComponent from "../../components/module/module-edit";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loader from "../../components/common/Loader";

const ModuleEdit: React.FC = () => {
    const {moduleID} = useParams();
    const isNewModule = moduleID == '-1';
    const axiosPrivate = useAxiosPrivate();
    const {data, isFetching} = useQuery(`module-${moduleID}`, async function () {
            try {
                if (moduleID != '-1') {
                    return ((await axiosPrivate.get(`/module?id=${moduleID}`)).data);
                }
            } catch (err) {
                console.log(err.response);
                toast.error(err.response.data.message);
            }
        }
    );

    if (data && data?.status !== 200 && !isFetching) {
        toast.error("Error fetch module data");
    }


    return (
        <>

            {isFetching && <Loader/>}
            {((!isFetching && data) || isNewModule) && <ModuleEditComponent data={data?.data} isNew={isNewModule}/>}
            <Toaster/>
        </>
    )
};

export default ModuleEdit;

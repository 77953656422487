import React from "react";
import {Box, Text} from "@chakra-ui/react";

const JSONToMapTable: React.FC<{
    data: any,
}> = ({data}) => {
    if (data) {
        const columns = Object.keys(data);
        const rows = Object.values(data);
        return (
            <>
                <Box
                    border="1px solid"
                    borderColor="#2d3649"
                    borderRadius="lg"
                    p={4}
                    display={"grid"}
                    gridTemplateColumns={"1fr 1fr"}
                    gridGap={8}
                >
                    <Box>
                        {columns.map((column, index) => {
                            return <Text
                                key={index}
                                color={"#829bd2"}
                                mb={0.5}
                            >{column}</Text>
                        })}
                    </Box>
                    <Box>
                        {rows.map((row, index) => {
                            return <Text
                                key={index}
                                mb={0.5}
                            >{row}</Text>
                        })}
                    </Box>
                </Box>
            </>
        )
    }

    return <Text>
        No data
    </Text>

}

export default JSONToMapTable;
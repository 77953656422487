import {action, makeObservable, observable} from "mobx";

export class SearchStoreImpl {
    isSearching: boolean = false;
    searchText: string = "";
    searchType: string = "all";
    searchParam: string = "id";

    constructor() {
        makeObservable(this, {
            isSearching: observable,
            searchText: observable,
            searchType: observable,
            searchParam: observable,
            changeSearching: action,
            changeSearchText: action,
            changeSearchType: action,
            changeSearchParam: action
        })
    }

    changeSearchText(searchText: string,) {
        this.searchText = searchText;
    }

    changeSearchType(searchType: string) {
        this.searchType = searchType;
    }

    changeSearchParam(searchParam: string) {
        this.searchParam = searchParam;
    }

    changeSearching(isSearching: boolean) {
        this.isSearching = isSearching;
    }
}

export const SearchStore = new SearchStoreImpl();


import React, {InputHTMLAttributes} from 'react';
import {CircularProgress, FormControl, FormLabel, HStack, Select, VStack} from "@chakra-ui/react";
import {HORIZONTAL, VERTICAL} from "../../../types/Strings";

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
    placeholder?: string;
    value: any;
    setValue: (value: any) => void;
    options: Array<{
        label: string;
        value: any;
    }>;
    layout?: string;

};

const SelectFieldCommon: React.FC<InputFieldProps> = ({
                                                          placeholder,
                                                          value,
                                                          setValue,
                                                          options,
                                                          layout,
                                                          disabled = false
                                                      }) => {

    const SelectField = ({placeholder, setValue, options, value}) => {
        return (
            <>
                {placeholder && <FormLabel htmlFor={placeholder}>{placeholder}</FormLabel>}
                {!options && <CircularProgress isIndeterminate size="1.5rem" color="blue.500"/>}
                {options && options.length === 0 && <CircularProgress isIndeterminate size="1.5rem" color="blue.500"/>}
                {options && options.length > 0 && <Select
                    id={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    isDisabled={disabled}
                >
                    {options?.map((option, index) => (
                        <option
                            key={index}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    ))}
                </Select>}
            </>
        )
    }

    return (
        <>
            <FormControl>
                {layout === HORIZONTAL &&
                    <HStack>
                        <SelectField
                            placeholder={placeholder}
                            setValue={setValue}
                            options={options}
                            value={value}
                        />
                    </HStack>
                }
                {
                    (layout === undefined || layout === VERTICAL) &&
                    <VStack>
                        <SelectField
                            placeholder={placeholder}
                            setValue={setValue}
                            options={options}
                            value={value}
                        />
                    </VStack>
                }
                {/*{errorText && <FormErrorMessage>{errorText}</FormErrorMessage>}*/}
            </FormControl>
        </>
    );
};

export default SelectFieldCommon;

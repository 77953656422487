import {Box, Button, Checkbox, Link, Stack} from "@chakra-ui/react";
import React from "react";
import {Form, Formik} from "formik";
import InputField from "../custom/InputField";
import * as yup from "yup";
import axiosInstance from "../../utils/Axios";
import toast, {Toaster} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function LoginForm(props) {
    const {color} = props

    const {setAuth, persist, setPersist} = useAuth()
    const navigate = useNavigate()
    const validationSchema = yup.object({
        email: yup.string().required().email(),
        password: yup.string().min(5).required()
    });

    React.useEffect(() => {
        localStorage.setItem('persist', persist)
    }, [persist])

    return (
        <Box my={8} textAlign='left'>
            <Toaster/>
            <Formik
                enableReinitialize={true}
                validateOnChange={true}
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={validationSchema}
                onSubmit={async (data, {setSubmitting}) => {
                    setSubmitting(true);
                    try {
                        const response = await axiosInstance.post('/auth/login', data);
                        console.log(response.data);
                        if (response.status === 200) {
                            const {user, token} = response.data;
                            setAuth({user, token, roles: ['viewer']})
                            toast.success("login Successful")
                        }
                    } catch (err) {
                        toast.error(err?.response?.data?.message ?? "login failed")
                        return;
                    }
                    setSubmitting(false);
                    await navigate('/')
                }}
            >
                {({values, isSubmitting}) => (
                    <Form>
                        <InputField
                            placeholder={"Email"}
                            name={"email"}
                            type={'email'}
                            value={values.email}
                        />

                        <InputField
                            placeholder={"Password"}
                            name={"password"}
                            type={'password'}
                            value={values.password}
                        />

                        <Stack isInline justifyContent='space-between' mt={4}>
                            <Box>
                                <Checkbox
                                    isChecked={persist}
                                    onChange={(e) => setPersist(e.target.checked)}
                                >Remember Me</Checkbox>
                            </Box>
                            <Box>
                                <Link
                                    color={`${color}.500`}
                                    onClick={() => navigate('/forgot-password')}
                                >Forgot your password?</Link>
                            </Box>
                        </Stack>

                        <Button
                            type={'submit'}
                            // variantColor={color}
                            isLoading={isSubmitting}
                            width='full'
                            mt={4}>Sign In</Button>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}

export default LoginForm

import React, {useState} from "react";
import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from "@chakra-ui/react";
import {VscAdd} from "react-icons/vsc";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const GetClickDataByUTMParams: React.FC = () => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [utmSource, setUtmSource] = useState<string>("");
    const [utmMedium, setUtmMedium] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [clickData, setClickData] = useState<Array<any>>([])
    const [totalClicks, setTotalClicks] = useState<number>(0);
    const axiosPrivate = useAxiosPrivate()


    const handleSubmit = async () => {
        setIsLoading(true)

        try {
            const data = await axiosPrivate.get(`/analytics/deeplink/get-click-data-by-utm?utm_source=${utmSource}&utm_medium=${utmMedium}`)
            if (data.status) {
                setClickData(data.data?.data)
                const c_ = data.data?.data;
                let t_ = 0;
                c_.map(item => {
                    if (item.data.length > 0) {
                        t_ += parseInt(item?.data[0]["count"])
                    }
                })
                setTotalClicks(t_)
            }
        } catch (err) {
            console.log(err)
        }

        setIsLoading(false)
    }

    return (
        <>

            <Modal
                isOpen={modalOpen}
                onClose={() => {
                    setModalOpen(false)
                }}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Deeplink Details</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Flex
                            flexDirection={"column"}
                            gap={2}
                        >
                            <Input
                                value={utmSource}
                                onChange={(e) => {
                                    setUtmSource(e.target.value)
                                }}
                                placeholder={"Enter UTM Parameter here"}
                            />
                            <Input
                                value={utmMedium}
                                onChange={(e) => {
                                    setUtmMedium(e.target.value)
                                }}
                                placeholder={"Enter UTM Medium here"}
                            />
                            <Button
                                onClick={handleSubmit}
                                isLoading={isLoading}
                            >Get Clicks</Button>
                        </Flex>
                        <Flex flexDirection={"column"} gap={2} mt={4}>
                            {clickData.length > 0 &&
                                <Flex flexDirection={"row"} gap={2} justifyContent={"space-between"} mb={4}>
                                    <Flex flexDirection={"row"} gap={2}>
                                        <Text color={"green"}>Entity ID</Text>
                                        <Text>Link Name</Text>
                                    </Flex>
                                    <Text color={"orange"}>Number Of Clicks</Text>
                                </Flex>}
                            {clickData.length > 0 && clickData.map(item => {
                                return <Flex flexDirection={"row"} gap={2} justifyContent={"space-between"}>
                                    <Flex flexDirection={"row"} gap={2}>
                                        <Text color={"green"}>{item.master_id}</Text>
                                        <Text>{item.name}</Text>
                                    </Flex>
                                    <Text
                                        color={"orange"}>{item.data.length > 0 ? item?.data[0]["count"] : "Data Unavailable"}</Text>
                                </Flex>
                            })}
                            {clickData.length > 0 &&
                                <Flex flexDirection={"row"} gap={2} justifyContent={"space-between"}>
                                    <Text fontSize={"lg"}>Total</Text>
                                    <Text fontSize={"lg"} color={"beige"}>{totalClicks}</Text>
                                </Flex>}
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={() => {
                            setModalOpen(false)
                        }}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Button
                leftIcon={<VscAdd size={20}/>}
                borderRadius={8}
                p={3}
                colorScheme={'green'}
                onClick={() => {
                    setModalOpen(true)
                }}>Get Click Data By UTM Params</Button>
        </>
    )
}

export default GetClickDataByUTMParams;
import React from 'react';
import UsersTable from "../../components/users";
import toast from "react-hot-toast";
import {useQuery} from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const Users = () => {
    const axiosPrivate = useAxiosPrivate();

    const {data, isFetching, error, status} = useQuery("users", async function () {
            try {
                return ((await axiosPrivate.get('/user/all')).data).sort((a, b) => a.id - b.id);
            } catch (err) {
                console.log(err.response);
                if (err.response.status === 401) {
                    toast.error("You are not authorized to view this page");
                }
                throw err
            }
        }
    );

    if (data && data.length === 0) {
        toast.error("No users found");
    }

    return (

        <>
            <UsersTable
                users={data}
                isFetching={isFetching}
                error={error as string}
            />

        </>

    )
}

export default Users;

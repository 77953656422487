import React from "react";
import PostgresIDToDocID from "../../components/misc/postgres to doc id";

const GetDocID = () => {
    return (
        <>
            <PostgresIDToDocID/>
        </>
    )
}

export default GetDocID;
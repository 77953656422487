import React from 'react';
import {useQuery} from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loader from "../common/Loader";
import PageTableComponent from "../table/table";
import {PAGES_PAGE} from "../../types/Strings";

const PageByType: React.FC<{
    type: string
}> = ({type}) => {
    const axiosPrivate = useAxiosPrivate();

    const {data, isFetching, isLoading} = useQuery(type, async () => {
        try {
            const data = (await axiosPrivate.get('/page/all?type=' + type));
            return (data.data?.data.sort((a, b) => a?.id - b?.id))
        } catch (err) {
            console.log(err.response);
            throw err
        }
    })


    return (
        <>
            {(isLoading || isFetching) && <Loader/>}

            {
                (!isLoading && !isFetching) && <PageTableComponent
                    data={data}
                    isFetching={isFetching}
                    source={PAGES_PAGE}
                />
            }
        </>
    );
};

export default PageByType;

import React, {useEffect, useState} from 'react';
import {io} from "socket.io-client";
import {Box, Button, Select, Text} from "@chakra-ui/react";
import {EVENT_TESTING_SOCKET_EVENT} from "../../../types/Strings";
import JSONToMapTable from "../../common/JSONToTable";
import {convertArrayToCSV} from "convert-array-to-csv"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import BulkProcessor from "../../../utils/BulkProcessor";
import {SingleEvent} from "./SingleEvent";
import {useMutation, useQuery} from "react-query";
import {queryClient} from "../../../App";
import {EventI} from "../../../types/EventTesting";

const EventTestingComponent: React.FC = () => {
    const [socket, setSocket] = useState(null);
    const [currentEventIdx, setCurrentEventIdx] = useState(0);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);

    const axiosPrivate = useAxiosPrivate();

    const {
        data
    } = useQuery<Array<EventI>>("events-testing" + selectedDevice ? selectedDevice : "", async function () {
        try {
            if (selectedDevice) {
                const response = await axiosPrivate.get('/analytics/events/testing/previous?app_id=' + selectedDevice);
                return response.data?.data;
            }
            return null;
        } catch (err) {
            console.log(err.response);
            throw err
        }
    })

    const foo = (_: Array<EventI>) => {
        return null
    }

    const mutation = useMutation(foo, {
        onMutate: async (events: Array<EventI>) => {
            queryClient.setQueryData("events-testing" + selectedDevice ? selectedDevice : "", (old: Array<EventI>) => {
                const filteredEvents = [];

                for (const event of events) {
                    const timestamp = event.timestamp;

                    const index = old.findIndex((item) => item.timestamp === timestamp);
                    if (index === -1) {
                        filteredEvents.unshift(event);
                    }
                }

                return [...filteredEvents, ...old]
            })
        },
        onError: (error) => {
            console.log(error)
        },
    })

    //Data list ref
    const listRef = React.useRef(null);

    const bulkProcessor = new BulkProcessor(async collectedMessages => {
        const newEvents = [];

        collectedMessages.map(msg => {
            newEvents.unshift(msg);
        })

        await mutation.mutate(newEvents)

        listRef.current.scrollTo(0, 0);
    }, 5000)

    useEffect(() => {
        async function getDevices() {
            try {
                const response = await axiosPrivate.get('/analytics/events/active-connections');
                setDevices(response.data?.data);
                if (response.data?.data && response.data?.data.length > 0) {
                    setSelectedDevice(response.data?.data[0].app_id);
                }
            } catch (err) {
                console.log(err);
            }
        }

        getDevices();
    }, [])

    // @ts-ignore
    useEffect(() => {
        const newSocket = io(process.env.REACT_APP_EVENTS_TESTING_URL);
        setSocket(newSocket);
        return () => newSocket.close();
    }, [setSocket, selectedDevice]);

    useEffect(() => {
        if (socket) {
            const RoomID = EVENT_TESTING_SOCKET_EVENT + selectedDevice;
            socket.on(RoomID, (msg) => {
                bulkProcessor.processData(msg);
            });
        }
    }, [socket, selectedDevice])

    const downloadAsCSV = async () => {
        const events = data.map(item => ({...item.data, timestamp: item.timestamp}));
        const csvFromArrayOfObjects = convertArrayToCSV(events);
        const blob = new Blob([csvFromArrayOfObjects], {type: "text/csv;charset=utf-8"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", "EventTesting" + new Date().toDateString() + ".csv");
        link.click();
    }

    return <>
        <Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
            >
                <Box
                    maxW={"500px"}
                >
                    <Text
                        fontWeight="bold"
                        color="yellow.500"
                        mb={4}
                    >Please turn on the development mode in the App to use this feature</Text>
                    <Text
                        fontWeight="bold"
                        color="green.500"
                        mb={4}
                    >To improve the performance of this app, there might be a delay of few seconds in the recieving of
                        events</Text>
                </Box>
                <Box
                    mt={2}
                    mb={2}
                >
                    <Text
                        mt={2}
                        mb={2}
                    >Select device</Text>
                    <Select
                        onChange={(e) => setSelectedDevice(e.target.value)}

                        mt={2}
                        mb={2}
                    >
                        {devices && devices.map((item, idx) => <option key={idx} value={item.app_id}>
                            {item.app_id} ({item.name}'s device)
                        </option>)}
                    </Select>
                </Box>
                <Box>
                    {selectedDevice && <Text mt={2} mb={2}>
                        {devices.find(item => item.app_id === selectedDevice)?.name}'s device
                    </Text>}
                    <Button
                        variant="outline"
                        onClick={downloadAsCSV}>Download</Button>
                </Box>
            </Box>
        </Box>
        {!selectedDevice && <Text>Please select a device</Text>}


        {selectedDevice && data && data.length === 0 && <Text fontWeight="bold"
                                                              color="red.500"
                                                              fontSize={"xl"}
                                                              mb={2}
        >No events received yet</Text>}


        {/*two column view */}
        <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gridGap={8}
            overflow="hidden"
        >
            <Box>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={"space-between"}
                    mb={2}
                    mt={2}
                >
                    <Text fontWeight="bold" fontSize={"xl"} mb={2}>Events</Text>
                    <Box
                        display="flex"
                        alignItems="center"
                    >
                        <Button mr={2} isDisabled={true}>Previous</Button>
                        <Button mr={2} isDisabled={true}>Next</Button>
                    </Box>
                </Box>
                <Box
                    overflowY="scroll"
                    border="1px solid"
                    borderColor="#2d3649"
                    borderRadius="lg"
                    p={4}
                    height="60vh"
                    ref={listRef}
                >
                    {selectedDevice && data &&
                        data.map((item, index) => {
                            return (
                                <div
                                    key={JSON.stringify(item.timestamp)}
                                >
                                    <SingleEvent
                                        key={JSON.stringify(item.timestamp)}
                                        index={index}
                                        event={item.data}
                                        timestamp={item.timestamp}
                                        setCurrentEvent={(idx) => setCurrentEventIdx(idx)}
                                        currentEventIdx={currentEventIdx}
                                    />
                                </div>
                            )
                        })
                    }
                </Box>
            </Box>
            <Box>
                <Text fontWeight="bold" fontSize={"xl"} mb={2}>Event Details</Text>
                <Box
                    border="1px solid"
                    borderColor="#2d3649"
                    borderRadius="lg"
                    p={4}
                    height="60vh"
                >
                    <JSONToMapTable data={data && data.length > currentEventIdx ? data[currentEventIdx]?.data : null}/>
                </Box>
            </Box>
        </Box>
    </>
}

export default EventTestingComponent;
import {action, makeObservable, observable} from "mobx";

export class CurrentPageImpl {
    current_page: string = "";
    page_loading: boolean = false;

    constructor() {
        makeObservable(this, {
            current_page: observable,
            changePage: action,
            page_loading: observable,
            changePageLoading: action,
        })
    }

    changePage(page: string) {
        this.current_page = page;
    }

    changePageLoading(loading: boolean) {
        this.page_loading = loading;
    }
}

export const CurrentPage = new CurrentPageImpl();


import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import InputField from "../custom/InputField";
import {Form, Formik} from "formik";
import * as yup from "yup";
import SelectField from "../custom/SelectField";
import toast, {Toaster} from 'react-hot-toast';
import {useMutation, useQueryClient} from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import GetAccessLevel from "../../utils/GetAccessLevel";
import {ADMIN} from "../../types/Strings";
import useAuth from "../../hooks/useAuth";

const EditUserModal = ({user}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [isChangingStatus, setIsChangingStatus] = React.useState(false)
    const initialRef = React.useRef()
    const finalRef = React.useRef()
    const axiosPrivate = useAxiosPrivate();
    const queryClient = useQueryClient()
    //@ts-ignore
    const {auth} = useAuth();
    const {mutate} = useMutation(async (data) => {
        await axiosPrivate.patch('/user/update', data)
    }, {
        onSuccess: async () => {
            toast.success('User updated successfully')
            await queryClient.invalidateQueries('users')
        },
        onError: () => {
            toast.error('Error updating user')
        }
    })

    async function handleStatusUser(id: number, status: boolean) {
        setIsChangingStatus(true)
        try {
            const response = await axiosPrivate.get(`/user/${status === true ? 'delete' : 'activate'}?id=` + id);
            if (response.status === 200) {
                toast.success("User status changed successfully")
                await queryClient.invalidateQueries('users')
            } else {
                toast.error("User status change failed")
            }
            setIsChangingStatus(false)
        } catch (err) {
            toast.error('Error changing user status')
            setIsChangingStatus(false)
        }
    }

    async function handleResetUser(id: number) {
        try {
            const response = await axiosPrivate.get(`/user/reset?id=` + id);
            if (response.status === 200) {
                toast.success('User password reset')
            } else {
                toast.error('User password not reset')
            }
        } catch (err) {
            console.log(err)
            toast.error('Error resetting user password')
        }
    }


    return (

        <>
            <Button
                // variantColor='facebook'
                variant='outline'
                size='sm'
                onClick={onOpen}
                disabled={!GetAccessLevel(auth, [ADMIN])}
            >
                Edit
            </Button>

            <Toaster/>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Edit User</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody pb={6}>
                        <Formik
                            enableReinitialize={true}
                            validateOnChange={true}
                            initialValues={{
                                id: user.id || "",
                                email: user.email || '',
                                name: user.name || '',
                                phone: user.phone || '',
                                access_level: user.access_level || '',
                                is_active: user.is_active || '',
                            }}
                            validationSchema={yup.object({
                                id: yup.number().required(),
                                email: yup.string().required().email(),
                                name: yup.string().required(),
                                phone: yup.string().required(),
                                access_level: yup.string().required(),
                            })}
                            onSubmit={async (data, {setSubmitting}) => {
                                setSubmitting(true);
                                // @ts-ignore
                                mutate(data)
                            }}
                        >
                            {({values, isSubmitting}) => (
                                <Form>
                                    <InputField
                                        placeholder={"ID"}
                                        name={"id"}
                                        type={'text'}
                                        disabled={true}
                                    />

                                    <InputField
                                        placeholder={"Email"}
                                        name={"email"}
                                        type={'email'}
                                    />

                                    <InputField
                                        placeholder={"Name"}
                                        name={"name"}
                                        type={'text'}
                                    />

                                    <InputField
                                        placeholder={"Phone"}
                                        name={"phone"}
                                        type={'text'}
                                    />

                                    <SelectField
                                        placeholder={"Access Level"}
                                        name={'access_level'}
                                        options={[
                                            {value: '0', label: 'Admin'},
                                            {value: '1', label: 'Editor'},
                                            {value: '2', label: 'Viewer'},
                                        ]}
                                    />
                                    <Button
                                        mt={3}
                                        mr={3}
                                        type='submit'
                                        isLoading={isSubmitting}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        colorScheme='blue'
                                        mt={3}
                                        mr={3}
                                        onClick={() => handleStatusUser(values.id, values.is_active)}
                                        isLoading={isChangingStatus}
                                    >
                                        {values.is_active === true ? 'Deactivate' : 'Activate'}
                                    </Button>
                                    <Button
                                        colorScheme='blue'
                                        mt={3}
                                        onClick={() => handleResetUser(values.id)}
                                    >
                                        Reset Password
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>

                    <ModalFooter>

                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>

    )
}

export default EditUserModal;
import React, {useEffect, useState} from "react";
import {io} from "socket.io-client";
import {produce} from "immer";

const CronJobManager: React.FC = () => {
    const [socket, setSocket] = useState(null);
    const [data, setData] = useState([]);
    // @ts-ignore
    useEffect(() => {
        const newSocket = io(`http://localhost:8080`);
        setSocket(newSocket);
        return () => newSocket.close();
    }, [setSocket]);

    useEffect(() => {
        if (socket) {
            socket.on("cron", (msg) => {
                setData(produce(data, (draft) => {
                    const timestamp = msg.timestamp;
                    const index = draft.findIndex((item) => item.timestamp === timestamp);
                    console.log(index);
                    console.log(timestamp)
                    if (index === -1) {
                        draft.push(msg);
                    }
                }));

            });
        }
    }, [socket, data])

    return <>
        <h1>CronJobManager</h1>
        <pre>{data &&
            data.map((item, index) => {
                return <div key={index}>{JSON.stringify(item.data)}</div>
            })
        }</pre>
    </>
}

export default CronJobManager;
import React, {useState} from 'react'
import {Button} from "@chakra-ui/react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

const __DEV__ = document.domain === 'localhost'

function RazorPay() {
    const [name, setName] = useState('Mehul')

    const axiosPrivate = useAxiosPrivate();

    const handlePaymentVerification = async (data) => {
        const payment = await axiosPrivate.post("/payments/payment-verification", {...data});
        console.log(payment)
    }

    async function displayRazorpay() {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            return
        }

        const resData = await axiosPrivate.post("/payments/create-order", {
            user_id: 92689,
            entity_id: 29180
        }).then(res => res?.data?.data).catch(err => {
            alert(err.response.data.message)
        })

        console.log(resData)


        const options = {
            key: __DEV__ ? 'rzp_test_U1zscWCozJZbL7' : 'PRODUCTION_KEY',
            currency: "INR",
            amount: (resData.amount - 5).toString(),
            order_id: resData.order_id,
            name: 'Donation',
            description: 'Thank you for nothing. Please give us some money',
            receipt: resData.receipt_number,
            image: 'http://localhost:1337/logo.svg',
            handler: async function (response) {
                await handlePaymentVerification(response);
            }
        }
        //@ts-ignore
        const paymentObject = new window.Razorpay(options)
        paymentObject.open()
    }

    return (
        <div className="App">
            <header className="App-header">
                <Button
                    onClick={displayRazorpay}
                    // target="_blank"
                    // rel="noopener noreferrer"
                >
                    Donate
                </Button>
            </header>
        </div>
    )
}

export default RazorPay
import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/table";
import React from "react";
import {
    Box,
    Checkbox,
    Flex,
    HStack,
    IconButton, NumberDecrementStepper, NumberIncrementStepper,
    NumberInput,
    NumberInputField, NumberInputStepper,
    Text,
    Tooltip,
    VStack
} from "@chakra-ui/react";
import {HORIZONTAL, MODULE_PAGE} from "../../../types/Strings";
import {FiEdit} from "react-icons/fi";
import StatusIndicator from "../../common/StatusIndicator";
import {MdOutlineContentCopy} from "react-icons/md";
import copy from "copy-to-clipboard";
import {toast} from "react-hot-toast";
import {ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon} from "@chakra-ui/icons";
import SelectFieldCommon from "../../custom/common/SelectCommon";
import PageFilter from "../../common/filter";
import {EntityTypes} from "../../../config/Options";

interface Props {
    data: any;
    type: string;
    source?: string;
    handleChange?: (id: string, e: boolean, s: string) => void;
}

const SearchResultTable: React.FC<Props> = ({data, type, source}) => {

    return (
        <>
            {data && data.length > 0 && <ResultTable
                items={data}
                type={type}
                source={source}
            />}
        </>
    )
}

interface PropsT {
    items: any;
    type: string;
    source?: string;
}

const ResultTable: React.FC<PropsT> = ({items, type, source}) => {
    const [isMouseOver, setIsMouseOver] = React.useState(false);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [perPage, setPerPage] = React.useState(10);

    //Change data according to page number
    const data = React.useMemo(() => {
        const firstPageIndex = pageNumber * perPage;
        const lastPageIndex = firstPageIndex + perPage;

        return items.slice(firstPageIndex, lastPageIndex);
    }, [pageNumber, perPage, items]);


    const previous = () => {
        setPageNumber(Math.max(0, pageNumber - 1));
    };

    const next = () => {
        setPageNumber(Math.min(pageCount - 1, pageNumber + 1));
    };

    const goToPage = (page: number) => {
        const pageNumber = Math.max(0, Math.min(page, pageCount - 1));
        setPageNumber(pageNumber);
    };

    const pageCount = Math.ceil(items.length / perPage);


    const copyToCopyClipboard = () => {
        const dataToCopy = items.map(item => item.id).join(',');
        copy(dataToCopy);

        toast.success(`Copied ${dataToCopy.split(',').length} items to clipboard`);
    }

    return (
        <>
            {/*Pagination Bar*/}
            <Box
                display={"flex"}
                alignItems={"center"}
            >
                <Flex my={4} alignItems="center">
                    <Flex>
                        <Tooltip label="First Page">
                            <IconButton
                                onClick={() => goToPage(0)}
                                isDisabled={pageNumber === 0}
                                icon={<ArrowLeftIcon h={3} w={3}/>}
                                mr={4}
                                aria-label={"First Page"}
                            />
                        </Tooltip>
                        <Tooltip label="Previous Page">
                            <IconButton
                                onClick={previous}
                                isDisabled={pageNumber === 0}
                                icon={<ChevronLeftIcon h={6} w={6}/>}
                                aria-label={"Previous Page"}
                            />
                        </Tooltip>

                        <Flex align="center">
                            {/* <Text color={"#e1970b"} flexShrink="0" ml={4} mr={4}> */}
                            <Text color={"#e1970b"} ml={4} mr={4}>
                                Page{" "}
                                <Text fontWeight="bold" as="span">
                                    {pageNumber + 1}
                                </Text>{" "}
                                of{" "}
                                <Text fontWeight="bold" as="span">
                                    {pageCount}
                                </Text>
                            </Text>
                        </Flex>

                        <Tooltip label="Next Page">
                            <IconButton
                                isDisabled={pageNumber === pageCount - 1}
                                onClick={next}
                                icon={<ChevronRightIcon h={6} w={6}/>}
                                aria-label={"Next Page"}
                            />
                        </Tooltip>

                        <Tooltip label="Last Page">
                            <IconButton
                                isDisabled={pageNumber === pageCount - 1}
                                onClick={() => goToPage(pageCount - 1)}
                                icon={<ArrowRightIcon h={3} w={3}/>}
                                ml={4}
                                aria-label={"Last Page"}
                                mr={8}
                            />
                        </Tooltip>
                    </Flex>
                    <Flex alignItems="center">

                        {/* <Text flexShrink="0" color={"#e1970b"}>Go to page:</Text>{" "} */}
                        <Text color={"#e1970b"}>Go to page:</Text>{" "}
                        <NumberInput
                            ml={2}
                            mr={8}
                            w={20}
                            min={1}
                            max={pageCount}
                            onChange={(value) => {
                                const page: number = parseInt(value) ? parseInt(value) - 1 : 0;
                                goToPage(page);
                            }}
                            defaultValue={pageNumber + 1}
                        >
                            <NumberInputField/>
                            <NumberInputStepper>
                                <NumberIncrementStepper/>
                                <NumberDecrementStepper/>
                            </NumberInputStepper>
                        </NumberInput>
                    </Flex>

                    <Flex ml={2} alignItems="center">
                        {setPerPage && <Box>
                            <SelectFieldCommon
                                placeholder={'Per Page'}
                                value={perPage}
                                layout={HORIZONTAL}
                                setValue={(d) => setPerPage(d as number)}
                                options={[
                                    {value: 5, label: '5'},
                                    {value: 10, label: '10'},
                                    {value: 20, label: '20'},
                                    {value: 30, label: '30'},
                                    {value: 40, label: '40'},
                                    {value: 50, label: '50'},
                                ]}/>

                        </Box>}
                    </Flex>
                </Flex>
            </Box>

            <Table>
                <Thead>
                    <Tr>
                        {source && source === MODULE_PAGE && <Th><Checkbox/></Th>}
                        <Th><HStack><Text>ID</Text> <MdOutlineContentCopy
                            size={20}
                            onClick={copyToCopyClipboard}
                            onMouseOver={() => {
                                setIsMouseOver(true)
                            }}
                            onMouseLeave={() => {
                                setIsMouseOver(false)
                            }}
                            style={{
                                cursor: 'pointer',
                                opacity: isMouseOver ? 1 : 0.5,
                                transform: isMouseOver ? 'scale(1.2)' : 'scale(1)',
                            }}
                        /></HStack></Th>
                        <Th>Name</Th>
                        <Th>Is Active</Th>
                        <Th>Edit</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data && data.map((item, index) => (
                        <Tr key={index}>
                            {source && source === MODULE_PAGE && <Td><Checkbox
                            /></Td>}
                            <Td>{item.id}</Td>
                            <Td>
                                <VStack align={"left"}>
                                    <Box>
                                        {item?.title || item?.name}
                                    </Box>
                                    <Box>
                                        <HStack align={"center"}>
                                            <Text color={"#838a8a"}>Last Modified
                                                On {new Date(item.last_modified).toDateString()}</Text>
                                        </HStack>
                                    </Box>
                                </VStack>
                            </Td>
                            <Td><StatusIndicator is_active={item?.is_active}/> </Td>
                            <Td>
                                <FiEdit
                                    size={24}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#008ff5',
                                    }}
                                    onClick={() => {
                                        window.open(`/entity-edit/${item.id}`, '_blank')
                                    }}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    )
}


export default SearchResultTable;

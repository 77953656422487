import React, {useEffect, useState} from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";

const NoInternetConnection = (props) => {
    const [isOnline, setOnline] = useState(true);

    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });


    return (
        <>
            {!isOnline && <InternetConnectionUnAvailable/>}
            {!isOnline && <Box
                pointerEvents={"none"}
                opacity={0.4}
            >
                {props.children}
            </Box>}
            {isOnline && props.children}
        </>
    )

}

const InternetConnectionUnAvailable = () => {
    return (
        <Flex
            h={60}
            justifyContent={"center"}
            alignContent={"center"}
            p={4}
        >
            <Text
                fontSize={40}
                // color={"#e73838"}
            >No Internet Connection</Text>
        </Flex>
    )
}

export default NoInternetConnection;
import React from 'react'
import {Collapse, Flex, HStack, Link, Menu, MenuButton, Text, useColorModeValue, useDisclosure} from '@chakra-ui/react'
import {observer} from "mobx-react";
import {FiChevronDown, FiChevronUp} from 'react-icons/fi'
import RenderNavItems from "./RenderNavItems";

const CollpasibleNavitem = observer(({children, route, active, currentPage, index, childNavItems}) => {
    const {isOpen, onToggle} = useDisclosure()

    return (
        <Flex
            mt={index === 0 ? 0 : 1}
            flexDir="column"
            w="100%"
            alignItems={"flex-start"}
        >
            <Menu
                placement="right"
            >
                <Link
                    color={currentPage.current_page === route ? "#767a7a" : useColorModeValue('gray.300', 'gray.100')}
                    p={3}
                    _disabled={active}
                    onClick={() => {
                        onToggle()
                    }}
                    borderRadius={8}
                    _hover={{textDecor: 'none', backgroundColor: "#AEC8CA", color: "#1A202C"}}
                    backgroundColor={isOpen ? '#4a5454' : ''}
                    w={"100%"}
                >
                    <MenuButton w="100%"
                    >
                        <HStack
                            w="100%"
                            alignItems={"center"}
                        >
                            <Flex>
                                {children}
                                {/*<Text display={"flex"}>{title}</Text>*/}
                            </Flex>
                            {isOpen ? <FiChevronUp/> : <FiChevronDown/>}
                        </HStack>
                    </MenuButton>
                </Link>
            </Menu>

            <Collapse
                in={isOpen}
                style={{
                    marginTop:6,
                    backgroundColor:'#4a5454',
                    borderRadius:8,
                }}
            >
                <RenderNavItems navItems={childNavItems} currentPage={currentPage}/>
            </Collapse>
        </Flex>
    )
});

export default CollpasibleNavitem;

import axiosInstance from "../utils/Axios";
import useAuth from "./useAuth";
import {produce} from "immer";

const useRefreshToken = () => {
    // @ts-ignore
    const {setAuth, auth} = useAuth();

    return async () => {
        const response = await axiosInstance.get('/auth/refresh');

        setAuth(produce(auth, draft => {
            draft.roles = response.data.roles;
            draft.user = response.data.user;
            draft.token = response.data.access_token;
        }));

        return response.data.access_token;
    };
}

export default useRefreshToken;
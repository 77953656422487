import React from 'react';
import {useQuery} from "react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Loader from "../components/common/Loader";
import {AnalyticsTabular} from "../components/analytics/Analytics";

function Index() {
    const axiosPrivate = useAxiosPrivate();
    const [startDate, setStartDate] = React.useState(new Date(new Date().setDate(new Date().getDate() - 7)));
    const [endDate, setEndDate] = React.useState(new Date());

    const {data, isFetching, refetch} = useQuery("analytics", async function () {
            try {
                const data = (await axiosPrivate.get(`/analytics?from=${startDate.toISOString().split('T')[0]}&to=${endDate.toISOString().split('T')[0]}`))
                return (data?.data?.data);
            } catch (err) {
                console.log(err.response);
                throw err
            }
        }
    );

    React.useEffect(() => {
        refetch();
    }, [startDate, endDate, refetch]);

    return (
        <>
            {isFetching && <Loader/>}
            {!isFetching &&
                <>
                    <AnalyticsTabular
                        data={data}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                    />
                </>
            }
        </>
    )
}


export default Index

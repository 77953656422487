import {Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react";
import React, {useState} from "react";
import {produce} from "immer";
import AnalyticsStack from "./AnalyticsStack";
import {DateRangePickerComponent} from "../common/DateRange";

interface TabsDataImpl {
    label: string;
    data: any[];
    id: string;
    type: string;
}

export const AnalyticsTabular: React.FC<{
    data: any, startDate: Date;
    endDate: Date;
    setStartDate: (date: Date) => void;
    setEndDate: (date: Date) => void;
}> = ({data, startDate, endDate, setStartDate, setEndDate}) => {
    const [tabs, setTabs] = useState<TabsDataImpl[]>(
        [
            {label: 'Playlists', data: [], id: 'playlists_added_last_week', type: 'playlist'},
            {label: 'Modules', data: [], id: 'modules_added_last_week', type: 'module'},
            {label: 'Videos', data: [], id: 'videos_added_last_week', type: 'video'},
            {label: 'Links', data: [], id: 'links_added_last_week', type: 'entity'},
        ]
    );

    React.useEffect(() => {
        setTabs(produce(tabs, (draft) => {
            draft.forEach((tab) => {
                Object.keys(data).forEach((key) => {
                    if (tab.id === key) {
                        tab.data = data[key];
                    }
                });
            });
            return draft;
        }))
    }, [tabs]);

    return (
        <>
            <Flex
                my={4}
                justifyContent={'space-between'}
            >
                <DateRangePickerComponent
                    onChange={(e) => {
                        setStartDate(e.selection.startDate)
                        setEndDate(e.selection.endDate)
                    }}
                    ranges={[{
                        startDate: startDate,
                        endDate: endDate,
                        key: 'selection',
                    }]}
                />
                <Text
                    p={2}
                    style={{
                        border: '1px solid #e2e8f0',
                        borderRadius: '5px',
                    }}
                >
                    {startDate.toDateString()} - {endDate.toDateString()}
                </Text>
            </Flex>
            <Tabs>
                <TabList>
                    {tabs.map((tab, index) => (
                        <Tab key={index}>{tab.label}</Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {tabs.map((tab, index) => (
                        <TabPanel p={4} key={index}>
                            <AnalyticsStack
                                data={tab.data}
                                type={tab.type}
                            />
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </>
    )
}


import {
    Avatar,
    AvatarBadge,
    Button,
    Center,
    FormControl,
    FormLabel,
    Heading,
    IconButton,
    Input,
    Stack,
    Text,
} from '@chakra-ui/react';
import {SmallCloseIcon} from '@chakra-ui/icons';
import useAuth from "../../hooks/useAuth";

const ProfileForm = () => {
    //@ts-ignore
    const {auth} = useAuth();
    const {email, phone, name, image_url, created_at, access_level, id} = auth.user;
    return (
        <Stack
            spacing={4}
            w={'full'}
            maxW={'md'}
            p={6}
            my={12}>

            <Heading lineHeight={1.1} fontSize={{base: '2xl', sm: '3xl'}}>
                Update Profile
            </Heading>
            <FormControl id="userName">
                <FormLabel>User Icon</FormLabel>
                <Stack direction={['column', 'row']} spacing={6}>
                    <Center>
                        <Avatar size="xl" src={image_url}>
                            <AvatarBadge
                                as={IconButton}
                                size="sm"
                                rounded="full"
                                top="-10px"
                                colorScheme="red"
                                aria-label="remove Image"
                                icon={<SmallCloseIcon/>}
                            />
                        </Avatar>
                    </Center>
                    <Center w="full">
                        <Button w="full">Change Icon</Button>
                    </Center>
                </Stack>
            </FormControl>
            <FormControl id="id" isRequired>
                <FormLabel>Unique ID</FormLabel>
                <Input
                    placeholder="Unique ID"
                    _placeholder={{color: 'gray.500'}}
                    value={id}
                    readOnly
                />
            </FormControl>
            <FormControl id="userName" isRequired>
                <FormLabel>User name</FormLabel>
                <Input
                    placeholder="UserName"
                    _placeholder={{color: 'gray.500'}}
                    type="text"
                    value={name}
                    readOnly
                />
            </FormControl>
            <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input
                    placeholder="your-email@example.com"
                    _placeholder={{color: 'gray.500'}}
                    type="email"
                    value={email}
                    readOnly
                />
            </FormControl>
            <FormControl id="phone" isRequired>
                <FormLabel>Phone</FormLabel>
                <Input
                    placeholder="phone"
                    _placeholder={{color: 'gray.500'}}
                    value={phone}
                    readOnly
                />
            </FormControl>
            <FormControl id="accessLevel" isRequired>
                <FormLabel>Access Level</FormLabel>
                <Input
                    placeholder="Access Level"
                    _placeholder={{color: 'gray.500'}}
                    value={access_level}
                    readOnly
                />
            </FormControl>
            <Text>
                {created_at}
            </Text>

            <Stack spacing={6} direction={['column', 'row']}>
                <Button
                    bg={'red.400'}
                    color={'white'}
                    w="full"
                    _hover={{
                        bg: 'red.500',
                    }}>
                    Cancel
                </Button>
                <Button
                    bg={'blue.400'}
                    color={'white'}
                    w="full"
                    _hover={{
                        bg: 'blue.500',
                    }}>
                    Submit
                </Button>
            </Stack>
        </Stack>
    );
}

export default ProfileForm;
import React from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    useDisclosure
} from "@chakra-ui/react";
import {VscAdd} from "react-icons/vsc";
import {InternalLinkPageTypes} from "../../config/Options";
import copy from "copy-to-clipboard";
import {toast} from "react-hot-toast";

const CreateInternalLinkModal: React.FC = () => {
    const [linkType, setLinkType] = React.useState(null);
    const [linkID, setLinkID] = React.useState(null);

    const {isOpen, onOpen, onClose} = useDisclosure()
    const finalRef = React.useRef(null);


    const generateDeeplink = async () => {
        let link = `paavanapp://${linkType}`;

        if (linkID) {
            link += `/${linkID}`;
        }

        copy(link);
        toast.success(`Copied ${link} to clipboard`);
    }

    return (
        <>
            <Button
                leftIcon={<VscAdd size={20}/>}
                borderRadius={8}
                p={3}
                ref={finalRef}
                colorScheme={'green'}
                onClick={onOpen}
            >Create Internal Link</Button>

            <Modal
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Create Internal Link</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Select
                            placeholder={'Select Link Type'}
                            onChange={(e) => {
                                setLinkID(null);
                                setLinkType(e.target.value)
                            }}
                            value={linkType}
                            mb={4}
                        >
                            {InternalLinkPageTypes.map((type, index) => {
                                return (
                                    <option key={index} value={type.value}>{type.label}</option>
                                )
                            })}
                        </Select>

                        {linkType && InternalLinkPageTypes.filter(item => item.value === linkType)[0]['requireID'] &&
                            <Input
                                placeholder="Link ID"
                                value={linkID}
                                onChange={(e) => {
                                    setLinkID(e.target.value)
                                }}
                                type={'number'}
                            />}

                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={generateDeeplink}>
                            Copy Link
                        </Button>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default CreateInternalLinkModal
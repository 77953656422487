import React from "react";
import {
    Box,
    Button, FormControl, FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select, Textarea,
    useDisclosure
} from "@chakra-ui/react";
import {SavedQuery} from "../../../types/query_manager";
import {createSavedQuery, updateSavedQuery} from "../../../services/query_manager";
import toaster from "react-hot-toast";

interface EditQueryModalProps {
    currentQuery: SavedQuery;
    isNew: boolean;
}

const EditQueryModal: React.FC<EditQueryModalProps> = ({currentQuery, isNew}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [queryTitle, setQueryTitle] = React.useState(isNew ? null : currentQuery.title);
    const [queryQuery, setQueryQuery] = React.useState(isNew ? null : currentQuery.query);
    const [queryType, setQueryType] = React.useState(isNew ? null : currentQuery.type);
    const [isSaving, setIsSaving] = React.useState(false);
    const clickHandler = async () => {
        setIsSaving(true);

        //Service call for new create
        if (isNew) {
            const res = await createSavedQuery({
                title: queryTitle,
                query: queryQuery,
                type: queryType
            });

            if (res) {
                onClose();
                toaster.success("Query created successfully");
            } else {
                toaster.error("Query creation failed");
            }
            setIsSaving(false);

        } else {
            //Service call for update
            const res = await updateSavedQuery({
                id: currentQuery.id,
                title: queryTitle,
                query: queryQuery,
                type: queryType
            })

            if (res) {
                onClose();
                toaster.success("Query updated successfully");
            } else {
                toaster.error("Query update failed");
            }

            setIsSaving(false);
        }
    }

    return (
        <>
            <Button mr={2} onClick={onOpen} colorScheme={'blue'}>{isNew ? 'Create' : 'Edit'}</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>{`${isNew ? 'Create' : 'Edit'} Query`}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            w={"100%"}
                            h={"100%"}
                            gap={2}
                        >
                            <FormControl>
                                <FormLabel htmlFor={"query_title"}>Query Title</FormLabel>
                                <Input
                                    id="query_title"
                                    placeholder="Query Title"
                                    value={queryTitle}
                                    onChange={(e) => setQueryTitle(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor={"query_description"}>Query Description</FormLabel>
                                <Textarea
                                    id={"query_description"}
                                    placeholder="Query"
                                    value={queryQuery}
                                    onChange={(e) => setQueryQuery(e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor={"query_type"}>Query Type</FormLabel>
                                <Select
                                    placeholder="Select option"
                                    id={"query_type"}
                                    value={queryType}
                                    onChange={(e) => setQueryType(e.target.value)}
                                >
                                    {[
                                        {label: 'Postgres', value: 'POSTGRES'},
                                        {label: 'Big Query', value: 'BIGQUERY'},
                                    ].map((option) => {
                                        return <option key={option.value} value={option.value}>{option.label}</option>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button colorScheme='green' isLoading={isSaving}
                                onClick={clickHandler}>{isNew ? "Save" : "Update"}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default EditQueryModal
import React from "react";
import {
    Box,
    Button,
    Circle,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger,
    Text,
    Tooltip,
    VStack
} from "@chakra-ui/react";
import {Table, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/table";
import {MdOutlineContentCopy} from "react-icons/md";
import copy from "copy-to-clipboard";
import {toast} from "react-hot-toast";
import DeeplinkDetails from "./LinkDetails";

import {BsFillEyeFill} from "react-icons/bs";

interface ViewAllDeeplinksProps {
    data: [any];
}

const ViewAllDeeplinks: React.FC<ViewAllDeeplinksProps> = ({data}) => {
    const [isMouseOver, setIsMouseOver] = React.useState(null);
    const [isToggleOpenID, setIsToggleOpenID] = React.useState(null);
    const finalRef = React.useRef(null);

    const copyToCopyClipboard = (id: number) => {
        const dataToCopy = data.filter(item => item.id === id)[0];
        copy(dataToCopy.link);
        toast.success(`Copied ${dataToCopy.name}'s link to clipboard`);
    }
    return (
        <>
            <Table>
                <Thead>
                    <Tr>
                        <Th isNumeric>Entity ID</Th>
                        <Th>Name</Th>
                        <Th>UTM Details</Th>
                        <Th>Copy Link</Th>
                        <Th>Details</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        data && data.map((item: any, index: number) => {
                            return (
                                <>
                                    <Tr
                                        ref={finalRef}
                                        key={index}
                                    >
                                        <Td
                                            isNumeric
                                        >
                                            {item.master_id}
                                        </Td>
                                        <Td>
                                            <VStack align={'left'}>
                                                <Box>
                                                    {item.name}
                                                </Box>
                                                <Box>
                                                    <HStack
                                                        align={"center"}
                                                        style={{
                                                            fontSize: 12
                                                        }}
                                                    >
                                                        <Tooltip
                                                            label="Created By"
                                                        >
                                                            <Text color={"#838a8a"}>{item?.created_by} </Text>
                                                        </Tooltip>
                                                        <Circle size={"4px"} bg={"#838a8a"}/>
                                                        <Tooltip
                                                            label={"Last modification date"}
                                                        >
                                                            <Text
                                                                color={"#838a8a"}>{new Date(item.created_at).toDateString()}</Text>
                                                        </Tooltip>
                                                    </HStack>
                                                </Box>
                                            </VStack>
                                        </Td>
                                        <Td>
                                            <VStack align={'left'}>
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Button
                                                            style={{
                                                                width: "40%",
                                                                alignItems: "center",
                                                                justifyContent: "center"

                                                            }}
                                                        >
                                                            <BsFillEyeFill
                                                                style={{
                                                                    marginLeft: 5
                                                                }}
                                                                size={18}
                                                            />
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverArrow/>
                                                        <PopoverCloseButton/>
                                                        <PopoverHeader>Details</PopoverHeader>
                                                        <PopoverBody>
                                                            <VStack
                                                                align={'left'}
                                                            >
                                                                <Text><span style={{color:'yellow'}}>Source:</span> {item.utm_source}</Text>
                                                                <Text><span style={{color:'yellow'}}>Medium:</span> {item.utm_medium}</Text>
                                                                <Text><span style={{color:'yellow'}}>Campaign:</span> {item.utm_campaign}</Text>
                                                                <Text><span style={{color:'yellow'}}>Content:</span> {item.utm_content}</Text>
                                                                <Text><span style={{color:'yellow'}}>Term:</span> {item.utm_term}</Text>
                                                            </VStack>
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                            </VStack>
                                        </Td>
                                        <Td>
                                            <Tooltip
                                                label={item.link}
                                            >
                                                <Button
                                                    onClick={() => copyToCopyClipboard(item.id)}
                                                    onMouseOver={() => {
                                                        setIsMouseOver(item.id)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setIsMouseOver(null)
                                                    }}
                                                >
                                                    <MdOutlineContentCopy
                                                        size={20}
                                                        style={{
                                                            cursor: 'pointer',
                                                            opacity: isMouseOver === item.id ? 1 : 0.5,
                                                            transform: isMouseOver === item.id ? 'scale(1.2)' : 'scale(1)',
                                                            transition: 'all 0.2s ease-in-out',
                                                            transitionDelay: isMouseOver === item.id ? '0s' : '0.2s',
                                                        }}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </Td>
                                        <Td>
                                            <Button
                                                onClick={() => {
                                                    if (isToggleOpenID) {
                                                        setIsToggleOpenID(null)
                                                    } else {
                                                        setIsToggleOpenID(item.link)
                                                    }
                                                }}
                                                variant={"outline"}
                                                style={{
                                                    cursor: 'pointer',
                                                    border: '1px solid #838a8a',
                                                }}
                                                size={'sm'}
                                            >
                                                View Details
                                            </Button>
                                        </Td>
                                    </Tr>
                                </>
                            )
                        })
                    }
                </Tbody>
            </Table>
            <Modal
                finalFocusRef={finalRef}
                isOpen={isToggleOpenID}
                onClose={() => {
                    setIsToggleOpenID(null)
                }}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Deeplink Details</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <DeeplinkDetails link={isToggleOpenID}/>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={() => {
                            setIsToggleOpenID(null)
                        }}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}


export default ViewAllDeeplinks;
import React from "react";
import {
    Badge,
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Input,
    Progress,
    Select,
    VStack
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import VideoUploadResult from "./video-upload-result";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import LockAccess from "../../utils/LockAccess";
import {ADMIN, EDITOR} from "../../types/Strings";
import {produce} from "immer";

const VideoUpload: React.FC = () => {
    const [input, setInput] = React.useState("");
    const [ids, setIds] = React.useState<any[]>([]);
    const [valid, setValid] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [result, setResult] = React.useState(null);
    const [type, setType] = React.useState("video");
    const [uploadedVideos, setUploadedVideos] = React.useState<any[] | null>(null);

    const [isDocumentUploaded, setIsDocumentUploaded] = React.useState(false);

    const axiosPrivate = useAxiosPrivate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inp = e.target.value;
        setInput(inp);

        if (inp.length === 0) {
            setError("Please enter a video ID");
            return;
        } else {
            setError(null);
        }

        //Seperate the input into an array of video IDs separated by commas
        let videoIds = inp.split(",");

        for (let i = 0; i < videoIds.length; i++) {
            //Remove any whitespace from the video ID
            videoIds[i] = videoIds[i].trim();
        }

        setIds(videoIds.map(vid => {
            return {
                id: vid,
                error: vid.length !== 11
            }
        }))

        const isValid = videoIds.every(vid => vid.length === 11);

        if (isValid) {
            setValid(true);
        } else {
            setValid(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        setUploadedVideos(null)
        const res = await axiosPrivate.post('/video', {
            ids: ids.map(vid => vid.id)
        })

        if (res.data) {
            if (res.data?.status === 200) {
                setResult(produce(result, draft => {
                    draft = res.data?.data;
                    return draft;
                }))
                toast.success(res.data?.message);
            } else {
                toast.error(res.data?.message);
            }
        } else {
            toast.error("Something Went Wrong");
        }
        console.log(res.data)

        setLoading(false);
    };

    const {auth} = useAuth();

    return (
        <>
            <VStack
                align="left"
                spacing={4}
                opacity={LockAccess(auth, [ADMIN, EDITOR]).opacity}
                style={{...LockAccess(auth, [ADMIN, EDITOR]).style as any}}
            >
                {!isDocumentUploaded && <Box>

                    <Box
                        w={"100%"}
                    >
                        <FormControl
                            isInvalid={!!error}
                        >
                            <FormLabel htmlFor={'youtube_entity_ids'}>Enter Youtube Entity IDs</FormLabel>
                            <Box
                                display="flex"
                                flexDirection="row"
                            >
                                <Input
                                    id={'youtube_entity_ids'}
                                    placeholder="Enter comma seperated 11 character video IDs eg.  2XiRewPvfmA, 2XiRewPvfmB"
                                    size="lg"
                                    variant="filled"
                                    height="50px"
                                    borderRadius="md"
                                    value={input}
                                    onChange={handleInputChange}
                                    _focus={{
                                        borderColor: "teal.500",
                                        boxShadow: "0 0 0 2px teal.500"
                                    }}
                                    mr={2}
                                />
                                <Select
                                    placeholder='Select type'
                                    variant='filled'
                                    width={"15%"}
                                    size="md"
                                    height="50px"
                                    borderRadius="md"
                                    defaultValue={'video'}
                                    mr={2}
                                    onChange={(e) => {
                                        setType(e.target.value)
                                    }}
                                >
                                    <option value='video'>Video</option>
                                    <option value='shorts'>Shorts</option>
                                </Select>
                                <Button
                                    size="lg"
                                    variant="solid"
                                    width="10%"
                                    disabled={!valid}
                                    height="50px"
                                    borderRadius="md"
                                    mb={2}
                                    isLoading={loading}
                                    onClick={() => handleSubmit()}
                                >
                                    Fetch
                                </Button>
                            </Box>

                            {loading && <Progress size='xs' w="50%" isIndeterminate/>}
                            {error && <FormErrorMessage>{error}</FormErrorMessage>}
                        </FormControl>

                    </Box>
                    {/*<Box>*/}
                    {/*    <Heading my={8} size={'lg'}>*/}
                    {/*        OR*/}
                    {/*    </Heading>*/}
                    {/*</Box>*/}
                </Box>}

                {/*<Box>*/}
                {/*    <CSVReaderComponent*/}
                {/*        isDocUploaded={(val) => {*/}
                {/*            setIsDocumentUploaded(val)*/}
                {/*        }}*/}
                {/*        setIds={(val) => {*/}
                {/*            setIds(val)*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    {isDocumentUploaded && ids.length > 0 && <Button*/}
                {/*        size="lg"*/}
                {/*        variant="solid"*/}
                {/*        width="10%"*/}
                {/*        height="50px"*/}
                {/*        borderRadius="md"*/}
                {/*        mb={2}*/}
                {/*        isLoading={loading}*/}
                {/*        onClick={() => handleSubmit()}*/}
                {/*    >Fetch</Button>}*/}
                {/*</Box>*/}
                <Box>
                    {ids.length > 0 && ids.map(id =>
                        <Badge ml='1' fontSize='0.8em' colorScheme={id.error ? 'red' : 'green'}>
                            {id.id}
                        </Badge>)}
                </Box>
                <Box>
                    <VideoUploadResult
                        videos={result}
                        isLoading={loading}
                        type={type}
                        uploadedVideos={uploadedVideos}
                        setUploadedVideos={setUploadedVideos}
                    />
                </Box>
            </VStack>
        </>
    );
};

export default VideoUpload;

import React from 'react'
import {Box, Flex, Heading,} from '@chakra-ui/react'
import LoginForm from "../../components/login/LoginForm";

const VARIANT_COLOR = 'teal'

function Login() {
    return (
        <Flex mt={4} width='full' align='center' justifyContent='center'>
            <Box
                borderWidth={1}
                px={4}
                width='full'
                maxWidth='500px'
                borderRadius={4}
                textAlign='center'
                boxShadow='lg'
            >
                <Box p={4}>
                    <Box textAlign='center'>
                        <Heading>Sign In to Your Account</Heading>
                    </Box>
                    <LoginForm
                        color={VARIANT_COLOR}
                    />
                </Box>
            </Box>
        </Flex>
    )
}

export default Login




import {AiOutlineArrowUp, AiOutlineArrowDown} from "react-icons/ai";
import {HStack, Text} from "@chakra-ui/react";
import React from "react";

const SortComponent = ({setData, field}) => {
    const [asc, setAsc] = React.useState(false);

    const handleClick = () => {
        setAsc(!asc);
        setData(field, asc);
    }
    return (
        <>
            <HStack onClick={handleClick}>
                <Text>{field}</Text>
                {asc && <AiOutlineArrowUp/>}
                {!asc && <AiOutlineArrowDown/>}
            </HStack>
        </>
    )
}

export default SortComponent
import React, {useState} from "react";
import {Box, Button, Checkbox, CircularProgress, Flex, Input, Stack, Text} from "@chakra-ui/react";
import {produce} from "immer";
import SelectCommon from "../../components/custom/common/SelectCommon";
import {s3Config} from "../../config/aws";
import S3 from 'react-aws-s3';
import axios from "axios";

//@ts-ignore
window.Buffer = window.Buffer || require("buffer").Buffer;

const PANCHANG = "panchang";
const MANTRA = "mantra";
const DOHA = "doha";
const SUVICHAR = "suvichar";


const curMonth = new Date().toLocaleString('default', {month: 'long'});

const UploadSocials: React.FC = () => {
    const [socialType, setSocialType] = useState<string>(PANCHANG);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [imgURLS, setImgURLS] = useState<Array<{
        url: string,
        name: string,
        data: any,
        selected: boolean,
        uploaded: boolean,
        uploadURL: string,
        uploading: boolean,
        error: boolean,
        activeOn: Date,
    }>>(null)
    const [imgResponse, setImgResponse] = useState(null)

    const [picturesUploaded, setPicturesUploaded] = useState(false);
    const [picturesUploading, setPicturesUploading] = useState(false);

    const onFileChange = (e) => {
        const urls = []
        for (const key in Object.keys(e.target.files)) {
            const filename = e.target.files[key].name;
            // const extension = filename.split('.').pop();
            // const randomNumber = Math.floor((Math.random() * 10000) + 1)
            // const timestamp = new Date().getTime();
            // const filename_ = timestamp + randomNumber + '.' + extension;
            const url = URL.createObjectURL(e.target.files[key])
            const dt_ = filename.split('_');
            const dt = dt_[dt_.length - 1].split('.')[0];
            const date = socialType === PANCHANG ? new Date(dt) : null;
            //get date in format 29-10-2022
            const date_ = socialType === PANCHANG ? `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}` : null;
            urls.push({
                name: filename,
                url: url,
                selected: false,
                data: e.target.files[key],
                uploaded: false,
                uploadURL: null,
                uploading: false,
                //@ts-ignore
                error: socialType === PANCHANG && date == 'Invalid Date',
                activeOn: date_
            })
        }
        setImgURLS(urls)
    }

    const uploadPicturesToS3 = async () => {
        setPicturesUploading(true)
        try {
            //Get current month name
            const dirname = `posts/${socialType}/${curMonth}`;

            const ReactS3Client = new S3({
                ...s3Config,
                dirName: dirname
            });

            //Mark all images as uploading
            setImgURLS(produce(imgURLS, draft => {
                draft.forEach((img) => {
                    if (img.selected) {
                        img.uploading = true
                    }
                })
            }))

            const newImgURLS = JSON.parse(JSON.stringify(imgURLS))

            for (const image of imgURLS) {
                if (image.selected) {
                    ReactS3Client
                        .uploadFile(image['data'], image['name'])
                        .then(data => {
                            const index = newImgURLS.findIndex((img) => img.name === image.name);
                            if (data.status === 204) {
                                newImgURLS[index].uploadURL = data.location;
                                newImgURLS[index].uploaded = true;
                                newImgURLS[index].uploading = false;

                            } else {
                                setErrorMessage("Error uploading image")
                                newImgURLS[index].error = true;
                            }
                            setImgURLS([...newImgURLS])
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            }
            setPicturesUploading(false)
            setPicturesUploaded(true)
        } catch (err) {
            console.log(err)
            setPicturesUploading(false)
        }
    }

    const onSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault()

        const apiObject = {
            social_type: socialType,
            data: imgURLS.map(img => {
                return {
                    active_on: socialType === PANCHANG ? img.activeOn : null,
                    url: img.uploadURL
                }
            })
        }

        await axios.post(`${process.env.REACT_APP_IMAGE_PROCESS_API}/socials/upload-socials`, apiObject).then(res => {
            console.log(res)
            if (res.data.status) {
                setImgResponse(res.data?.data)
                setImgURLS(null)
            } else {
                setErrorMessage("Error")
            }
        }).catch(err => {
            console.log(err)
            console.log(err.message)
        })
        setIsLoading(false)
    }

    const onSelect = (item, index) => {
        setImgURLS(produce(imgURLS, draft => {
            draft[index].selected = !draft[index].selected
            console.log(draft[index].selected, !draft[index].selected)
        }))
    }

    const onSelectAll = (e) => {
        const checked = e.target.checked
        setImgURLS(produce(imgURLS, draft => {
            draft.map(item => {

                item.selected = checked

                return item
            })
        }))
    }

    const removeItem = (index) => {
        setImgURLS(produce(imgURLS, draft => {
            draft.splice(index, 1)
        }))
    }

    return (
        <>
            <Box
                flexDirection={"column"}
                justifyContent={"flex-start"}
            >
                <Flex
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Flex
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box>
                            <Text>Choose Images to Upload</Text>
                            <Input
                                name="files"
                                type="file"
                                onChange={onFileChange}
                                multiple
                                my={4}
                                w={"80%"}
                            />
                        </Box>

                        <Box>
                            <Text>Select Social Type</Text>
                            <SelectCommon
                                setValue={setSocialType}
                                value={socialType}
                                options={[{value: PANCHANG, label: "Panchang"}, {
                                    value: MANTRA,
                                    label: "Mantra"
                                }, {value: SUVICHAR, label: "Suvichar"}, {value: DOHA, label: "Doha"}]}
                            />
                        </Box>
                    </Flex>

                    <Box>
                        <Button
                            onClick={uploadPicturesToS3}
                            isLoading={picturesUploading}
                            colorScheme={"green"}
                            my={4}
                            mr={2}
                            isDisabled={picturesUploaded}
                        >Upload Pictures</Button>
                        <Button
                            onClick={onSubmit}
                            isLoading={isLoading}
                            colorScheme={"green"}
                            isDisabled={picturesUploaded == false}
                            my={4}
                        >Save</Button>
                    </Box>
                </Flex>
                <Box
                    flexDirection={"row"}
                    justifyContent={"flex-start"}
                >
                    {/*@ts-ignore*/}
                    <Flex
                        flexDirection={"row"}
                        justifyContent={"flex-end"}
                        mb={4}
                        mr={4}
                    >{imgURLS &&
                        <Checkbox
                            isChecked={imgURLS.filter(item => item != undefined).every((item) => item.selected === true)}
                            onChange={onSelectAll}
                            isDisabled={picturesUploaded}
                        />}
                    </Flex>
                    {imgURLS && (imgURLS.length > 0) && imgURLS.map((item, index) => {
                        return <Flex
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignContent={"center"}
                            alignItems={"center"}
                            key={index}
                            style={{
                                marginTop: 6,
                                padding: 6,
                                borderRadius: 12,
                                backgroundColor: item.uploaded ? "#2f6f22" : item.error ? "#9b2a2a" : "#2D3649",
                            }}
                        >
                            <Stack pl={2} direction={"row"} alignItems={"center"}>
                                <Button onClick={() => removeItem(index)}>X</Button>
                                <Text>
                                    Upload Destination
                                    : {`posts/${socialType}/${curMonth}/`}{item.name}
                                </Text>
                            </Stack>

                            {socialType === PANCHANG &&
                                <Flex
                                    flexDirection={"row"}
                                    alignItems={"center"}
                                >
                                    <Text>{new Date(item.activeOn).toDateString()}</Text>
                                </Flex>
                            }

                            <Flex
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >

                                <Box>
                                    <img src={item.url} style={{
                                        height: 50,
                                        marginTop: 6,
                                        marginRight: 10
                                    }}/>
                                </Box>
                                <Box
                                    mx={2}
                                >
                                    {item.uploading && <CircularProgress isIndeterminate size={8}/>}
                                </Box>
                                <Checkbox
                                    mr={2}
                                    isChecked={item.selected}
                                    onChange={() => onSelect(item, index)}
                                    isDisabled={item.error ? true : (item.uploaded || item.uploading)}
                                />
                            </Flex>
                        </Flex>
                    })}
                </Box>
                <Box>
                    {imgResponse && imgResponse.length > 0 && imgResponse.map(res => {
                        if (!res) {
                            return <Flex>
                                <Text color={'red'}>Something went wrong</Text>
                            </Flex>
                        }
                        return <Flex
                            flexDirection={"row"}
                            justifyContent={"space-between"}
                            alignContent={"center"}
                            alignItems={"center"}
                            style={{
                                marginTop: 6,
                                border: "1px solid green",
                                padding: 6,
                                borderRadius: 12,
                            }}
                        >
                            <Box><Text>ID : {res.id}</Text></Box>

                            <Box><img src={res.image_url} style={{
                                height: 50,
                                marginTop: 6,
                                marginRight: 10
                            }}/></Box>

                        </Flex>
                    })}
                </Box>
            </Box>
        </>
    )
}

export default UploadSocials;
import axios from 'axios';

let BASE_URL = process.env["REACT_APP_MODE"] == "development" ? process.env["REACT_APP_BASE_URL_DEV"] : process.env["REACT_APP_BASE_URL_PROD"];

let axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'MODE': process.env.MODE,
        'client': 'admin'
    },
    withCredentials: true
})

let axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        'MODE': process.env.MODE,
        'client': 'admin'
    },
    withCredentials: true
})
export {axiosInstance as default, axiosPrivate}

import React from "react";
import {useQuery} from "react-query";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Loader from "../../components/common/Loader";
import toast from "react-hot-toast";
import ViewAllDeeplinks from "../../components/deeplink-manager/ViewAllDeeplinks";
import {Box, Button, HStack} from "@chakra-ui/react";
import {VscAdd} from "react-icons/vsc";
import {useNavigate} from "react-router-dom";
import CreateInternalLinkModal from "../../components/deeplink-manager/CreateInternalLinkModal";
import GetClickDataByUTMParams from "../../components/deeplink-manager/GetClickDataByUTMParams";

const DeeplinkManager: React.FC = () => {
    const axiosPrivate = useAxiosPrivate();
    const {data, error, isLoading} = useQuery("deeplinks", async function () {
        try {
            return ((await axiosPrivate.get(`/analytics/deeplink/getall`)).data.data);
        } catch (err) {
            console.log(err.response);
            toast.error("Error fetching deeplinks", err.response);
            throw err
        }
    })

    const navigate = useNavigate();

    return (
        <>
            <Box
                w={"100%"}
            >
                {isLoading && <Loader/>}
                {error && <div>{JSON.stringify(error)}</div>}
                {data &&
                    <>
                        <HStack
                            w={"100%"}
                            justifyContent={"end"}
                            mb={4}
                        >
                            <CreateInternalLinkModal/>
                            <GetClickDataByUTMParams/>
                            <Button
                                leftIcon={<VscAdd size={20}/>}
                                borderRadius={8}
                                p={3}
                                colorScheme={'green'}
                                onClick={() => {
                                    navigate('/deeplink-manager/create')
                                }}
                            >Create Deeplink</Button>
                        </HStack>
                        <ViewAllDeeplinks data={data}/>
                    </>
                }
            </Box>
        </>
    )
}


export default DeeplinkManager;
import {Box, Flex, Heading} from "@chakra-ui/react";
import React from "react";
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import ResetPasswordForm from "../../components/auth/ResetPasswordForm";

const ResetPassword = () => {
    const {id} = useParams();
    const [params] = useSearchParams();
    const [error, setError] = React.useState(null);
    React.useEffect(() => {
        if (!id) {
            setError('Invalid URL');
        }
    }, [])

    return (
        <Flex mt={4} width='full' align='center' justifyContent='center'>
            <Box
                borderWidth={1}
                px={4}
                width='full'
                maxWidth='500px'
                borderRadius={4}
                textAlign='center'
                boxShadow='lg'
            >
                <Box p={4}>
                    <Box textAlign='center'>
                        <Heading>Reset Password</Heading>
                    </Box>
                    <ResetPasswordForm id={id} token={params.get('token')}/>
                </Box>

                {error && <Box p={4}>
                    <Heading>{error}</Heading>
                </Box>}
            </Box>
        </Flex>
    )
}

export default ResetPassword

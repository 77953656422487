import React from 'react';
import {produce} from "immer";
import TableBody from "./table-body";
import {PAGES_PAGE, PLAYLIST_PAGE} from "../../types/Strings";
import SearchModal from "../custom/SearchModal";
import Loader from "../common/Loader";

interface TableProps {
    data: any[];
    isFetching: boolean;
    error?: string;
    setData?: (data: any[]) => void;
    source: string;
}

const PageTableComponent: React.FC<TableProps> = (props) => {
    const {data, isFetching, source} = props;
    const [copyData, setCopyData] = React.useState(data);
    const [modules, setModules] = React.useState<any[]>([]);
    const [pageNumber, setPageNumber] = React.useState<number>(0);
    const [perPage, setPerPage] = React.useState<number>(10);
    const [search, setSearch] = React.useState<string>("");
    const [searchParam, setSearchParam] = React.useState<string>("title");
    //Search modal
    const [searchModalOpen, setSearchModalOpen] = React.useState<boolean>(false);
    const [filterDetails, setFilterDetails] = React.useState<any>({});
    const totalModules = data.length;

    React.useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.code === 'KeyX') {
                setSearchModalOpen(!searchModalOpen);
                setSearch("");
            }
        });
        setModules(copyData.slice(pageNumber * perPage, pageNumber * perPage + perPage));
    }, [pageNumber, copyData, perPage, data]);


    const handlePaginationNext = () => {
        if (search.length === 0 && (totalModules / perPage > pageNumber + 1)) {
            setPageNumber(pageNumber + 1);
        }
    }

    const handlePaginationPrevious = () => {
        if (pageNumber > 0 && search.length === 0) {
            setPageNumber(pageNumber - 1);
        }
    }

    const handleGoToPage = (page: number) => {
        if (page >= 0 && page < Math.ceil(totalModules / perPage)) {
            setPageNumber(page);
        }
    }

    const sort = (field, asc) => {
        setCopyData(produce(copyData, draft => {
            draft.sort((a, b) => {
                if (asc) {
                    return a[field] - b[field];
                } else {
                    return b[field] - a[field];
                }
            })
        }))
    }

    const handleSorting = (field, asc) => {
        if (field === 'ID') {
            if (source === PLAYLIST_PAGE || source === PAGES_PAGE) {
                sort('id', asc);
            } else {
                sort('module_id', asc);
            }
        } else if (field === 'Title') {
            setCopyData(produce(copyData, draft => {
                draft.sort((a, b) => {
                    if (asc) {
                        return a.title.localeCompare(b.title);
                    } else {
                        return b.title.localeCompare(a.title);
                    }
                })
            }))

        } else if (field === 'Position') {
            sort('module_position', asc);
        } else if (field === 'Module Status') {
            sort('is_active', asc);
        } else if (field === 'Page Status') {
            sort('page_is_active', asc);
        } else if (field === 'No. Of Entities') {
            setCopyData(produce(copyData, draft => {
                draft.sort((a, b) => {
                    if (asc) {
                        return a.entity_list.length - b.entity_list.length;
                    } else {
                        return b.entity_list.length - a.entity_list.length;
                    }
                })
            }))
        }
    }

    const filteredData = React.useMemo(() => {
        if (Object.keys(filterDetails).length > 0) {
            const status = filterDetails.status === 1;
            const createdByID = filterDetails.createdBy;
            return copyData.filter(item => {
                return item.is_active === status;
            })
        }


        if (search === "") {
            return copyData;
        } else if (search !== "" && searchParam === "title") {
            return copyData.filter(item => {
                return item.title.toLowerCase().includes(search.toLowerCase());
            })
        } else if (search !== "" && searchParam === "id") {
            return copyData.filter(item => {
                if (item?.id === parseInt(search)) {
                    return item;
                } else if (item?.module_id === parseInt(search)) {
                    return item;
                }
            })
        }

    }, [search, copyData, pageNumber, perPage, data, filterDetails]);


    return (
        <>
            {isFetching && <Loader/>}
            <SearchModal
                open={searchModalOpen}
                setOpen={setSearchModalOpen}
                search={search}
                setSearch={setSearch}
                searchParam={searchParam}
                setSearchParam={setSearchParam}
            />
            <TableBody
                perPage={perPage}
                pageNumber={pageNumber}
                modules={(search.length !== 0 || Object.keys(filterDetails).length > 0) ? filteredData : modules}
                setPerPage={setPerPage}
                handlePaginationNext={handlePaginationNext}
                handleSorting={handleSorting}
                handlePaginationPrevious={handlePaginationPrevious}
                totalModules={totalModules}
                isFetching={isFetching}
                goToPage={handleGoToPage}
                source={source}
                getFilterDetails={(filterDetails) => {
                    setFilterDetails(filterDetails)
                }}
                setOpenSearchModal={setSearchModalOpen}
            />
        </>
    )
}

export default PageTableComponent;

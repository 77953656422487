import React from "react";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Flex,
    HStack,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    VStack
} from "@chakra-ui/react";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {ColorModeSwitcher} from "../common/ColorModeSwitcher";
import useAuth from '../../hooks/useAuth'
import AppIcon from '../../assets/image/app-icon2.png'
import UserAccessLevel from "../common/UserAccessLevel";
import useLogout from "../../hooks/useLogout";
import NotificationPanel from "../notifications/NotificationPanel";
import {GiHamburgerMenu} from "react-icons/gi";

interface Props {
    isSideBarOpen: boolean;
    toggleSideBar: (e: boolean) => void;
}

const NavBar: React.FC<Props> = ({isSideBarOpen, toggleSideBar}) => {
    const navigate = useNavigate();
    const [isMouseOver, setIsMouseOver] = React.useState(false);
    let body = null;
    const {auth} = useAuth();
    const logout = useLogout();
    const authenticated = !!auth?.user;
    if (authenticated === false) {
        body = (
            <>
                <ColorModeSwitcher/>
            </>
        );
    } else {
        body = (
            <Flex align="center">
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{boxShadow: 'none'}}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                    src={auth?.user?.image_url}
                                />
                                <VStack
                                    display={{base: 'none', md: 'flex'}}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">{auth?.user?.name}</Text>
                                    <Text fontSize="xs">
                                        <UserAccessLevel role={auth?.user?.access_level}/>
                                    </Text>

                                </VStack>
                                <Box display={{base: 'none', md: 'flex'}}>
                                    {/*<FiChevronDown/>*/}
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                        >
                            <MenuItem
                                onClick={() => navigate("/profile")}
                            >Profile</MenuItem>
                            <MenuItem>Settings</MenuItem>
                            <MenuItem>Billing</MenuItem>
                            <MenuDivider/>
                            <MenuItem>
                                <Button onClick={async () => {
                                    await logout();
                                    toast.success("Logged out successfully")
                                    window.location.reload();
                                }}>
                                    Logout
                                </Button></MenuItem>
                        </MenuList>
                        <ColorModeSwitcher mr={2}/>
                        {/*<NotificationPanel/>*/}
                    </Menu>
                </Flex>
            </Flex>
        );
    }

    return (
        <Flex
            zIndex={6}
            position="fixed"
            top={0}
            left={0}
            right={0}
            p={4}
            bg={useColorModeValue('white', 'gray.900')}
            color={useColorModeValue('gray.900', 'white')}
        >
            <Flex
                flex={1}
                m="auto"
                align="center"
            >
                <GiHamburgerMenu
                    size={30}
                    onClick={() => toggleSideBar(!isSideBarOpen)}
                    onMouseOver={() => {
                        setIsMouseOver(true)
                    }}
                    onMouseLeave={() => {
                        setIsMouseOver(false)
                    }}
                    style={{
                        cursor: 'pointer',
                        opacity: isMouseOver ? 1 : 0.5,
                        transform: isMouseOver ? 'scale(1.2)' : 'scale(1)',
                    }}
                />
                <Box ml={4}>
                    <img src={AppIcon} alt="logo" width={40} style={{cursor: 'pointer'}}
                         onClick={() => navigate('/')}/>
                </Box>

                {
                    process.env["REACT_APP_MODE"] === "development" &&
                    <Box ml={4}>
                        <Badge variant='solid' colorScheme='green'>
                            Development Mode
                        </Badge>
                    </Box>
                }

                {/*<Box ml={4}>*/}
                {/*    <Button*/}
                {/*        onClick={() => navigate('/')}*/}
                {/*        variant="ghost"*/}
                {/*        colorScheme="teal"*/}
                {/*        size="sm"*/}
                {/*    >*/}
                {/*        Home*/}
                {/*    </Button>*/}
                {/*</Box>*/}

                {/*Dashboard*/}
                <Text
                    ml={4}
                    fontSize="md"
                    color="gray.500"
                    onClick={() => navigate('/')}
                    style={{cursor: 'pointer'}}
                >
                    Dashboard
                </Text>

                <Text
                    ml={2}
                    fontSize="md"
                    color="gray.500"

                >
                    v{process.env["REACT_APP_VERSION"]}
                </Text>


                <Box ml={"auto"} mr={4}>{body}</Box>
            </Flex>
        </Flex>
    );
};

export default NavBar;

import React, {useEffect} from "react";
import toast from "react-hot-toast";
import {Form, Formik} from "formik";
import InputField from "../custom/InputField";
import {
    Box,
    Button, Collapse,
    FormControl,
    FormErrorMessage,
    FormLabel, Heading, Image,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    Text,
    Textarea
} from "@chakra-ui/react";
import * as yup from "yup";
import SelectField, {SelectFieldWithImage} from "../custom/SelectField";
import {useNavigate} from "react-router-dom";
import UploadImageComponent from "../common/UploadImage";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import LockAccess from "../../utils/LockAccess";
import {ADMIN, EDITOR} from "../../types/Strings";
import useAuth from "../../hooks/useAuth";
import {SecondsToDuration} from "../../utils/Time";
import {PlaylistVideoOrderTypes} from "../../config/Options";
import EntityPresentInModuleModal from "../entities/EntityPresentInModuleModal";
import {suggestCardTypeBasedOnImageDimensions} from "../../utils/SuggestCardType";
import StatusIndicator from "../common/StatusIndicator";
import {GlobalFormStyles} from "../../styles/FormStyles";

interface Props {
    data: any;
    isNew: boolean;
}

const PlaylistMetaEdit: React.FC<Props> = ({data, isNew}) => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();

    const [creators, setCreators] = React.useState([]);

    useEffect(() => {
        async function fetchCreators() {
            const {data} = await axiosPrivate.get('/creator/all');
            setCreators(data.data);
        }

        fetchCreators();
    }, [])

    const validationSchema = yup.object({
        name: yup.string().required("Title is required"),
        image_url: yup.string(),
        description: yup.string(),
        creator_name: yup.string(),
        is_paid: yup.boolean(),
        is_exclusive: yup.boolean(),
        price: yup.number(),
        unlock_status: yup.boolean(),
        difficulty: yup.string(),
        partner: yup.string(),
        ratings: yup.string(),
        views: yup.number(),
        likes: yup.number(),
        trending_score: yup.number(),
        limit_video_view_count: yup.boolean(),
        hash_tags: yup.string(),
        card_chip_text: yup.string(),
        video_view_count: yup.number().when('limit_video_view_count', {
            is: true,
            then: yup.number().required('Video View Count is a required field')
        }),
        video_order: yup.string().when('limit_video_view_count', {
            is: true,
            then: yup.string().matches(/^random|default$/).required()
        }),
    });

    const initialValues = isNew ? {
        name: "",
        image_url: " ",
        description: "",
        creator_name: "",
        creator_id: null,
        is_paid: false,
        is_exclusive: true,
        price: 0,
        unlock_status: false,
        difficulty: "",
        partner: "",
        is_active: true,
        id: '',
        entity_id: '',
        trending_score: 0,
        limit_video_view_count: false,
        video_view_count: 0,
        video_order: 'default',
        hash_tags: '',
        card_chip_text: '',
        module_images: []
    } : {
        name: data.title,
        id: data.id,
        entity_id: data.entity_id,
        image_url: data?.image_url,
        description: data.description,
        creator_name: data.creator_name,
        creator_id: data.creator_id,
        is_paid: data.is_paid,
        is_exclusive: data.is_exclusive == null ? false : data.is_exclusive,
        price: data.price,
        unlock_status: data.unlock_status,
        difficulty: data.difficulty,
        partner: data.partner,
        is_active: data?.is_active,
        trending_score: data?.trending_score,
        limit_video_view_count: data?.limit_video_view_count,
        video_view_count: data?.video_view_count,
        video_order: data?.video_order,
        hash_tags: data?.hash_tags,
        card_chip_text: data?.card_chip_text,
        module_images: data?.extra?.module_images
    }

    const {auth} = useAuth();

    return (
        <>
            <SimpleGrid columns={2} spacing={4}>
                <Box
                    my={8}
                    textAlign='left'
                    opacity={LockAccess(auth, [ADMIN, EDITOR]).opacity}
                    style={{...LockAccess(auth, [ADMIN, EDITOR]).style as any}}
                >
                    <Text
                        as={'h3'}
                        fontSize={'lg'}
                        mb={4}
                        color={'#4994d7'}
                    >Total Playlist Duration - {SecondsToDuration(data?.playlist_duration)}</Text>

                    <Formik
                        enableReinitialize={true}
                        validateOnChange={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (data, {setSubmitting, setValues}) => {
                            setSubmitting(true);

                            try {
                                if (isNew) {
                                    const res = await axiosPrivate.put('/playlist', {
                                        name: data.name,
                                        image_url: data.image_url,
                                        description: data.description,
                                        is_paid: data.is_paid,
                                        exclusive: data.is_exclusive,
                                        price: data.price,
                                        difficulty: data.difficulty,
                                        partner: data.partner,
                                        is_active: data.is_active,
                                        trending_score: data.trending_score,
                                        limit_video_view_count: data.limit_video_view_count,
                                        video_view_count: data.video_view_count,
                                        video_order: data.video_order,
                                        videos: [],
                                        hash_tags: data.hash_tags,
                                        card_chip_text: data.card_chip_text,
                                        creator_id: data.creator_id,
                                    })
                                    if (res.status === 200) {
                                        if (res.data?.status === 200) {
                                            toast.success(res.data.message)
                                            setValues({
                                                ...data,
                                                id: res.data.data.master_id,
                                                entity_id: res.data.data.entity_id,
                                            })
                                            navigate('/playlist-edit/' + res.data.data.master_id, {replace: true})
                                        } else {
                                            toast.error(res.data.message)
                                        }
                                    } else {
                                        toast.error("Something went wrong")
                                    }
                                } else {
                                    const res = await axiosPrivate.patch('/playlist', {
                                        id: data.id,
                                        name: data.name,
                                        image_url: data.image_url,
                                        description: data.description,
                                        is_paid: data.is_paid,
                                        exclusive: data.is_exclusive,
                                        price: data.price,
                                        trending_score: data.trending_score,
                                        difficulty: data.difficulty,
                                        partner: data.partner,
                                        is_active: data.is_active,
                                        limit_video_view_count: data.limit_video_view_count,
                                        video_view_count: data.video_view_count,
                                        video_order: data.video_order,
                                        hash_tags: data.hash_tags,
                                        card_chip_text: data.card_chip_text,
                                        creator_id: data.creator_id,
                                        videos: {
                                            add: [],
                                            update: []
                                        },
                                        module_images: data.module_images.map((item: any) => {
                                            return {
                                                module_id: item.module_id,
                                                image_url: item.image_url
                                            }
                                        })
                                    })
                                    if (res.status === 200) {
                                        if (res.data?.status === 200) {
                                            toast.success(res.data.message)
                                        } else {
                                            toast.error(res.data.message)
                                        }
                                    } else {
                                        toast.error("Something went wrong")
                                    }
                                }
                            } catch (err) {
                                console.error(err)
                                toast.error(err.response.data.message)
                                return;
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({values, isSubmitting, setValues, errors}) => (
                            <Form
                                style={{
                                    ...GlobalFormStyles,
                                    width: '60%',
                                }}
                            >
                                <Box
                                    overflow="hidden"
                                    mb={2}
                                >
                                    <Text
                                        fontSize={16}
                                        mb={2}
                                        color={'gray.200'}
                                    >
                                        Master ID : {isNew ? 'NA' : values.id}
                                        <br/>
                                        Entity ID : {isNew ? 'NA' : values.entity_id}
                                    </Text>
                                </Box>

                                <InputField
                                    placeholder={"Name"}
                                    name={"name"}
                                    value={values.name}
                                />

                                <FormControl isInvalid={!!errors.is_active}>
                                    <FormLabel>Is Active</FormLabel>
                                    <RadioGroup
                                        onChange={(e) => {
                                            setValues({...values, is_active: e === "true"})
                                        }}
                                        value={values.is_active.toString()}
                                    >
                                        <Stack direction={"row"}>
                                            {
                                                [{value: "true", label: "Active"}, {
                                                    value: "false",
                                                    label: "Inactive"
                                                }]?.map((option, index) => (
                                                    <Radio
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                ))
                                            }
                                        </Stack>
                                    </RadioGroup>
                                    {errors.is_active && <FormErrorMessage>{errors.is_active}</FormErrorMessage>}
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Description</FormLabel>
                                    <Textarea
                                        placeholder={"Description"}
                                        name={"description"}
                                        value={values.description}
                                        onChange={(e) => setValues({...values, description: e.target.value})}
                                    />
                                </FormControl>

                                <FormControl isInvalid={!!errors.trending_score}>
                                    <FormLabel>Trending Score</FormLabel>
                                    <NumberInput
                                                 defaultValue={0}
                                                 min={0}
                                                 max={100}
                                                 onChange={(e) => setValues({...values, trending_score: e})}
                                                 value={values.trending_score}
                                    >
                                        <NumberInputField/>
                                        <NumberInputStepper>
                                            <NumberIncrementStepper/>
                                            <NumberDecrementStepper/>
                                        </NumberInputStepper>
                                    </NumberInput>
                                    {errors.trending_score &&
                                        <FormErrorMessage>{errors.trending_score}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={!!errors.limit_video_view_count}>
                                    <FormLabel>Limit Video View Count</FormLabel>
                                    <RadioGroup
                                        onChange={(e) => {
                                            setValues({...values, limit_video_view_count: e === "true"})
                                        }}
                                        value={values.limit_video_view_count.toString()}
                                    >
                                        <Stack direction={"row"}>
                                            {
                                                [{value: "true", label: "Yes"}, {value: "false", label: "No"}]
                                                    ?.map((option, index) => (
                                                        <Radio
                                                            key={index}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </Radio>
                                                    ))
                                            }
                                        </Stack>
                                    </RadioGroup>

                                    {errors.limit_video_view_count &&
                                        <FormErrorMessage>{errors.limit_video_view_count}</FormErrorMessage>}
                                </FormControl>

                                {values.limit_video_view_count && <FormControl isInvalid={!!errors.video_view_count}>
                                    <FormLabel>Video View Count</FormLabel>
                                    <NumberInput
                                        name={"video_view_count"}
                                        value={values.video_view_count}
                                        onChange={(e) => {
                                            setValues({...values, video_view_count: parseInt(e)})
                                        }}
                                        defaultValue={0}
                                        min={0}
                                    >
                                        <NumberInputField/>
                                        <NumberInputStepper>
                                            <NumberIncrementStepper/>
                                            <NumberDecrementStepper/>
                                        </NumberInputStepper>
                                    </NumberInput>
                                    {errors.video_view_count &&
                                        <FormErrorMessage>{errors.video_view_count}</FormErrorMessage>}
                                </FormControl>}

                                <SelectField
                                    placeholder={"Select Video Order"}
                                    name={"video_order"}
                                    options={PlaylistVideoOrderTypes}
                                />

                                <SelectFieldWithImage
                                    placeholder={"Select Creator"}
                                    name={"creator_id"}
                                    setValue={(e) => {
                                        setValues({...values, creator_id: e})
                                    }}
                                    value={values.creator_id}
                                    options={creators.map(item => {
                                        return {
                                            value: item.id,
                                            label: item.title,
                                            image_url: item.image_url
                                        }
                                    })}
                                />

                                <FormControl isInvalid={!!errors.is_paid}>
                                    <FormLabel>Is Paid</FormLabel>
                                    <RadioGroup
                                        isDisabled={true}
                                        onChange={(e) => {
                                            setValues({...values, is_paid: e === "true"})
                                        }}
                                        value={values.is_paid.toString()}
                                    >
                                        <Stack direction={"row"}>
                                            {
                                                [{value: "true", label: "Active"}, {
                                                    value: "false",
                                                    label: "Inactive"
                                                }]?.map((option, index) => (
                                                    <Radio
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                ))
                                            }
                                        </Stack>

                                    </RadioGroup>
                                    {errors.is_paid && <FormErrorMessage>{errors.is_paid}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={!!errors.is_exclusive}>
                                    <FormLabel>Is Exclusive</FormLabel>
                                    <RadioGroup
                                        onChange={(e) => {
                                            setValues({...values, is_exclusive: e === "true"})
                                        }}
                                        value={values.is_exclusive.toString()}
                                    >
                                        <Stack direction={"row"}>
                                            {
                                                [{value: "true", label: "Yes"}, {
                                                    value: "false",
                                                    label: "No"
                                                }]?.map((option, index) => (
                                                    <Radio
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                ))
                                            }
                                        </Stack>

                                    </RadioGroup>
                                    {errors.is_exclusive && <FormErrorMessage>{errors.is_exclusive}</FormErrorMessage>}
                                </FormControl>

                                <InputField
                                    disabled={true}
                                    placeholder={"Price"}
                                    name={"price"}
                                    value={values.price}
                                />

                                <FormControl isInvalid={!!errors.unlock_status}>
                                    <FormLabel>Unlock Status</FormLabel>
                                    <RadioGroup
                                        isDisabled={true}
                                        onChange={(e) => {
                                            setValues({...values, unlock_status: e === "true"})
                                        }}
                                        value={values.unlock_status.toString()}
                                    >
                                        <Stack direction={"row"}>
                                            {
                                                [{value: "true", label: "Active"}, {
                                                    value: "false",
                                                    label: "Inactive"
                                                }]?.map((option, index) => (
                                                    <Radio
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                ))
                                            }
                                        </Stack>
                                    </RadioGroup>
                                    {errors.unlock_status &&
                                        <FormErrorMessage>{errors.unlock_status}</FormErrorMessage>}
                                </FormControl>

                                <SelectField
                                    placeholder={"Difficulty"}
                                    name={"difficulty"}
                                    options={[
                                        {label: "Easy", value: "easy"},
                                        {label: "Medium", value: "medium"},
                                        {label: "Hard", value: "hard"}
                                    ]}
                                />

                                <InputField
                                    placeholder={"Hash Tags (Comma Seperated)"}
                                    name={"hash_tags"}
                                    value={values.hash_tags}
                                />

                                <InputField
                                    placeholder={"Card Chip Text"}
                                    name={"card_chip_text"}
                                    value={values.card_chip_text}
                                />

                                <InputField
                                    placeholder={"Image URL"}
                                    name={"image_url"}
                                    value={values.image_url}
                                />

                                <UploadImageComponent
                                    setImageUrl={(imageUrl) => setValues({...values, image_url: imageUrl})}
                                    imageURL={values.image_url}
                                    getHeightAndWidth={(obj) => {
                                        console.log(suggestCardTypeBasedOnImageDimensions(obj))
                                    }}
                                    entityID={values.id}
                                />

                                {values?.module_images && values.module_images?.length > 0 && <Box
                                    mt={2}
                                >
                                    <Heading size={"md"} mt={4} mb={4}>Module Images</Heading>
                                    <Box
                                        flexDirection={"column"}
                                        gap={4}
                                        display={"flex"}
                                    >
                                        {values.module_images?.map((image, index) => (
                                            <ModuleImageC
                                                module_id={image.module_id}
                                                image_url={image.image_url}
                                                module_name={image.name}
                                                active_status={image.is_active}
                                                id={values.id}
                                                setValues={setValues}
                                                values={values}
                                            />
                                        ))}
                                    </Box>
                                </Box>}
                                <Button
                                    type={'submit'}
                                    isLoading={isSubmitting}
                                    colorScheme={'green'}
                                    mt={4}>Save</Button>
                            </Form>
                        )}
                    </Formik>
                </Box>
                <Box>
                    <EntityPresentInModuleModal id={data?.id}/>
                </Box>
            </SimpleGrid>
        </>
    )
}

interface ModuleImageCProps {
    module_id: number;
    image_url: string;
    module_name: string;
    active_status: boolean;

    id: number;

    setValues: (values: any) => void;

    values: any;
}

const ModuleImageC: React.FC<ModuleImageCProps> = ({
                                                       module_id,
                                                       image_url,
                                                       values,
                                                       id,
                                                       setValues,
                                                       module_name,
                                                       active_status
                                                   }) => {
    const [show, setShow] = React.useState(false);

    const handleToggle = () => setShow(!show);

    return (
        <>
            <Button
                onClick={handleToggle}
            >
                {module_name + " - " + module_id}
            </Button>
            <Collapse in={show}>
                <Box
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={2}
                    bg={"gray.700"}
                    p={4}
                    borderRadius={"md"}
                >
                    <Box
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        gap={2}
                        display={"flex"}
                        mb={2}
                    >
                        <Text
                            fontSize={"lg"}
                            fontWeight={"bold"}
                            color={"yellow"}
                        >{module_name}</Text>
                        <StatusIndicator is_active={active_status}/>
                        <Text
                            fontSize={"lg"}
                            fontWeight={"bold"}
                            color={"yellow"}
                        >Module ID : {module_id}</Text>
                    </Box>

                    <InputField
                        value={image_url}
                        name={"image_url"}
                        placeholder={"Image URL"}
                        onChange={(e) => {
                            const newModuleImages = values.module_images.map((image: any) => {
                                if (image.module_id === module_id) {
                                    return {...image, image_url: e.target.value}
                                }
                                return image;
                            })
                            setValues({...values, module_images: newModuleImages})
                        }}
                    />

                    <UploadImageComponent
                        setImageUrl={(url) => {
                            const newModuleImages = values.module_images.map((image: any) => {
                                if (image.module_id === module_id) {
                                    return {...image, image_url: url}
                                }
                                return image;
                            })
                            setValues({...values, module_images: newModuleImages})
                        }}
                        imageURL={image_url}
                        entityID={id + "-" + module_id}
                    />
                </Box>
            </Collapse>
        </>
    )
}

export default PlaylistMetaEdit;

import React from "react";
import {Button, Modal, ModalContent, ModalFooter, useDisclosure} from "@chakra-ui/react";
import {DateRangePicker} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export const DateRangePickerComponent = ({onChange, ranges}) => {
    const {isOpen, onOpen, onClose} = useDisclosure()
    return (
        <>
            <Button onClick={onOpen}>Select Date Range Picker</Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalContent
                >
                    <DateRangePicker
                        onChange={onChange}
                        ranges={ranges}
                        showSelectionPreview={true}
                    />
                </ModalContent>
            </Modal>
        </>
    )
}

import {createContext, useState} from "react";

interface AuthContentInterface {
    auth: { user?: any, roles?: any, token?: any },
    setAuth?: any,
    user?: any,
    roles?: any,
    token?: any,
    persist?:any;
    setPersist?:any
}

const initial = {
    auth: {
        user: undefined,
        roles: undefined,
        token: undefined
    },
}

const AuthContext = createContext<AuthContentInterface>(initial);

export const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({});
    const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || true);
    return (
        <AuthContext.Provider value={{auth, setAuth, persist, setPersist}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;

import React, {useEffect, useMemo} from 'react';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {Box, Heading, Table} from "@chakra-ui/react";
import {Tbody, Th, Thead, Tr} from "@chakra-ui/table";

const VideoUploadHistory = () => {
    const axiosPrivate = useAxiosPrivate();

    const [videos, setVideos] = React.useState<Array<{
        id: number,
        title: string,
        created_at: string,
        created_by: string
    }>>([]);


    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const data = await axiosPrivate.get('/analytics/history');
                if (data.status === 200) {
                    setVideos(data.data?.data);
                }
            } catch (e) {
                console.log(e);
            }
        }
        fetchVideos();
    }, [])

    return (
        <Box>
            <Heading size="md" mb={4}>Latest Videos Uploaded</Heading>
            <Table
                size="md"
            >
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Title</Th>
                        <Th>Uploaded On</Th>
                        <Th>Uploaded By</Th>
                    </Tr>
                </Thead>
                <Tbody

                >
                    {
                        videos.map(video => {
                            return (
                                <Tr key={video.id}>
                                    <Th>{video.id}</Th>
                                    <Th>{video.title}</Th>
                                    <Th>{new Date(video?.created_at)?.toDateString()}</Th>
                                    <Th>{video?.created_by}</Th>
                                </Tr>
                            )
                        })
                    }
                </Tbody>
            </Table>
        </Box>
    )

};

export default VideoUploadHistory;

import {Heading} from "@chakra-ui/react";

const TagManagerMain: React.FC = () => {
    return (
        <>
           <Heading as={'h1'} >Coming Soon!</Heading>
        </>
    )
}


export default TagManagerMain;
import React from 'react';
import {Box, Button} from "@chakra-ui/react";
import toaster from "react-hot-toast";

const RequestAccess = (props) => {

    const handleRequestAccess = () => {
        toaster.success("Request sent!");
    }

    return (
        <Box
        style = {{
            //In the center of the page
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }}
        >
            <Button
            colorScheme={'blue'}
            onClick={handleRequestAccess}
            >
                Request Access
            </Button>
        </Box>
    )
};

export default RequestAccess;
import useAuth from "./useAuth";
import axiosInstance from "../utils/Axios";

const useLogout = () => {
    //@ts-ignore
    const {setAuth, setPersist, auth} = useAuth();

    return async () => {
        setAuth(null);
        setPersist(false);
        try {
            await axiosInstance.get('/auth/logout');
            localStorage.removeItem('persist');
        } catch (e) {
            console.log(e);
        }
    };
}

export default useLogout;
import {
    CHANNEL,
    CHANNEL_HEADER,
    CIRCLE,
    COLLECTION_CHIP,
    CONTINUE_WATCHING_SQUARE,
    CREATOR,
    DEEP_LINK,
    DEFAULT,
    ENTITY,
    EXTERNAL_LINK,
    FEATURED_CAROUSEL_BANNER,
    FEATURED_LARGE_RECTANGLE,
    FEATURED_LARGE_SQUARE,
    FEATURED_PARALLAX_CAROUSEL_BANNER,
    FEATURED_VERTICAL_RECTANGLE,
    HEADER,
    HORIZONTAL_RECTANGLE,
    HORIZONTAL_SCROLL,
    HORIZONTAL_SCROLL_SIDE_TITLE,
    HORIZONTAL_SCROLL_TOP_TITLE,
    LARGE_RECTANGLE,
    LARGE_RECTANGLE_169,
    LARGE_RECTANGLE_RICH,
    LARGE_RECTANGLE_V2,
    LARGE_SQUARE,
    LARGE_SQUARE_V2,
    LINK,
    MANTRA,
    MANUAL,
    MEDIUM_RECTANGLE,
    MEDIUM_SQUARE,
    MEDIUM_VERTICAL_RECTANGLE,
    MEDIUM_VERTICAL_RECTANGLE_COLLECTION_CTA,
    MEDIUM_VERTICAL_RECTANGLE_COLLECTION_NO_CTA,
    MODULE,
    PAGE_END_QUOTE,
    PANCHANG,
    PLAYLIST,
    PROFILE,
    QUOTE,
    RANDOM,
    RECENTLY_PLAYED,
    SEARCH,
    SLIDER,
    SMALL_RECTANGLE,
    SMALL_SQUARE,
    SMALL_SQUARE_V2,
    STATEMENT_CARDS,
    STATEMENT_CARDS_CTA,
    SUBCHANNEL,
    SUVICHAR,
    THREE_BY_THREE_SCROLL,
    THREE_COLUMN_LAYOUT,
    THREE_COLUMN_VERTICAL_RECTANGLE,
    TRENDING_SCORE,
    TWO_COLUMN_LAYOUT,
    VERTICAL_RECTANGLE,
    VERTICAL_RECTANGLE_V2,
    VERTICAL_SCROLL_SINGLE_COLUMN,
    VIDEO,
    VIDEO_SQUARE
} from '../types/Strings';

const ScrollTypes = [
    {id: 1, value: HORIZONTAL_SCROLL, label: "HORIZONTAL SCROLL (Old)"},
    {id: 2, value: SLIDER, label: "SLIDER (Old)"},
    {id: 3, value: THREE_COLUMN_LAYOUT, label: "THREE COLUMN LAYOUT (Old)"},
    {id: 4, value: TWO_COLUMN_LAYOUT, label: "TWO COLUMN LAYOUT (Old)"},
    {id: 5, value: HEADER, label: "HEADER (Old)"},
    {id: 6, value: RECENTLY_PLAYED, label: "RECENTLY PLAYED (Old)"},
    {id: 7, value: VERTICAL_SCROLL_SINGLE_COLUMN, label: "VERTICAL SCROLL (Old)"},
    {id: 8, value: THREE_BY_THREE_SCROLL, label: "THREE ROW SCROLL (New)"},
    {id: 9, value: HORIZONTAL_SCROLL_SIDE_TITLE, label: "HORIZONTAL SCROLL (TITLE ON THE LEFT) (New)"},
    {id: 10, value: HORIZONTAL_SCROLL_TOP_TITLE, label: "HORIZONTAL SCROLL (TITLE ON TOP) (New)"},
    {id: 11, value: THREE_COLUMN_VERTICAL_RECTANGLE, label: "THREE COLUMN VERTICAL RECTANGLE LAYOUT (New)"},
    {id: 12, value: PAGE_END_QUOTE, label: "PAGE END QUOTE (New)"},
    {id: 13, value: CHANNEL_HEADER, label: "CHANNEL/SUB CHANNEL/CREATOR HEADER (New)"},
    {id: 14, value: FEATURED_CAROUSEL_BANNER, label: "FEATURED CAROUSEL BANNER"},
    {id: 15, value: FEATURED_PARALLAX_CAROUSEL_BANNER, label: "FEATURED PARALLAX CAROUSEL BANNER"},
]

const EntityRanking = [
    {id: 1, value: RANDOM, label: "Random"},
    {id: 2, value: TRENDING_SCORE, label: "Trending Score"},
    {id: 3, value: MANUAL, label: "Manual"}]

const CardLayout = [
    {id: 1, value: LARGE_RECTANGLE, label: "LARGE RECTANGLE (Old)"},
    {id: 11, value: LARGE_RECTANGLE_169, label: "LARGE RECTANGLE (16:9) (Old)"},
    {id: 12, value: LARGE_RECTANGLE_RICH, label: "LARGE RECTANGLE (RICH) (Old)"},
    {id: 11, value: VIDEO_SQUARE, label: "VIDEO SQUARE (Old)"},
    {id: 2, value: SMALL_SQUARE, label: "SMALL SQUARE (Old)"},
    {id: 3, value: HORIZONTAL_RECTANGLE, label: "HORIZONTAL RECTANGLE (Old)"},
    {id: 4, value: LARGE_SQUARE, label: "LARGE SQUARE (Old)"},
    {id: 5, value: MEDIUM_RECTANGLE, label: "MEDIUM RECTANGLE (Old)"},
    {id: 6, value: MEDIUM_SQUARE, label: "MEDIUM SQUARE (Old)"},
    {id: 7, value: CIRCLE, label: "CIRCLE (Old)"},
    {id: 8, value: SMALL_RECTANGLE, label: "SMALL RECTANGLE (Old)"},
    {id: 9, value: VERTICAL_RECTANGLE, label: "VERTICAL RECTANGLE (Old)"},
    {id: 10, value: RECENTLY_PLAYED, label: "RECENTLY PLAYED (Old)"},
    {id: 11, value: LARGE_RECTANGLE_V2, label: "LARGE RECTANGLE (V2) (New)"},
    {id: 12, value: LARGE_SQUARE_V2, label: "LARGE SQUARE (V2) (New)"},
    {id: 13, value: SMALL_SQUARE_V2, label: "SMALL SQUARE (V2) (New)"},
    {id: 14, value: VERTICAL_RECTANGLE_V2, label: "VERTICAL RECTANGLE (V2) (New)"},
    {id: 15, value: CONTINUE_WATCHING_SQUARE, label: "CONTINUE WATCHING SQUARE"},
    {id: 16, value:FEATURED_LARGE_SQUARE , label: "FEATURED LARGE SQUARE"},
    {id: 17, value: MEDIUM_VERTICAL_RECTANGLE, label: "MEDIUM VERTICAL RECTANGLE"},
    {id: 18, value: MEDIUM_VERTICAL_RECTANGLE_COLLECTION_NO_CTA, label: "MEDIUM VERTICAL RECTANGLE COLLECTION NO CTA"},
    {id: 19, value: MEDIUM_VERTICAL_RECTANGLE_COLLECTION_CTA, label: "MEDIUM VERTICAL RECTANGLE COLLECTION CTA"},
    {id: 20, value: FEATURED_VERTICAL_RECTANGLE, label: "FEATURED VERTICAL RECTANGLE"},
    {id: 21, value: FEATURED_LARGE_RECTANGLE, label: "FEATURED LARGE RECTANGLE"},
    {id: 22, value: COLLECTION_CHIP, label: "COLLECTION CHIP"},
    {id: 23, value: STATEMENT_CARDS, label: "STATEMENT CARDS"},
    {id: 24, value: STATEMENT_CARDS_CTA, label: "STATEMENT CARDS CTA"},

]

const EntityTypes = [
    {id: 1, value: VIDEO, label: "Video"},
    {id: 3, value: CHANNEL, label: "Channel"},
    {id: 4, value: SUBCHANNEL, label: "Sub Channel"},
    {id: 5, value: CREATOR, label: "Creator"},
    {id: 6, value: LINK, label: "Link"},
    {id: 7, value: QUOTE, label: "Quote (Post)"},
    {id: 8, value: MANTRA, label: "Mantra (Post)"},
    {id: 9, value: PANCHANG, label: "Panchang (Post)"},
    {id: 10, value: SUVICHAR, label: "Suvichar (Post)"},
]

const ViewAllEnableTypes = [
    {id: 1, value: MODULE, label: "Module"},
    {id: 2, value: ENTITY, label: "Entity"},
    {id: 3, value: DEEP_LINK, label: "Deeplink"},
    {id: 4, value: EXTERNAL_LINK, label: "External Link"},
]

const PlaylistVideoOrderTypes = [
    {id: 1, value: DEFAULT, label: "Default"},
    {id: 2, value: RANDOM, label: "Random"},
]

const DBCacheUpload = [
    {}
]

const InternalLinkPageTypes = [
    {id: 1, value: CHANNEL, label: "Channel", requireID: true},
    {id: 2, value: SUBCHANNEL, label: "Sub Channel", requireID: true},
    {id: 3, value: VIDEO, label: "Video", requireID: true},
    {id: 4, value: PLAYLIST, label: "Playlist", requireID: true},
    {id: 8, value: CREATOR, label: "Creator", requireID: true},
    {id: 5, value: SUVICHAR, label: "Suvichar", requireID: false},
    {id: 6, value: PROFILE, label: "Profile", requireID: false},
    {id: 7, value: SEARCH, label: "Search", requireID: false},

]
export {
    ScrollTypes,
    EntityRanking,
    CardLayout,
    EntityTypes,
    ViewAllEnableTypes,
    PlaylistVideoOrderTypes,
    DBCacheUpload,
    InternalLinkPageTypes
}

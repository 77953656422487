import React from "react";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    Text,
    Textarea
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import {Form, Formik} from "formik";
import InputField from "../custom/InputField";
import {EntityTypes} from "../../config/Options";
import SelectField from "../custom/SelectField";
import {CHANNEL, CREATOR, ENTITY_PAGE, LINK, PLAYLIST, SUBCHANNEL, VIDEO} from "../../types/Strings";
import PresentOnModal from "../common/PresentOnModal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {HiExternalLink} from "react-icons/hi";
import UploadImageComponent from "../common/UploadImage";
import {GlobalFormStyles} from "../../styles/FormStyles";

interface Props {
    data: any | object;
    isNew?: boolean;
}

const EntityEditForm: React.FC<Props> = ({data, isNew}) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isNew && data?.entity_type === PLAYLIST) {
            navigate('/playlist-edit/' + data.id, {replace: true});
        }

        if (!isNew && data?.entity_type === VIDEO) {
            navigate('/video-edit/' + data.id, {replace: true});
        }

    }, [data?.entity_type, data.id, navigate])

    const validationSchema = yup.object({
        entity_type: yup.string().required('Required'),
        name: yup.string(),
        description: yup.string(),
        is_active: yup.boolean().required('Required'),
        image_url: yup.string(),
        trending_score: yup.number(),
        card_chip_text: yup.string(),
        designation: yup.string(),
        sub_title: yup.string(),
    });

    const initialValues = isNew ? {
        id: "",
        name: "",
        description: "",
        entity_type: CHANNEL,
        is_active: true,
        image_url: '',
        trending_score: 0,
        created_at: '',
        created_by: "",
        keywords: "",
        tags: "",
        entity_id: "",
        entity_url: "",
        card_chip_text: "",
        designation: "",
        sub_title: ""
    } : {
        id: data.id,
        name: data?.title || data?.name,
        description: data.description,
        entity_type: data.entity_type,
        is_active: data.is_active,
        image_url: data.image_url,
        trending_score: data.trending_score,
        created_at: data.created_at,
        created_by: data.created_by,
        keywords: data.keywords,
        tags: data.tags,
        entity_id: data.entity_id,
        entity_url: data?.entity_url,
        card_chip_text: data?.card_chip_text,
        designation: data?.designation,
        sub_title: data?.sub_title
    }

    const axiosPrivate = useAxiosPrivate();

    return (
        <>
            <SimpleGrid columns={2} spacing={4}>
                <Box my={8} textAlign='left'>
                    <Formik
                        enableReinitialize={true}
                        validateOnChange={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (data, {setSubmitting, setValues}) => {
                            setSubmitting(true);
                            try {
                                if (isNew) {
                                    const r_ts = {
                                        "name": data.name,
                                        "description": data.description,
                                        "image_url": data.image_url,
                                        "entity_type": data.entity_type,
                                        "trending_score": data.trending_score,
                                        "tags": data.tags,
                                        "is_active": data.is_active,
                                        "keywords": data.keywords,
                                        "card_chip_text": data.card_chip_text,
                                        "sub_title": data.sub_title,
                                        ...((data.entity_type === LINK) && {
                                            "entity_url": data.entity_url,
                                        }),
                                        ...(data.entity_type === CREATOR && {
                                            "designation": data.designation
                                        })
                                    }

                                    const res = await axiosPrivate.put('/entity', r_ts);

                                    if (res.status === 200) {
                                        if (res.data?.status === 200) {
                                            toast.success(res.data.message)
                                            setValues({
                                                ...data,
                                                id: res.data.data.id,
                                                entity_id: res.data.data.entity_id,
                                            })

                                            //after 3 seconds redirect to entity edit page
                                            setTimeout(() => {
                                                navigate('/entity-edit/' + res.data.data.id, {replace: true});
                                            }, 3000);
                                        } else {
                                            toast.error(res.data.message)
                                        }
                                    } else {
                                        toast.error("Something went wrong")
                                    }
                                } else {
                                    const r_ts = {
                                        "id": data.id,
                                        "name": data.name,
                                        "description": data.description,
                                        "image_url": data.image_url,
                                        "entity_type": data.entity_type,
                                        "trending_score": data.trending_score,
                                        "tags": data.tags,
                                        "is_active": data.is_active,
                                        "keywords": data.keywords,
                                        "card_chip_text": data.card_chip_text,
                                        "sub_title": data.sub_title,
                                        ...((data.entity_type === CHANNEL || data.entity_type === SUBCHANNEL || data.entity_type === LINK) && {
                                            "entity_url": data.entity_url,
                                        }),
                                        ...(data.entity_type === CREATOR && {
                                            "designation": data.designation
                                        })
                                    }

                                    const res = await axiosPrivate.patch('/entity', r_ts)
                                    if (res.status === 200) {
                                        if (res.data?.status === 200) {
                                            toast.success(res.data.message)
                                        } else {
                                            toast.error(res.data.message)
                                        }
                                    } else {
                                        toast.error("Something went wrong")
                                    }
                                }
                            } catch (err) {
                                console.error(err)
                                toast.error(err.response.data.message)
                                return;
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({values, isSubmitting, setValues, errors}) => (
                            <Form
                                style={{
                                    ...GlobalFormStyles,
                                    width: '60%'
                                }}
                            >
                                <Box
                                    overflow="hidden"
                                    mb={2}
                                >
                                    <Text
                                        fontSize={16}
                                        mb={2}
                                        color={'gray.200'}
                                    >
                                        Master ID : {isNew ? 'NA' : values.id}
                                        <br/>
                                        Entity ID : {isNew ? 'NA' : values.entity_id}
                                    </Text>
                                </Box>

                                <InputField
                                    placeholder={"Title"}
                                    name={"name"}
                                    value={values.name}
                                />

                                <InputField
                                    placeholder={"Sub Title"}
                                    name={"sub_title"}
                                    value={values.sub_title}
                                />

                                <SelectField
                                    isDisabled={!isNew}
                                    placeholder={"Entity Type"}
                                    options={isNew ? EntityTypes.filter(x => x.value !== VIDEO) : EntityTypes}
                                    name={'entity_type'}
                                />

                                <FormControl isInvalid={!!errors.is_active}>
                                    <FormLabel>Is Active</FormLabel>
                                    <RadioGroup
                                        onChange={(e) => {
                                            setValues({...values, is_active: e === "true"})
                                        }}
                                        value={values.is_active.toString()}
                                    >
                                        <Stack direction={"row"}>
                                            {
                                                [{value: "true", label: "Active"}, {
                                                    value: "false",
                                                    label: "Inactive"
                                                }]?.map((option, index) => (
                                                    <Radio
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                ))
                                            }
                                        </Stack>
                                    </RadioGroup>
                                    {errors.is_active && <FormErrorMessage>{errors.is_active}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={!!errors.description}>
                                    <FormLabel>Description</FormLabel>
                                    <Textarea
                                        placeholder={"Description"}
                                        name={"description"}
                                        value={values.description}
                                        onChange={(e) => setValues({...values, description: e.target.value})}
                                    />
                                    {errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={!!errors.trending_score}>
                                    <FormLabel>Trending Score</FormLabel>
                                    <NumberInput
                                        defaultValue={0} min={0} max={100}
                                        onChange={(e) => setValues({...values, trending_score: e})}
                                        value={values.trending_score}
                                    >
                                        <NumberInputField/>
                                        <NumberInputStepper>
                                            <NumberIncrementStepper/>
                                            <NumberDecrementStepper/>
                                        </NumberInputStepper>
                                    </NumberInput>
                                    {errors.trending_score &&
                                        <FormErrorMessage>{errors.trending_score}</FormErrorMessage>}
                                </FormControl>

                                <InputField
                                    placeholder={"Card Chip Text (Comma Seperated)"}
                                    name={"card_chip_text"}
                                    value={values.card_chip_text}
                                />

                                {values.entity_type === CREATOR && <InputField
                                    placeholder={"Designation"}
                                    name={"designation"}
                                    value={values.designation}
                                />}

                                <InputField
                                    placeholder={"Image URL"}
                                    name={"image_url"}
                                    value={values.image_url}
                                />

                                <UploadImageComponent
                                    setImageUrl={(imageUrl) => setValues({...values, image_url: imageUrl})}
                                    imageURL={values.image_url}
                                    entityID={values.id}
                                />

                                {(values.entity_type === LINK) &&

                                    <FormControl isInvalid={!!errors.entity_url}>
                                        <FormLabel>Entity URL</FormLabel>

                                        <Input
                                            value={values.entity_url}
                                            onChange={(e) => setValues({...values, entity_url: e.target.value})}
                                        />
                                        {errors.entity_url &&
                                            <FormErrorMessage>{errors.entity_url}</FormErrorMessage>}
                                        {values.entity_url && <HiExternalLink
                                            style={{
                                                fontSize: "1.5rem",
                                                color: "#0070f3",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                window.open(values.entity_url, "_blank");
                                            }}
                                        />}
                                    </FormControl>
                                }

                                {!isNew && <InputField
                                    placeholder={"Created At"}
                                    name={"created_at"}
                                    value={new Date(values?.created_at)?.toDateString()}
                                    disabled={true}
                                />}

                                <Button
                                    type={'submit'}
                                    isLoading={isSubmitting}
                                    colorScheme={'green'}
                                    mt={8}
                                >
                                    Save
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </Box>
                <Box>
                    {!isNew && <PresentOnModal
                        id={data.id}
                        source={ENTITY_PAGE}
                    />}
                </Box>
            </SimpleGrid>
        </>
    )
};

export default EntityEditForm;

import React, {useEffect} from "react";
import * as yup from "yup";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack, Text,
    Textarea
} from "@chakra-ui/react";
import toast, {Toaster} from "react-hot-toast";
import {Form, Formik} from "formik";
import InputField from "../../custom/InputField";
import YouTube from "react-youtube";
import UploadImageComponent from "../../common/UploadImage";
import VideoInPlaylistsModal from "./VideoInPlaylistsModal";
import {SelectFieldWithImage} from "../../custom/SelectField";

const VideoEditForm = ({data}) => {
    const axiosPrivate = useAxiosPrivate();

    const [creators, setCreators] = React.useState([]);

    useEffect(()=>{
        async function fetchCreators() {
            const {data} = await axiosPrivate.get('/creator/all');
            setCreators(data.data);
        }
        fetchCreators();
    },[]);

    const validationSchema = yup.object({
        video_image_url: yup.string(),
        video_url: yup.string().required("Required"),
        video_title: yup.string().required("Required"),
        video_description: yup.string(),
        is_active: yup.boolean().required("Required"),
        trending_score: yup.number(),
        card_chip_text: yup.string(),
        creator_id:yup.number(),
        is_standalone: yup.boolean(),
    });

    const initialValues = {
        id: data.id,
        video_image_url: data.image_url,
        video_url: data.url,
        video_title: data.title,
        description: data.description,
        creator_id: data.creator_id,
        creator_name: data.creator_name,
        is_paid: data.is_paid,
        price: data.price,
        youtube_id: data.youtube_id,
        unlock_status: data.unlock_status,
        difficulty: data.difficulty,
        partner: data.partner,
        ratings: data.ratings,
        views: data.views,
        likes: data.likes,
        duration: data.duration,
        is_active: data.is_active,
        last_modified: data?.last_modified,
        trending_score: data.trending_score,
        card_chip_text: data?.card_chip_text,
        is_standalone: data?.is_standalone,
    }

    return (
        <>
            <SimpleGrid columns={2} spacing={4}>
                <Box my={8} textAlign='left'>
                    <Toaster/>
                    <Formik
                        enableReinitialize={true}
                        validateOnChange={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (data, {setSubmitting, setValues}) => {
                            setSubmitting(true);
                            try {
                                //Update the video
                                const re_t_s = {
                                    id: data.id,
                                    image_url: data.video_image_url,
                                    url: data.video_url,
                                    title: data.video_title,
                                    description: data.description,
                                    is_paid: data.is_paid,
                                    price: data.price,
                                    difficulty: data.difficulty,
                                    partner: data.partner,
                                    ratings: data.ratings,
                                    is_active: data?.is_active,
                                    trending_score: data.trending_score,
                                    card_chip_text: data?.card_chip_text,
                                    creator_id: data.creator_id,
                                    is_standalone: data?.is_standalone
                                };

                                await axiosPrivate.patch('/video', re_t_s).then(res => {
                                    toast.success("Video updated successfully");

                                }).catch(err => {
                                    console.log(err);
                                    toast.error("Error updating video");
                                });
                            } catch (err) {
                                console.error(err)
                                toast.error(err.response.data.message)
                                return;
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({values, isSubmitting, setValues, errors}) => (
                            <Form>
                                <Box
                                    overflow="hidden"
                                    mb={2}
                                    w={'60%'}
                                >
                                    <Text
                                        fontSize={16}
                                        mb={2}
                                        color={'gray.200'}
                                    >
                                        Master ID : {values.id}
                                    </Text>
                                </Box>

                                {<Box
                                    mb={4}
                                >
                                    <FormLabel>Video Preview</FormLabel>
                                    <YouTube
                                        videoId={values.youtube_id}
                                        opts={{
                                            height: '200',
                                            width: '300',
                                            playerVars: {
                                                autoplay: 1,
                                                mute: 1,
                                            },
                                        }}
                                    />
                                </Box>}


                                <InputField
                                    placeholder={"Youtube ID"}
                                    name={"youtube_id"}
                                    value={values.youtube_id}
                                    disabled={true}
                                    style={InputFieldStyle}
                                />

                                <InputField
                                    placeholder={"Title"}
                                    name={"video_title"}
                                    value={values.video_title}
                                    style={InputFieldStyle}
                                />

                                <FormControl isInvalid={!!errors.is_standalone}>
                                    <FormLabel>Is Standalone</FormLabel>
                                    <RadioGroup
                                        isDisabled={false}
                                        style={InputFieldStyle}
                                        onChange={(e) => {
                                            setValues({...values, is_standalone: e === "true"})
                                        }}
                                        value={values.is_standalone.toString()}
                                    >
                                        <Stack direction={"row"}>
                                            {
                                                [{value: "true", label: "Yes"}, {
                                                    value: "false",
                                                    label: "No"
                                                }]?.map((option, index) => (
                                                    <Radio
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                ))
                                            }
                                        </Stack>
                                    </RadioGroup>
                                    {errors.is_standalone && <FormErrorMessage>{errors.is_standalone}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={!!errors.is_active}>
                                    <FormLabel>Is Active</FormLabel>
                                    <RadioGroup
                                        style={InputFieldStyle}
                                        onChange={(e) => {
                                            setValues({...values, is_active: e === "true"})
                                        }}
                                        value={values.is_active.toString()}
                                    >
                                        <Stack direction={"row"}>
                                            {
                                                [{value: "true", label: "Active"}, {
                                                    value: "false",
                                                    label: "Inactive"
                                                }]?.map((option, index) => (
                                                    <Radio
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                ))
                                            }
                                        </Stack>
                                    </RadioGroup>
                                    {errors.is_active && <FormErrorMessage>{errors.is_active}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={!!errors.description}>
                                    <FormLabel>Description</FormLabel>
                                    <Textarea
                                        placeholder={"Description"}
                                        name={"description"}
                                        value={values.description}
                                        onChange={(e) => setValues({...values, description: e.target.value})}
                                        style={InputFieldStyle}
                                    />
                                    {errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
                                </FormControl>

                                <SelectFieldWithImage
                                    placeholder={"Select Creator"}
                                    name={"creator"}
                                    style={InputFieldStyle}
                                    setValue={(e)=>{
                                        setValues({...values, creator_id: e})
                                    }}
                                    value={values.creator_id}
                                    options={creators.map(item=>{
                                        return {
                                            value: item.id,
                                            label: item.title,
                                            image_url: item.image_url
                                        }
                                    })}
                                />

                                <FormControl isInvalid={!!errors.is_paid}>
                                    <FormLabel>Is Paid</FormLabel>
                                    <RadioGroup
                                        isDisabled={true}
                                        style={InputFieldStyle}
                                        onChange={(e) => {
                                            setValues({...values, is_paid: e === "true"})
                                        }}
                                        //value={values.is_active.toString()}
                                    >
                                        <Stack direction={"row"}>
                                            {
                                                [{value: "true", label: "Yes"}, {
                                                    value: "false",
                                                    label: "No"
                                                }]?.map((option, index) => (
                                                    <Radio
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                ))
                                            }
                                        </Stack>
                                    </RadioGroup>
                                    {errors.is_paid && <FormErrorMessage>{errors.is_paid}</FormErrorMessage>}
                                </FormControl>


                                {values.is_paid && <FormControl>
                                    <FormLabel>Price</FormLabel>
                                    <NumberInput style={InputFieldStyle} defaultValue={0} min={0} max={10000}>
                                        <NumberInputField/>
                                        <NumberInputStepper>
                                            <NumberIncrementStepper/>
                                            <NumberDecrementStepper/>
                                        </NumberInputStepper>
                                    </NumberInput>
                                </FormControl>}

                                <InputField
                                    placeholder={"Card Chip Text (Comma Seperated)"}
                                    name={"card_chip_text"}
                                    value={values.card_chip_text}
                                    style={InputFieldStyle}
                                />

                                <InputField
                                    placeholder={"Image URL"}
                                    name={"video_image_url"}
                                    value={values.video_image_url}
                                    style={InputFieldStyle}
                                />

                                <UploadImageComponent
                                    setImageUrl={(imageUrl) => setValues({...values, video_image_url: imageUrl})}
                                    style={InputFieldStyle}
                                    imageURL={values.video_image_url}
                                    entityID={values.id}
                                />

                                <FormControl isInvalid={!!errors.trending_score}>
                                    <FormLabel>Trending Score</FormLabel>
                                    <NumberInput style={InputFieldStyle} defaultValue={0} min={0} max={100}
                                                 onChange={(e) => setValues({...values, trending_score: e})}
                                                 value={values.trending_score}
                                    >
                                        <NumberInputField/>
                                        <NumberInputStepper>
                                            <NumberIncrementStepper/>
                                            <NumberDecrementStepper/>
                                        </NumberInputStepper>
                                    </NumberInput>
                                    {errors.trending_score &&
                                        <FormErrorMessage>{errors.trending_score}</FormErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={!!errors.video_url}>
                                    <FormLabel>Video URL</FormLabel>

                                    <Input
                                        style={InputFieldStyle}
                                        value={values.video_url}
                                        onChange={(e) => setValues({...values, video_url: e.target.value})}
                                    />
                                    {errors.video_url &&
                                        <FormErrorMessage>{errors.video_url}</FormErrorMessage>}
                                </FormControl>


                                {<InputField
                                    placeholder={"Last Modified At"}
                                    name={"created_at"}
                                    value={new Date(values?.last_modified)?.toDateString()}
                                    disabled={true}
                                    style={InputFieldStyle}
                                />}


                                <Button
                                    type={'submit'}
                                    isLoading={isSubmitting}
                                    colorScheme={'green'}
                                    mt={4}>Save</Button>
                            </Form>
                        )}
                    </Formik>
                </Box>
                <Box>
                    {<VideoInPlaylistsModal
                        id={data.id}
                    />}
                </Box>
            </SimpleGrid>
        </>
    )
};

const InputFieldStyle = {
    "width": "60%",
    "marginBottom": "10px"
}

export default VideoEditForm;

//Export react styles


import {CSSProperties} from "react";

export const GlobalFormStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '32%',
    gap: '16px',
}

import React from 'react';
import {Box, Button, Heading, HStack, Input, Select, Text, VStack} from "@chakra-ui/react";
import DatePicker from 'react-date-picker';
import {ANY, ENTITY_PAGE, MODULE_PAGE} from "../../types/Strings";
import {observer} from "mobx-react";
import {SearchStoreImpl} from "../../store/mobx/Search";
import EntitySearchResultTabs from "./EntitySearchResultTabs";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {Progress} from "@chakra-ui/react";

interface Props {
    source?: string;
    searchProps: SearchStoreImpl;
}

const EntitiesSearch: React.FC<Props> = observer(({source = ENTITY_PAGE, searchProps}) => {
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [dateError, setDateError] = React.useState("");
    const [entityTypeDisabled, setEntityTypeDisabled] = React.useState(false);
    const [searchParamDisabled, setSearchParamDisabled] = React.useState(false);
    const [data, setData] = React.useState();
    const [isFetching, setIsFetching] = React.useState(false);
    const [showSearch, setShowSearch] = React.useState(true);

    React.useEffect(() => {
        if (startDate > endDate) {
            setDateError("Start date cannot be after end date");

        } else {
            setDateError("");
        }

    }, [startDate, endDate]);

    const axiosPrivate = useAxiosPrivate();
    const OnSubmit = async (e) => {
        setIsFetching(true);
        const search = {
            text: searchProps.searchText,
            type: searchProps.searchType,
            param: searchProps.searchParam,
            start: startDate ? startDate : ANY,
            end: endDate ? endDate : ANY,
        }

        const res = await axiosPrivate.post('/entity/search', search);
        setData(res.data.data);
        setIsFetching(false);

    };

    const onSearchTextChange = (e) => {
        const text = e.target.value;
        searchProps.changeSearchText(text);
    };

    return (
        <>
            <HStack
                justifyContent={'space-between'}
                px={5}
                mb={2}
            >
                <Heading as={"h3"}>Search Entities</Heading>
                <Button
                    onClick={() => setShowSearch(!showSearch)}
                    w={"15%"}
                    colorScheme={showSearch ? "red" : "green"}
                >{showSearch ? 'Hide' : 'Show'} Search Box</Button>
            </HStack>

            {showSearch && <VStack
                mt={10}
                p={5}
            >
                <Box
                    w={"100%"}
                >
                    <Input
                        placeholder="Search for an entity"
                        size="lg"
                        variant="filled"
                        width="50%"
                        height="50px"
                        borderRadius="md"
                        value={searchProps.searchText}
                        onChange={(e) => onSearchTextChange(e)}
                        _focus={{
                            borderColor: "teal.500",
                            boxShadow: "0 0 0 2px teal.500"
                        }}
                        mr={2}
                    />
                    {source !== MODULE_PAGE && <Button
                        size="lg"
                        variant="solid"
                        width="10%"
                        height="50px"
                        borderRadius="md"
                        mb={2}
                        onClick={OnSubmit}
                    >Search</Button>}
                </Box>

                <Box
                    w={"100%"}
                >
                    <HStack
                        spacing={4}
                        mt={2}>
                        <Select
                            placeholder="Select an entity type"
                            w={"290px"}
                            height="50px"
                            value={searchProps.searchType}
                            disabled={entityTypeDisabled}
                            onChange={(e) => searchProps.changeSearchType(e.target.value)}
                        >
                            <option value={'all'}>All</option>
                            <option value={'video'}>Video</option>
                            <option value={'playlist'}>Playlist</option>
                            <option value={'link'}>Link</option>
                            <option value={'post'}>Posts</option>
                            <option value={'channel'}>Channel</option>
                            <option value={'subchannel'}>Sub Channel</option>
                            <option value={'creator'}>Creator</option>
                        </Select>
                        <Select
                            placeholder="Select a parameter"
                            w={"290px"}
                            height="50px"
                            value={searchProps.searchParam}
                            disabled={searchParamDisabled}
                            onChange={(e) => searchProps.changeSearchParam(e.target.value)}
                        >
                            <option value={"entity_id"}>Entity ID</option>
                            <option value={"id"}>Postgres ID</option>
                            <option value={"title"}>Title</option>
                            <option value={"any"}>Any</option>
                        </Select>

                        {source !== MODULE_PAGE && <Button
                            size="lg"
                            variant="outline"
                            width="10%"
                            height="50px"
                            borderRadius="md"
                            mb={2}
                            onClick={() => {
                                searchProps.changeSearchText('');
                                searchProps.changeSearchType('');
                                searchProps.changeSearchParam('');
                                setStartDate(null);
                                setEndDate(null);
                            }}
                        >Reset</Button>}
                    </HStack>
                    {/*<Box*/}
                    {/*    w={"100%"}*/}
                    {/*>*/}
                    {/*    <HStack*/}
                    {/*        spacing={4}*/}
                    {/*        mt={4}>*/}
                    {/*        <Text>Start Date</Text>*/}
                    {/*        <DatePicker*/}
                    {/*            value={startDate}*/}
                    {/*            onChange={(date: Date) => {*/}
                    {/*                setStartDate(date);*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*        <Text>End Date</Text>*/}
                    {/*        <DatePicker*/}
                    {/*            value={endDate}*/}
                    {/*            onChange={(date: Date) => {*/}
                    {/*                setEndDate(date);*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </HStack>*/}
                    {/*    {!!dateError && <Text*/}
                    {/*        color="red.500"*/}
                    {/*        fontSize="sm"*/}
                    {/*        mt={2}>*/}
                    {/*        {dateError}*/}
                    {/*    </Text>}*/}
                    {/*</Box>*/}
                </Box>
            </VStack>}
            <Box
                w={"100%"}
                mt={4}
            >
                {isFetching && <Progress size="lg" isIndeterminate mb={4}/>}
                {data && <EntitySearchResultTabs data={data}/>}
            </Box>

        </>
    )
})

export default EntitiesSearch;
